<div id="page-content">
    <div class="container-fluid pt-2 px-0" style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));border-top: 1px solid rgb(255 255 255 / 25%);">
        <span style="margin-left: 24px;font-size: 1.1em;margin-bottom: 12px;color: #fff;font-size: 1.6em; text-transform: uppercase; font-weight: 600;">{{'general.title_events' | translate}}</span>
        <div class=" d-flex flex-wrap flex-row flex-md-row-reverse">
            <div class="col-12 col-md-4">
                <div class="w-100 px-0">
                    <div (focus)="$event.target.firstElementChild.focus()" class="equsSearchInput mdi mdi-magnify">
                        <input type="text" placeholder="{{'general.label_search' | translate}}" (input)="filterEvents($event.target.value)">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 d-flex flex-row align-items-end">
                <div class="d-flex flex-row w-100">
                    <div *ngIf="filteredEvents[tabs['vivo']].length" class="col-4 px-0 d-flex justify-content-center" style="white-space: nowrap;">
                        <button class="tab-button w-100 px-0" [ngClass]="(activeTab == tabs['vivo'])?'active':null" (click)="activeTab = tabs['vivo']">
                            {{'general.label_live' | translate}}
                        </button>
                    </div>
                    <!--div class="col-4 px-0 d-flex justify-content-center">
                        <button class="tab-button w-100 px-0" [ngClass]="(activeTab == tabs['next'])?'active':null" (click)="activeTab = tabs['next']">
                            Próximos
                        </button>
                    </div-->
                    <div class="col-4 px-0 d-flex justify-content-center">
                        <button class="tab-button w-100 px-0" [ngClass]="(activeTab == tabs['prev'])?'active':null" (click)="activeTab = tabs['prev']">
                            {{'general.label_completed' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid d-flex flex-column flex-grow-1 scrollbar pb-3" style="overflow-y: auto;">
        <div [ngClass]="(activeTab == tabs['vivo'])?'d-flex':'d-none'" class="row px-4">
            <div class="col-12 col-sm-6 col-md-4 py-1 px-1" *ngFor="let event of filteredEvents[tabs['vivo']]" (click)="onEventClick(event)">
                <div class="event_card h-100 d-flex flex-column">
                    <div style="flex-grow:1;">
                        <span *ngIf="event.monetize"
                            style="float: right;"
                            class="placing">
                            Buy Your analysis
                         </span>
                        <span style="font-weight:bold;font-size:1.1em;">{{event.evento}}</span><br>
                        <span>{{event.data}}</span><br>
                        <span class="mb-2" style="color:#ffffffdd;font-size:0.9em;">{{event.local}}</span>

                    </div>
                    <div *ngIf="event.best && (event.best.categoria||event.best.cavalo||event.best.competidor||event.best.total)" style="border: #2c2c2c solid 1px; border-radius: 5px; padding: 5px; font-size: .9em; margin: 10px 0;">
                        <span *ngIf="event.best.categoria" style="color:#ffffffdd;font-size:0.9em;"><span style="font-weight:700"><span class="placing"><i class="mdi mdi-star"></i></span> Class:</span> {{event.best.categoria}}</span><br>
                        <span *ngIf="event.best.cavalo" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;"><span style="font-weight:700"><span class="placing"><i class="mdi mdi-star"></i></span>
                        <img class="horse" src="../../../assets/images/icons/horse-icon.png"> </span> {{event.best.cavalo}}</span>
                        <span *ngIf="event.best.competidor" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;"><span style="font-weight:700"><span class="placing"><i class="mdi mdi-star"></i></span> <img class="competitor" src="../../../assets/images/icons/cowboy-icon.png"></span> {{event.best.competidor}}</span>
                        <span *ngIf="event.best.total" style="color:#ffffffdd;font-size:0.9em;"><span style="font-weight:700"><span class="placing"><i class="mdi mdi-star"></i></span> <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i></span> {{event.best.total.toFixed(3)}}</span>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="d-flex flex-row w-100 align-items-center">
                            <div class="aovivo status mr-3" (click)="openLink($event,'/live',{'id_evento': event.id})"><i class="mdi mdi-television mr-2"></i>{{'general.label_live' | translate}}</div>
                            <!--div class="aovivo status mr-auto" [routerLink]="['/ranking',event.evento]"><i class="mdi mdi-podium mr-2"></i>Ranking</div-->
                            <i *ngIf="(event.maplink != null && event.maplink.trim()!='')" (click)="goToLink($event, event.maplink)" class="bts text-white p-2 mdi mdi-map-marker"></i>
                            <i *ngIf="(event.folderlink != null && event.folderlink.trim()!='')" (click)="goToLink($event, event.folderlink)" class="bts text-white p-2 mdi mdi-information-outline"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--div [ngClass]="(activeTab == tabs['next'])?'d-flex':'d-none'" class="row px-4">
            <div class="col-12 col-sm-6 col-md-4 py-1 px-1" *ngFor="let event of filteredEvents[tabs['next']]" (click)="onEventClick(event)">
                <div class="event_card h-100 d-flex flex-column">
                    <div style="flex-grow:1;">
                        <span style="font-weight:bold;font-size:1.1em;">{{event.evento}}</span><br>
                        <span>{{event.data}}</span><br>
                        <span class="mb-2" style="color:#ffffffdd;font-size:0.9em;">{{event.local}}</span>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="d-flex flex-row">
                            <i *ngIf="(event.maplink != null && event.maplink.trim()!='')" (click)="goToLink($event, event.maplink)" class="bts text-white p-2 ml-auto mdi mdi-map-marker"></i>
                            <i *ngIf="(event.folderlink != null && event.folderlink.trim()!='')" (click)="goToLink($event, event.folderlink)" class="bts text-white p-2 mdi mdi-information-outline"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div-->
        <div [ngClass]="(activeTab == tabs['prev'])?'d-flex':'d-none'" class="row px-4 py-3">
            <div class="col-12 col-sm-6 col-md-4 py-1 px-1" *ngFor="let event of filteredEvents[tabs['prev']]" (click)="onEventClick(event)">
                <div class="event_card h-100 d-flex flex-column">
                    <div style="flex-grow:1;">
                        <span *ngIf="event.monetize"
                              style="float: right;"
                              class="placing">
                            Buy Your analysis
                         </span>
                        <span style="font-weight:bold;font-size:1.1em;">{{event.evento}}</span><br>
                        <span>{{event.data}}</span><br>
                        <span class="mb-2" style="color:#ffffffdd;font-size:0.9em;">{{event.local}}</span>

                        <div *ngIf="event.best && (event.best.categoria||event.best.cavalo||event.best.competidor||event.best.total)" style="border: #2c2c2c solid 1px;border-radius: 5px;justify-content: space-between;align-items: flex-start;display: flex;padding: 5px;font-size: .9em;margin: 10px 0;">
                            <div  style="display: flex;  flex-direction: column;  flex-grow: 1;  overflow: hidden;">
                                <span *ngIf="event.best.categoria" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;flex-grow: 1;overflow: hidden;">
                                    <span class="placing"><i class="mdi mdi-star"></i></span> {{event.best.categoria}}
                                </span>
                                <span *ngIf="event.best.cavalo" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;flex-grow: 1;overflow: hidden;">
                                    <span style="font-weight:700;padding-left: 3px;">
                                        <img class="horse" src="../../../assets/images/icons/horse-icon.png">
                                    </span>
                                    {{event.best.cavalo}}
                                </span>
                                <span *ngIf="event.best.competidor" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;flex-grow: 1;overflow: hidden;">
                                    <span style="font-weight:700;padding-left: 3px;">
                                        <img class="competitor" src="../../../assets/images/icons/cowboy-icon.png">
                                    </span>
                                    {{event.best.competidor}}
                                </span>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                                <span *ngIf="event.best.total" style="color:#ffffffdd;font-size:0.9em;min-width: 80px;">
                                    <span style="font-weight:700">
                                        <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                    </span>
                                    <span style="font-size: 1.4em;"> {{event.best.total.toFixed(3)}}</span>
                                </span>
                                <button matTooltip="Analysis" matTooltipPosition="above" style="max-width: min-content;border: #2c2c2c solid 1px; padding: 2px 10px;color: #3a220b;background: linear-gradient(50deg, #d9a250, #a17040, #e2c77c, #9e6e40);border: 1px solid #ffe191;" (click)="toAnalysis($event,event)" class="ml-auto btn btn-default my-2 mr-1 "> <i class="mdi mdi-chart-line"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="d-flex flex-row">
                            <i *ngIf="(event.maplink != null && event.maplink.trim()!='')" (click)="goToLink($event, event.maplink)" class="bts text-white p-2 mdi mdi-map-marker"></i>
                            <i *ngIf="(event.folderlink != null && event.folderlink.trim()!='')" (click)="goToLink($event, event.folderlink)" class="bts text-white p-2 mdi mdi-information-outline"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
