import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit, AfterViewInit {

  files = [];

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit(){
    document.querySelector("#drop-area").addEventListener("dragenter",(event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.querySelector("#drop-area").addEventListener("dragover",(event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.querySelector("#drop-area").addEventListener("drop", (event:DragEvent)=>{
      event.preventDefault();
      event.stopPropagation();
      this.files = [Array.from(event.dataTransfer.files).map(file => {return {"name":file.name, "size":file.size,"file":file};})[0]];
      this.dialogRef.close(this.files);
    });
  }

  handleFileInput(files:FileList) {
    this.files = Array.from(files).map(file => {return {"name":file.name, "size":file.size,"file":file};});
    this.dialogRef.close(this.files);
  }

  close(){
    this.dialogRef.close();
  }

}
