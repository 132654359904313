import {BaseModel} from './base.model';

export class CategoryModel extends BaseModel {

    constructor(
        public categoria?:string,
        public url_starting_list?:string,
        public data?:string,
        public finished?:number,
        public ord?:number,
        public evento?:string,
        public best?:{evento?:string, categoria?:string,cavalo?:string,competidor?:string,total?:number},
        public stat?:{cavalos?: number,competidor?:number, finalizadas?:number,nc?:number,passadas?:number,pen?:number,sat?:number,status?:string},
        public id_categoria_oficial?:number,
        public id_evento?:number,
        public pr?:boolean,
        public td?:string,
        public _1d?:string,
        public _2d?:string,
        public _3d?:string,
        public _4d?:string,
        public _5d?:string,
        public _6d?:string,
        public _7d?:string,
        public _8d?:string,
        public _9d?:string,
        public _10d?:string,
        public _11d?:string,
        public _12d?:string,
        public _13d?:string,
        public _14d?:string,
        public _15d?:string,
        public _16d?:string,
        public _17d?:string,
        public _18d?:string,
        public _19d?:string,
        public _20d?:string,
        public monetize?: boolean,
        public value_stripe_special_report_by_evento?: number,
        public value_stripe_special_report_by_categoria?: number,
        public value_stripe_full_report_by_evento?: number,
        public value_stripe_full_report_by_categoria?:number,
    ){
        super();
    }
}
