<div id="page-content">
    <div class="container-fluid px-0" style="background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);">
        <div class="header d-flex flex-column flex-wrap" style="padding: 0px 30px;border-top: 1px solid rgb(255 255 255 / 25%);">
            <div class="w-100 p-0 d-flex flex-column justify-content-end mb-2 mb-md-3" >
                <div class="row mx-0">
                    <div class="col-12 col-md-5 d-flex pt-2 flex-column justify-content-end">
                        <div class="equsSelect mdi mdi-menu-down">
                            <span>{{'general.choose_class_label' | translate}}</span>
                            <select [(ngModel)]="categoryName" (change)="downloadTurns($event.target.value)">
                                <option *ngFor="let category of categories" [value]="category.categoria">{{category.categoria}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex flex-column justify-content-end">
                        <div (focus)="$event.target.firstElementChild.focus()" class="equsSearchInput mdi mdi-magnify">
                            <input type="text" placeholder="{{'general.label_search' | translate}}" (input)="filterTurns($event.target.value)">
                        </div>
                    </div>
                    <div class="col-12 col-md-2 d-flex flex-column justify-content-end pt-2">
                        <span style="color:#fff;font-size: 0.6em;font-family: 'Open Sans', sans-serif;">
                             {{'general.label_update_automatically' | translate}}
                        </span>
                        <mat-slide-toggle color="primary" (change)="autoUpdate($event.checked)"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="w-100  px-0">
                <div class="turn_header self_turn_header">
                    <span class="position">{{(selfTurn && selfTurn.pos !== null)?selfTurn.pos+"º":"--"}}</span>
                    <div class="participant">
                        <span class="horse">
                            <img src="../../../assets/images/icons/horse-icon.png">
                            <div>{{(selfTurn)?selfTurn.cavalo:''}}</div>
                        </span>
                        <span class="competitor">
                            <img src="../../../assets/images/icons/cowboy-icon.png">
                            <div>{{(selfTurn)?selfTurn.competidor:''}}</div>
                        </span>
                    </div>
                    <span [ngClass]="(selfTurn && selfTurn.total == min.total)?'max':null" class="total">
                        <i class="mdi relogio mdi-timer-outline" [attr.data-pas]="(selfTurn != null)?selfTurn.pas:'1'" style="font-size: 1.2em; position: relative; display: flex; justify-content: center; align-items: center; font-weight: 100;"></i>
                        {{(selfTurn && selfTurn.total)?toFixed(selfTurn.total,3):'--'}}
                    </span>
                </div>
            </div>

        </div>
        <div class="turn">
            <div class="turn_totals_fields">
                <span>{{'general.label_1st_straight' | translate}}</span>
                <span>{{'general.label_1st_drum' | translate}}</span>
                <span>{{'general.label_2nd_straight' | translate}}</span>
                <span>{{'general.label_2nd_drum' | translate}}</span>
                <span>{{'general.label_3rd_straight' | translate}}</span>
                <span>{{'general.label_3rd_drum' | translate}}</span>
                <span>{{'general.label_final_straight' | translate}}</span>
            </div>
            <div class="turn_totals">
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.r1 == min.r1)?'max':null">{{(selfTurn && selfTurn.r1 != null)?toFixed(selfTurn.r1,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.v1 == min.v1)?'max':null">{{(selfTurn && selfTurn.v1 != null)?toFixed(selfTurn.v1,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.r2 == min.r2)?'max':null">{{(selfTurn && selfTurn.r2 != null)?toFixed(selfTurn.r2,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.v2 == min.v2)?'max':null">{{(selfTurn && selfTurn.v2 != null)?toFixed(selfTurn.v2,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.r3 == min.r3)?'max':null">{{(selfTurn && selfTurn.r3 != null)?toFixed(selfTurn.r3,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.v3 == min.v3)?'max':null">{{(selfTurn && selfTurn.v3 != null)?toFixed(selfTurn.v3,3):'--'}}</span>
                </div>
                <div>
                    <span [ngClass]="(selfTurn && selfTurn.rf == min.rf)?'max':null">{{(selfTurn && selfTurn.rf != null)?toFixed(selfTurn.rf,3):'--'}}</span>
                </div>
            </div>
        </div>
    </div>
    <div id="turns" class="scrollbar">
        <div class="turn" style="background: #1d1a16;" (click)="openCompareDialog(min,true)">
            <div class="turn_header">
                <span class="placing">
                    <i class="mdi mdi-star"></i>
                </span>
                <div class="participant">
                    <span class="horse">
                        <div>{{'general.label_best_partials' | translate}}</div>
                    </span>
                </div>
                <div style="display: flex;flex-direction: row;"></div>
                <div style="display: flex;flex-direction: row;">
                    <span class="total bestvalues">
                        <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                        {{(min && min.total != null)?toFixed(min.total,3):'--'}}
                    </span>
                </div>
            </div>
            <div class="turn_totals">
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.r1 != null)?toFixed(min.r1,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('r1', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.r1 != null && selfTurn && selfTurn.r1 != null)?getDif('r1', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(selfTurn && min.v1 != null)?toFixed(min.v1,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('v1', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.v1 != null && selfTurn && selfTurn.v1 != null)?getDif('v1', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.r2 != null)?toFixed(min.r2,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('r2', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.r2 != null && selfTurn && selfTurn.r2 != null)?getDif('r2', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.v2 != null)?toFixed(min.v2,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('v2', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.v2 != null && selfTurn && selfTurn.v2 != null)?getDif('v2', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.r3 != null)?toFixed(min.r3,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('r3', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.r3 != null && selfTurn && selfTurn.r3 != null)?getDif('r3', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.v3 != null)?toFixed(min.v3,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('v3', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.v3 != null && selfTurn && selfTurn.v3 != null)?getDif('v3', selfTurn, min):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center bestvalues">
                        {{(min && min.rf != null)?toFixed(min.rf,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('rf', selfTurn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(min && min.rf != null && selfTurn && selfTurn.rf != null)?getDif('rf', selfTurn, min):'--'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="turn" *ngFor="let turn of filteredTurns" (click)="openCompareDialog(turn)">
            <div class="turn_header">
                <span class="placing">
                    {{(turn && turn.pos !== null)?turn.pos+"º":'--'}}
                </span>
                <div class="participant">
                    <span class="horse">
                        <img src="../../../assets/images/icons/horse-icon.png">
                        <div>{{(turn)?turn.cavalo:''}}</div>
                    </span>
                    <span class="competitor">
                        <img src="../../../assets/images/icons/cowboy-icon.png">
                        <div>{{(turn)?turn.competidor:'--'}}</div>
                    </span>
                </div>
                <div style="display: flex;flex-direction: row;">
                    <span [ngClass]="(turn && turn.total == min.total)?'max':null" class="total">
                        <i class="mdi relogio mdi-timer-outline" [attr.data-pas]="turn.pas" style="margin-top: 0px; font-size: 1.8em; position: relative; display: flex; justify-content: center; align-items: center; font-weight: 100;    line-height: 1em;margin-right: 3px;"></i>
                        {{(turn && turn.total)?toFixed(turn.total,3):'--'}}
                    </span>
                </div>
            </div>
            <div class="turn_totals">
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r1 == min.r1)?'max':null">
                        {{(turn && turn.r1 != null)?toFixed(turn.r1,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('r1', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.r1 != null && selfTurn && selfTurn.r1 != null)?getDif('r1', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v1 == min.v1)?'max':null">
                        {{(turn && turn.v1 != null)?toFixed(turn.v1,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('v1', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.v1 != null && selfTurn && selfTurn.v1 != null)?getDif('v1', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r2 == min.r2)?'max':null">
                        {{(turn && turn.r2 != null)?toFixed(turn.r2,3):'--'}}
                    </span>
                    <span [ngClass]="(getDif('r2', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.r2 != null && selfTurn && selfTurn.r2 != null)?getDif('r2', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v2 == min.v2)?'max':null">
                        {{(turn && turn.v2 != null)?toFixed(turn.v2,3):'--'}}
                    </span>
                    <span  [ngClass]="(getDif('v2', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.v2 != null && selfTurn && selfTurn.v2 != null)?getDif('v2', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r3 == min.r3)?'max':null">
                        {{(turn && turn.r3 != null)?toFixed(turn.r3,3):'--'}}
                    </span>
                    <span  [ngClass]="(getDif('r3', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.r3 != null && selfTurn && selfTurn.r3 != null)?getDif('r3', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v3 == min.v3)?'max':null">
                        {{(turn && turn.v3 != null)?toFixed(turn.v3,3):'--'}}
                    </span>
                    <span  [ngClass]="(getDif('v3', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.v3 != null && selfTurn && selfTurn.v3 != null)?getDif('v3', selfTurn, turn):'--'}}
                    </span>
                </div>
                <div>
                    <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rf == min.rf)?'max':null">
                        {{(turn && turn.rf != null)?toFixed(turn.rf,3):'--'}}
                    </span>
                    <span  [ngClass]="(getDif('rf', selfTurn, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                        {{(turn && turn.rf != null && selfTurn && selfTurn.rf != null)?getDif('rf', selfTurn, turn):'--'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 p-3 d-flex align-items-center justify-content-center" style="min-height:71px;">
        <button [disabled]="selfTurn == null" (click)="openAddTurnDialog()" class="addTurn">
            <i class="mdi mdi-playlist-edit"></i> {{'general.label_edit_list' | translate}}
        </button>
    </div>
    <!--//-->

</div>
