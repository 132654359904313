import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AwsService } from 'src/app/services/aws.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from 'src/app/components/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form:FormGroup;

  constructor(
    private router: Router,
    private formBuilder:FormBuilder,
    private awsService:AwsService,
    private toastr:ToastrService,
    private localStorage:LocalStorageService,
    private dialog:MatDialog
  ) {
    this.form = this.formBuilder.group({
      email:[null,[Validators.required]],
      password:[null,[Validators.required]]
    });
  }

  ngOnInit() {
  }

  login(){
    if(this.form.valid){
      let params = this.form.value;
      this.awsService.login(params.email, params.password).then((response) => {
        if(response != null && response.email != null && response.email.trim() != ""){
          this.localStorage.store('user',response);
          this.router.navigateByUrl("events"); // Estava redirecionando para /analysis/preconfig
        }else{
          let errorMessage = 'Unable to login, please check the data entered and try again!';
          if(response.message != null && response.message.trim() != "")
            errorMessage = response.message;
          if(errorMessage.includes("Email has not yet been verified")){
            this.dialog.open(ConfirmComponent, {
              data: {
                title:"Confirmation required",
                message:'<span class="mdi mdi-email" style="font-size: 60px;line-height: 10px;"></span><br>Please check the email we sent you in your mailbox, and click on the confirmation link.',
                canCancel:false
              },
              panelClass:"dialog"
            });
          }else{
            this.awsService.emailUnique(params.email).then((response) => {
              if(response != null && !response.success){
                this.toastr.error(errorMessage,'An error has occurred');
              }else{
                let dialogRef = this.dialog.open(ConfirmComponent, {
                  data: {
                    title:"User does not exist",
                    message:'Want to create a new account?',
                    confirmText:"Yes",
                    cancelText:"No"
                  },
                  panelClass:"dialog"
                });

                dialogRef.afterClosed().toPromise().then((confirm) => {
                  if(confirm)
                    this.router.navigate(['/register']);
                });
              }
            }).catch(() => {
              this.toastr.error(errorMessage,'An error has occurred');
            })
          }
        }
      }).catch((error) => {
        console.log(error);
        this.toastr.error('Unable to login, please check the data entered and try again!\n','An error has occurred');
      });
    }
  }


  resetPassword(){
    let email = this.form.get("email").value;

    if(email != null && email.trim() != ""){

      this.awsService.resetPassword(email).then((response) => {
        let title = "We send you an Email";
        let message = '<span class="mdi mdi-email" style="font-size: 60px;line-height: 10px;"></span><br>Please go to your mailbox and click on the link we have sent you to recover your password.';

        if(response != null && response.success){
          if(!response.data){
            title = "An error has occurred";
            message = "The email for password recovery could not be sent. Please try again!";
          }
        }

        this.dialog.open(ConfirmComponent, {
          data: {
            title:title,
            message:message,
            canCancel:false
          },
          panelClass:"dialog"
        });

      });
    }else{
      this.dialog.open(ConfirmComponent, {
        data: {
          title:"Attention",
          message:'Please enter your user email!',
          canCancel:false
        },
        panelClass:"dialog"
      });
    }
  }
}
