import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryModel } from '../models/category.model';
import { EventModel } from '../models/event.model';
import { BaseService } from './base.service';
import { TurnModel } from '../models/turn.model';
import { Router } from '@angular/router';

const BASE_LINK = environment.urlLambda || "https://jvalz6oba5.execute-api.sa-east-1.amazonaws.com/Prod/SplittimingAPI";

@Injectable({
  providedIn: 'root'
})
export class AwsService extends BaseService<any> {

  constructor(
    protected injector: Injector,
    private router:Router
  ) {
    super(injector, '', (json:any)=>json);
  }

  public saveAnalysis(data):Promise<{success:boolean, message:string}>{
    let url = BASE_LINK+"/saved_analyse";
    return this.customPost(data,url);
  }

  public saveMonetizeAnalyse(data):Promise<{success:boolean, message:string}>{
    let url = BASE_LINK+"/save_monetize_analyse";
    return this.customPost(data,url);
  }

  public saveMonetizeTop5Partials(data):Promise<{success:boolean, message:string}>{
    let url = BASE_LINK+"/save_monetize_top5_partials";
    return this.customPost(data,url);
  }

  public uploadLogo(filename, image):Promise<any>{

    let body = {
      filename:filename,
      image:image
    }
    return this.customPost(body, BASE_LINK+"/send_image").then((response) => {
      return response;
    }).catch( reason => {
      throw reason;
    });
  }

  public getAnalysis(params={}):Promise<any>{
    return this.customGet(params, BASE_LINK+"/saved_analyse").then((response) => {
      return response;
    }).catch( reason => {
      throw reason;
    });
  }

  public getPerformance(params={}):Promise<any>{
    return this.customGet(params, BASE_LINK+"/performance_pista").then((response) => {
      return response;
    }).catch( reason => {
      throw reason;
    });
  }

  public getVideo(params={}):Promise<any>{
      // link antigo: https://jvalz6oba5.execute-api.sa-east-1.amazonaws.com/Prod/equs/get_live_stream

    return this.customGet(params,BASE_LINK+"/get_live_stream").then((response) => {
      return response;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getInsight(params:any=null):Promise<any[]>{
    let url = BASE_LINK+"/insights";

    return this.customGet(params,url).then((response:{data:any[]}) => {
      return response.data;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getEvents(params:any=null):Promise<EventModel[]>{
    let url = BASE_LINK+"/events";

    return this.customGet(params,url).then((response:{data:any[]}) => {
      return response.data;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getFullEvents(params:any=null):Promise<EventModel>{
    let url = BASE_LINK+"/get_event_full";

    return this.customGet(params,url).then((response:{data:any}) => {
      if(response.data != null){
        let evento = Object.assign(new EventModel(), response.data["evento"]);
        evento.categorias = response.data["categorias"];
        evento.usuarios = response.data["usuarios"];
        return evento;
      }else{
        return null;
      }
    }).catch( reason => {
      throw reason;
    });
  }

  public async createEvent(data:{evento:EventModel,categorias:any[]}):Promise<{success:boolean, message:string}>{
    let url = BASE_LINK+"/get_save_full";
    return this.customPost(data,url);
  }

  public delEvent(params:{id_evento:number}){
    let id_evento = null;
    if(params != null)
      id_evento = params.id_evento;
    let url = BASE_LINK+"/event_del";
    return this.customGet({id_evento:id_evento},url);
  }

  public delCategory(params:{id_category:number}){
    let id_categoria = null;
    if(params != null)
    id_categoria = params.id_category;
    let url = BASE_LINK+"/categoria_del";
    return this.customGet({id_categoria:id_categoria},url);
  }

  public getRanking(id_evento=null, id_categoria=null,tipo:string=null, qtd:number=null):Promise<EventModel[]>{

    if(tipo == null)
      tipo = 'tr,tv,total';
    if(qtd == null)
      qtd = 5;

    let url = BASE_LINK+"/ranking";
    let params = {
      tipo:tipo,
      qtd:qtd
    };

    if(id_evento != null)
      params["id_evento"]=id_evento;
    if(id_categoria != null)
      params["id_categoria"]=id_categoria;

    return this.customGet(params,url).then((response:{data:any[]}) => {
      return response.data;
    }).catch( reason => {
      throw reason;
    });
  }

  public getCategories(params:any=null):Promise<any[]>{
    let url = BASE_LINK+"/categorias";

    /*let user = this.localStorage.retrieve("user");
    let token = (user != null)?user.token:null;
    let uid = (user != null)?user.firebase_uid:null;
    if(token != null && token.trim() != "" && uid != null && uid.trim() != ""){
      let headers = new Headers();
      headers.append('token',token);
      headers.append('uid',uid);*/
      return this.customGet(params,url).then((response:{data:any[]}) => {
        return response.data;
      }).catch( reason => {
        throw reason;
      });
    /*}else{
      this.localStorage.clear("user");
      this.router.navigateByUrl("/login");
    }*/
  }

  public getOfficialCategories():Promise<any[]>{
    let url = BASE_LINK+"/categorias_oficiais";

    let user = this.localStorage.retrieve("user");
    let token = (user != null)?user.token:null;
    let uid = (user != null)?user.firebase_uid:null;
    if(token != null && token.trim() != "" && uid != null && uid.trim() != ""){
      return this.customGet({},url).then((response:{data:any[]}) => {
        return response.data;
      }).catch( reason => {
        throw reason;
      });

    }else{
      this.localStorage.clear("user");
      this.router.navigateByUrl("/login");
    }
  }

  public getTurns(params:any=null):Promise<{passadas:TurnModel[],best:TurnModel}>{
    let url = BASE_LINK+"/passadas";
    return this.customGet(params,url).then(( response:{ data:{passadas:TurnModel[],best:TurnModel} }) => {
      return response.data;
    }).catch( reason => {
      throw reason;
    });

    // let user = this.localStorage.retrieve("user");
    // let token = (user != null)?user.token:null;
    // let uid = (user != null)?user.firebase_uid:null;
    // if(token != null && token.trim() != "" && uid != null && uid.trim() != ""){
    //   return this.customGet(params,url).then(( response:{ data:{passadas:TurnModel[],best:TurnModel} }) => {
    //     return response.data;
    //   }).catch( reason => {
    //     throw reason;
    //   });
    //
    // }else{
    //   this.localStorage.clear("user");
    //   this.router.navigateByUrl("/login");
    // }
  }

  public contactMessage(name:string,email:string,phone:string,message:string):Promise<void>{
    let url = BASE_LINK+"/contato";
    let body = {
      nome:name,
      email:email,
      telefone:phone,
      mensagem:message
    };

    return this.customPost(body,url);
  }

  public login(email, password){
    let url = BASE_LINK+"/login";
    let body = {
      email:email,
      password:password
    };

    return this.customPost(body,url);
  }

  public signup(name, email, password){
    let url = BASE_LINK+"/signup";
    let body = {
      name:name,
      email:email,
      password:password,
    };

    return this.customPost(body,url);
  }

  public resetPassword(email){
    let url = BASE_LINK+"/password_reset";
    let body = {
      email:email
    };
    return this.customPost(body,url);
  }

  public emailUnique(email){
    let url = BASE_LINK+"/email_unique";
    let body = {
      email:email
    };

    return this.customPost(body,url);
  }

  public setCompetitorsAndHorses(data){
    let url = BASE_LINK+"/set_competitors_and_horses";
    let body = {
      batch_data: data
    }
    return this.customPost(body,url);
  }

  public autocomplete(search, type, obj:boolean=false){
    let url = `${BASE_LINK}/autocomplete`;
    let params = {
      type:type,
      search:search
    };
    if(type == "evento"){
      params["past"]=1;
      params["live"]=1;
    }
    if(obj)
      params["obj"] = true;
    return this.customGet(params,url);
  }
    public autocompletePassadasFilters(params) {
        const url = `${BASE_LINK}/autocomplete_passadas_filters`;

        return this.customGet(params, url);
    }

    public getBestTvAndRf(params){
      const url = `${BASE_LINK}/best_tv_and_rf`;

      return this.customGet(params, url);

    }

  public sendPurchaseEmail(body:{comp: any, best:TurnModel,}):Promise<void>{
    let url = BASE_LINK+"/send_purchase_email";

    return this.customPost(body,url);
  }
  public sendPurchaseSms(body:{comp: any, best:TurnModel,}):Promise<void>{
    let url = BASE_LINK+"/send_purchase_sms";

    return this.customPost(body,url);
  }

}
