import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TurnModel } from 'src/app/models/turn.model';
import { MatDialog } from '@angular/material/dialog';
import { CompareDialogComponent } from './compare-dialog/compare-dialog.component';
import { AddTurnDialogComponent } from './add-turn-dialog/add-turn-dialog.component';
import { AwsService } from 'src/app/services/aws.service';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {

  turnOrder:number = null;
  turnPas:number = null;
  eventName:string = '';
  categoryName:string = '';

  updateInterval:any = null;

  min:TurnModel = new TurnModel();

  categories:string[] = [];
  selfTurn:TurnModel = null;
  filteredTurns:TurnModel[] = [];
  turns:TurnModel[] = [];

  filterText:string = "";

  sortFieldActivated:{name:string, value:number} = {
    name:"total",
    value:1
  }

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private awsService:AwsService
  ) {
    this.eventName = this.route.snapshot.paramMap.get("event");
    this.categoryName = this.route.snapshot.paramMap.get("category");
    this.turnOrder = parseInt(this.route.snapshot.paramMap.get("order"));
    this.turnPas = parseInt(this.route.snapshot.paramMap.get("pas"));
  }

  ngOnInit() {
    this.downloadCategories();
  }

  private downloadCategories(){
    let params = {
        evento:this.eventName,
        best:true
    };
    this.awsService.getCategories(params).then(categories => {
      this.categories = categories;
      this.downloadTurns(this.categoryName);
    }).catch((error)=>{
      console.log(error);
    });
  }

  public downloadTurns(categoryName){

    let params = {
      evento:this.eventName,
      categoria:categoryName
    };
    this.awsService.getTurns(params).then((turns:{passadas:TurnModel[],best:TurnModel}) => {
      this.turns = (turns!=null && turns.passadas != null)?turns.passadas:[];

      let bestTurn = turns.best;
      if(bestTurn != null){
        this.min = Object.assign(new TurnModel(),bestTurn);
        this.min.bestTotal = this.min.total;
      }else{
        this.min = new TurnModel();
      }

      if(this.selfTurn == null)
        this.selfTurn = this.turns.find(turn => turn.ord == this.turnOrder && turn.pas == this.turnPas);

      this.filteredTurns = this.definePlacementTurns(this.turns);
      this.filterTurns(this.filterText);
    }).catch((error)=>{
      console.log(error);
    });

  }

  private definePlacementTurns(turns:TurnModel[]):TurnModel[]{
    let result = this.sortTurns(turns,'pos');
    result = result.filter( (turn:TurnModel, index) => {
      turn.v1 = (turn.v1 != null)? parseFloat(turn.v1.toFixed(3)) : null;
      turn.v2 = (turn.v2 != null)? parseFloat(turn.v2.toFixed(3)) : null;
      turn.v3 = (turn.v3 != null)? parseFloat(turn.v3.toFixed(3)) : null;
      turn.r1 = (turn.r1 != null)? parseFloat(turn.r1.toFixed(3)) : null;
      turn.r2 = (turn.r2 != null)? parseFloat(turn.r2.toFixed(3)) : null;
      turn.r3 = (turn.r3 != null)? parseFloat(turn.r3.toFixed(3)) : null;
      turn.rf = (turn.rf != null)? parseFloat(turn.rf.toFixed(3)) : null;
      turn.tr = (turn.tr != null)? parseFloat(turn.tr.toFixed(3)) : null;
      turn.tv = (turn.tv != null)? parseFloat(turn.tv.toFixed(3)) : null;
      turn.rv1 = (turn.rv1 != null)? parseFloat(turn.rv1.toFixed(3)) : null;
      turn.rv2 = (turn.rv2 != null)? parseFloat(turn.rv2.toFixed(3)) : null;
      turn.rv3 = (turn.rv3 != null)? parseFloat(turn.rv3.toFixed(3)) : null;
      turn.rvr1 = (turn.rvr1 != null)? parseFloat(turn.rvr1.toFixed(3)) : null;
      turn.rvr2 = (turn.rvr2 != null)? parseFloat(turn.rvr2.toFixed(3)) : null;
      turn.rvr3 = (turn.rvr3 != null)? parseFloat(turn.rvr3.toFixed(3)) : null;

      turn.rvrt = (turn.rvr1 != null && turn.rvr2 != null && turn.rvr3 != null)?(turn.rvr1 + turn.rvr2 + turn.rvr3):null;

      if(turn.total != null && !isNaN(turn.total))
        turn.total = (turn.total != null)?parseFloat(turn.total.toFixed(3)):null;

      turn.sat=turn.sat!=true?false:true;
      turn.nc=turn.nc!=true?false:true;

      if(turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true){
        if(turn.categoria == this.categoryName){
          this.min.rvrt = ((turn.rvrt != null && this.min.rvrt == null) || (turn.rvrt != null && turn.rvrt>0 && turn.rvrt < this.min.rvrt))?turn.rvrt:this.min.rvrt;
          this.min.total = this.min.v1 +  this.min.v2 +  this.min.v3 + this.min.r1+ this.min.r2+ this.min.r3+ this.min.rf;
        }
        if(turn.placing == null)
          turn.placing = index+1;

        if(turn.cavalo == this.selfTurn.cavalo && this.selfTurn.competidor == turn.competidor && this.selfTurn.categoria == turn.categoria){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    });

    return result;
  }

  public autoUpdate(update:boolean){
    if(update){
      this.updateInterval = setInterval(() => {
        this.downloadTurns(this.categoryName);
      }, 30000) // 30 sec
    }else{
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  public sortTurns(turns:TurnModel[],field="total",order=1):TurnModel[]{
    let result = turns;
    if(turns.length > 0){
      let noTotalTurns = [];
      if(field == "total"){
        noTotalTurns = result.filter(turn => isNaN(turn[field]) || !turn[field]);
        result = result.filter(turn => !isNaN(turn[field]) && turn[field]);
      }

      result = result.sort((turnA:TurnModel, turnB:TurnModel) => {

        if ( turnA[field] < turnB[field]){
          return (order >= 0)? -1 : 1;
        }else if( turnA[field] > turnB[field] ){
          return (order >= 0)? 1 : -1;
        }
        return 0;
      });
      result = result.concat(noTotalTurns);
    }
    return result;
  }

  public filterTurns(text:string){
    this.filterText = text;
    if(text != null && text.trim() != ""){
      text = text.toLowerCase();

      this.filteredTurns = this.turns.filter( turn => {
        turn.sat=turn.sat!=true?false:true;
        turn.nc=turn.nc!=true?false:true;

        let condition = turn.cavalo.toLowerCase().includes(text) || turn.competidor.toLowerCase().includes(text);
        condition = condition && (turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true);
        condition = condition && !(turn.cavalo == this.selfTurn.cavalo && this.selfTurn.competidor == turn.competidor && this.selfTurn.categoria == turn.categoria && this.selfTurn.pas == turn.pas);
        return condition;
      });
    }else{

      this.filteredTurns = this.turns.filter( turn => {
        turn.sat=turn.sat!=true?false:true;
        turn.nc=turn.nc!=true?false:true;

        let condition = !(turn.cavalo == this.selfTurn.cavalo && this.selfTurn.competidor == turn.competidor && this.selfTurn.categoria == turn.categoria && this.selfTurn.pas == turn.pas);
        condition = condition && (turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true);
        return condition;
      });
      this.filteredTurns = this.sortTurns(this.filteredTurns,this.sortFieldActivated.name,this.sortFieldActivated.value);
    }
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  public getDif(field, turnA, turnB){
    let label = '--';
    if(turnA != null && turnB != null){
      if(turnA[field]!=null && turnB[field] != null){
        let dif = turnA[field] - turnB[field];
        label = (dif > 0?'+':'') + (dif).toFixed(3);
      }
    }
    return label;
  }

  public openCompareDialog(compareWith:TurnModel,best=false):void{
    this.dialog.open(CompareDialogComponent, {
      data: {
        selfTurn:this.selfTurn,
        turn:compareWith,
        best:best,
        categoria:this.categoryName,
        evento:this.eventName,
      },
      maxHeight:"calc(100vh - 30px)",
      maxWidth:"calc(100vw - 30px)",
      panelClass:"dialog"
    });
  }

  public openAddTurnDialog():void{
    let dialogRef = this.dialog.open(AddTurnDialogComponent, {
      data: {
        categoryName:this.categoryName,
        eventName:this.eventName,
        selfTurn: this.selfTurn,
        turns:this.filteredTurns
      },
      maxHeight:"calc(100vh - 30px)",
      maxWidth:"calc(100vw - 30px)",
      panelClass:"dialog"
    });

    dialogRef.afterClosed().subscribe((turns:TurnModel[]) => {
      if(turns != null){
        this.turns = turns;
        this.filteredTurns = this.definePlacementTurns(this.turns);
        this.filterTurns(this.filterText);
        this.filteredTurns = this.sortTurns(this.filteredTurns,'pos');
      }
    });
  }

}
