<div id="page-content">
    <div class="header">
        <h3 class="title">
            <i class="mdi mdi-magnify"></i> {{'general.label_search' | translate}}
        </h3>
        <i class="mdi mdi-close ml-auto text-white" (click)="close()"></i>
    </div>
    <div class="container-fluid p-0">
        <div class="row mx-0 pb-3 pt-2">
            <div class="col-12">
                <div class="equsSelect mdi mdi-menu-down">
                    <span>{{'general.choose_class_label' | translate}}</span>
                    <select [(ngModel)]="categoryName" (change)="downloadTurns($event.target.value)">
                        <option *ngFor="let category of categories" [value]="category.categoria">{{category.categoria}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="equsSearchInput mdi mdi-magnify mb-0">
                    <input type="text" placeholder="{{'general.label_search' | translate}}" (input)="filterTurns($event.target.value)">
                </div>
            </div>
        </div>
    </div>
    <div class="turn">
        <div class="turn_header">
            <div class="participant">
                <span class="position mr-2">
                    #
                </span>
                <span class="horse">
                    {{'general.label_horse' | translate}}
                </span>
                <span class="competitor">
                    {{'general.label_competitor' | translate}}
                </span>
            </div>
            <div class="options pl-2" style="width: 24px;">
                <mat-checkbox [checked]="isCheckedAll()" (change)="checkAll($event)" color="primary">

                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="w-100 flex-grow-1 scrollbar" style="overflow-y:auto;overflow-x: hidden;">
        <div class="turn" (click)="onTurnChecked(turn)"  *ngFor="let turn of filteredTurns">
            <div class="turn_header pr-2">
                <div class="participant">
                    <span class="position mr-2">
                        {{(turn && turn.pos)?turn.pos+"º":'--'}}
                    </span>
                    <span class="horse">
                        <img src="../../../assets/images/icons/horse-icon.png">
                        <div>{{(turn && turn.cavalo)?turn.cavalo:'--'}}</div>
                    </span>
                    <span class="competitor">
                        <img src="../../../assets/images/icons/cowboy-icon.png">
                        <div>{{(turn && turn.competidor)? turn.competidor:'--'}}</div>
                    </span>
                </div>
                <div class="options pl-2">
                    <mat-checkbox style="pointer-events:none;" [checked]="isChecked(turn)" color="primary"></mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row p-3">
            <button [disabled]="!selectedTurns.length" (click)="addTurns()" class="addTurn w-100">
                <i class="mdi mdi-arrow-collapse-down"></i>
                {{'general.label_save_changes' | translate}}
            </button>
    </div>
</div>
