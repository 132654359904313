import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar'
import { TurnModel } from 'src/app/models/turn.model';
import { AwsService } from 'src/app/services/aws.service';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss']
})
export class CompareDialogComponent implements OnInit {
  @ViewChild('laco',{static:true}) image:ElementRef<HTMLImageElement>;
  @ViewChild('difs',{static:true}) difs:ElementRef<HTMLElement>;
  best:boolean = false;
  selfTurn:TurnModel = null;
  turn:TurnModel = null;

  eventName:string = "";
  categoryName:string = "";

  maxRulerTRV = null;
  maxRulerRVR = null;
  maxRulerRV = null;
  maxRuler = null;

  colors = {
    'r1':'#9b1111',
    'v1':'#d6710a',
    'r2':'#3b9116',
    'v2':'#1e859e',
    'r3':'#50368a',
    'v3':'#90207a',
    'rf':'#ffffff',
    'pen':'#f00'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{selfTurn:TurnModel, turn:TurnModel, best:boolean, categoria:string, evento:string},
    public dialogRef: MatDialogRef<CompareDialogComponent>,
    public router:Router,
    private awsService:AwsService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.selfTurn = this.data.selfTurn
    this.turn = this.data.turn;
    this.best = this.data.best;


    this.eventName = this.data.evento;
    this.categoryName = this.data.categoria

    let totTRV = this.turn.tr + this.turn.tv;
    let totSTRV = this.selfTurn.tr + this.selfTurn.tv;
    this.maxRulerTRV = (totTRV > totSTRV)?totTRV:totSTRV;

    let totRVR = this.turn.rvr1+this.turn.rvr2+this.turn.rvr3;
    let totSRVR = this.selfTurn.rvr1+this.selfTurn.rvr2+this.selfTurn.rvr3;
    this.maxRulerRVR = (totRVR > totSRVR)?totRVR:totSRVR;

    let totRV = this.turn.rv1+this.turn.rv2+this.turn.rv3 + this.turn.rf;
    let totSRV = this.selfTurn.rv1+this.selfTurn.rv2+this.selfTurn.rv3 + this.selfTurn.rf;
    this.maxRulerRV = (totRV > totSRV)?totRV:totSRV;

    let tot = this.turn.total;
    let totS = this.selfTurn.total;
    this.maxRuler = (tot > totS)?tot:totS;
  }

  ngOnInit() {
  }


  public close():void{
    this.dialogRef.close();
  }

  getTurnPos(turn){
    if (turn && turn.pos_txt)
      return turn.pos_txt
    if (turn && turn.pos)
      return turn.pos+"º"
    return '--';
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  public getRulerSectionWidth(field, turn:TurnModel){
    let width = 0;
    let total = turn.total;
    if(total != null){
      width = (turn[field]*100)/total;
    }
    return width;
  }
  public getRulerSectionValueWidth(value, turn:TurnModel){
    let width = 0;
    let total = turn.total;
    if(total != null){
      width = (value*100)/total;
    }
    return width;
  }

  public getDif(field){
    let label = '';

    if(this.selfTurn[field]!=null && this.turn[field] != null){
      let dif = this.turn[field] - this.selfTurn[field];
      label = (dif > 0?'+':'') + (dif).toFixed(3);
    }
    return label;
  }

  public getTotals(turn){
    let reta = (turn != null && turn.rt)?turn.rt:0;
    let curva = (turn != null && turn.rv)?turn.rv:0;
    let tot = reta+curva;
    let perc_reta = 100*reta/tot;
    let perc_curva = 100*curva/tot;
    let total = {
      perc_reta:perc_reta.toFixed(3),
      perc_curva:perc_curva.toFixed(3)
    }
    return total;
  }

  public getTotalsDifferences(){
    let dif = this.turn.tr - this.selfTurn.tr;
    let reta = (dif>0?'+':'')+this.toFixed(dif,3);

    dif = this.turn.tv - this.selfTurn.tv;
    let curva = (dif>0?'+':'')+this.toFixed(dif,3);

    let difs ={
      reta: reta,
      curva: curva
    }
    return difs;
  }

  public share(){
    let analise = {
      analise:[this.turn, this.selfTurn],
      best:this.best,
      categoria:this.categoryName,
      evento:this.eventName
    }
    this.awsService.saveAnalysis(analise).then((data) => {
      const host: string =  location.origin;
      // const url: string = host + String(this.router.createUrlTree(['/analysis-share'], { queryParams: {id:data["id_analise"]} }));

      this.router.navigate(['/analysis-share'],{ queryParams: {id:data["id_analise"]} }).then(()=>{
          this.dialogRef.close();
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  public openSnackBar(message: string, type: string) {
    let config: MatSnackBarConfig = {
      panelClass:'snack_' + type,
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    };
    this._snackBar.open(message, 'Ok', config);
  }

  public getRVtotals(turn){
    let rv1 = ((turn != null && turn.rv1)?turn.rv1:0);
    let rv2 = ((turn != null && turn.rv2)?turn.rv2:0);
    let rv3 = ((turn != null && turn.rv3)?turn.rv3:0);
    let rf = ((turn != null && turn.rf)?turn.rf:0);
    let tot = rv1+rv2+rv3+rf;
    let perc_rv1 = 100*rv1/tot;
    let perc_rv2 = 100*rv2/tot;
    let perc_rv3 = 100*rv3/tot;
    let perc_rf = 100*rf/tot;

    let total = {
      perc_rv1:perc_rv1.toFixed(3),
      perc_rv2:perc_rv2.toFixed(3),
      perc_rv3:perc_rv3.toFixed(3),
      perc_rf:perc_rf.toFixed(3)
    }
    return total;
  }

  getTotal(array_values){
    let tot = 0;
    for(let val of array_values){
      if(val!=null && isNaN(val)==false) tot += parseFloat(val);
    }
    return tot.toFixed(3)
  }
}
