<div id="page-content">
    <div class="container-fluid" style="background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);">
        <div class="row" style="padding: 0px 15px 10px;border-top: 1px solid rgb(255 255 255 / 25%);">
            <div  class="col-12 p-0 d-flex flex-column justify-content-end">
                <span class="px-3 py-2 d-flex flex-row justify-contente-between" style="color: #fff;font-size: 1.2em;">
                        {{eventName}}
                        <button class="bt-print" (click)="generatePDF()" [disabled]="loading"
                                style="margin-right: 3px;margin-left: auto">
                                <i class="mdi mdi-printer"></i>
                        </button>
                </span>
                <div class="row m-0 mt-3">
                    <div class="col-12 col-md-6 d-flex flex-column justify-content-end">
                        <div class="equsSelect mdi mdi-menu-down">
                            <span>{{'general.choose_class_label' | translate}}</span>
                            <select (change)="downloadTurns($event.target.value)">
                                <option *ngFor="let category of categories" [selected]="category.categoria==categoryName" [value]="category.categoria">{{category.categoria}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column justify-content-end">
                        <div class="equsSearchInput mdi mdi-magnify">
                            <input type="text" placeholder="{{'general.label_search' | translate}}" (input)="filterTurns($event.target.value)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="turns_header">
            <span (click)="sortBy($event,'seq')">
                # <i class="mdi mdi-arrow-down active"></i>
            </span>
            <span (click)="sortBy($event,'competidor')">{{'general.label_competitor' | translate}} <i class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'pos')">{{'general.label_placement' | translate}} <i class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'posd')">{{'general.label_placing' | translate}} <i class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" style="height: 3px; margin-top: 1px"></mat-progress-bar>
    <ul class="turns scrollbar">

        <li *ngFor="let turn of filteredTurns" (click)="toAnalysis(turn)" class="turn">
            <span class="order" [attr.data-text]="turn.ord"></span>
            <div>
                <div>
                    <span class="horse">
                        <img src="../../../assets/images/icons/horse-icon.png">
                        <div>{{turn.cavalo}}</div>
                    </span>
                    <span class="competitor">
                        <img src="../../../assets/images/icons/cowboy-icon.png">
                        <div>{{turn.competidor}}</div>
                    </span>
                </div>
                <div>

                    <div style="display:flex;flex-direction:column;align-items:center;justify-content: space-between;">
                        <span class="placing">{{getPlacing(turn)}}</span>
                        <span class="total"><i class="mdi relogio mdi-timer-outline" [attr.data-pas]="turn.pas" style="margin-top: -5px; font-size: 1.6em; position: relative; display: flex; justify-content: center; align-items: center; font-weight: 100;margin-right:3px;"></i> {{getTotalTime(turn)}}</span>
                    </div>
                    <div style="display:flex;flex-direction:column;align-items:center;justify-content: space-between;">
                        <span class="class">{{getClass(turn)}}</span>
                    </div>
                    <i class="mdi mdi-chevron-right" style="font-size: 2em;color: rgba(255,255,255,0.4);"></i>
                </div>
            </div>
        </li>
    </ul>

</div>
