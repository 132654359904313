import { Injectable, Injector } from '@angular/core';
import { HttpResponse} from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { TurnModel } from '../models/turn.model';
import { EventModel } from '../models/event.model';
import {CategoryModel} from '../models/category.model';
// @ts-ignore
import moment from 'moment';


  /*
    
  0 eventos
  1 passadas
  2 categorias
  */
 
@Injectable({
  providedIn: 'root'
})
export class FlespiService extends BaseService<any> {

  constructor(protected injector: Injector) {
    super(injector, '', (json:any)=>json);
  }

  public async getCategoriasDetails(eventName:string){
      let url = this.apiPath;
      url += `name=${eventName}/messages`;

      let params = {
          data:JSON.stringify({"filter": "type=2"})
      };

      let custom_headers = {};
      custom_headers['Accept'] = 'application/json';
      custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

      let headers = await this.buildHeader(custom_headers);

      return this.customGet(params,url,headers).then((responses:any[]) => {
          let res = (responses['result'])?responses['result']:[];
          if(res != null && Array.isArray(res)){
              res = res[0].params
          }
          return res;
      }).catch( reason => {
          throw reason;
      });
  }

  public async getEvents(eventName:String=''):Promise<EventModel[]>{
    let url = this.apiPath;
    if(eventName.trim()==''){
      url += 'all/messages';
    }else{
      url += `name=${eventName}/messages`;
    }
    let params = {
      data:JSON.stringify({"filter": "type=0"})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((responses:any[]) => {
      let res = (responses['result'])?responses['result']:[];
      if(res != null && Array.isArray(res)){
        res = res.map(response => Object.assign(new EventModel(), response.params));
      }

      return res;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getLastLiveMessage(categoryName:String=''):Promise<EventModel[]>{
    let url = this.apiPath + 'all/messages';

    let params = {
      data:JSON.stringify({"filter": "type=4"})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((responses:any[]) => {
      let res = (responses['result'])?responses['result']:[];
      if(res != null && Array.isArray(res)){
        res = res[0].params;
      }
      return res;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getRanking(eventName:String=''):Promise<EventModel[]>{
    let url = this.apiPath;
    if(eventName.trim()==''){
      url += 'all/messages';
    }else{
      url += `name=${eventName}/messages`;
    }

    let params = {
      data:JSON.stringify({"filter": "type=10"})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((responses:any[]) => {
      //console.log(responses);
      let res = (responses['result'])?responses['result']:[];
      if(res != null && Array.isArray(res)){
        res = res[0].params.tv;
      }
      return res;
    }).catch( reason => {
      throw reason;
    });
  }

  public getCatDetailByName(details:any,name:string) {

      for(let key of Object.keys(details)){
          try{
              if ( details[key].Categoria == name )
                  return details[key];
          }catch (e) {
          }
      }
      return null;
  }

  public async getCategories(eventName:String):Promise<any>{
    let url = this.apiPath;
    if(eventName != null && eventName.trim()==''){
      url += 'all/messages';
    }else{
      url += `name=${eventName}/messages`;
    }
    let params = {
      data:JSON.stringify({"filter": "type=3"})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((responses:any[]) => {
      let res = (responses['result'])?responses['result']:[];
      if(res != null && Array.isArray(res)){
        res = res.map(response => Object.assign(new TurnModel(), response.params));
      }
      return res;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getTurns(eventName:String,categoryName:String, sat:boolean = true):Promise<TurnModel[]>{
    let url = `${this.apiPath}name=${eventName}/messages`;
    let filter =  `type=1&&categoria='${categoryName}'&&competidor="*"&&cavalo="*"`;
    if(!sat) filter += `&&sat!='1'`;

    let params = {
      data:JSON.stringify({"filter": filter})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((response:HttpResponse<any>) => {
      let results = (response['result'])?response['result']:[];
      let turns:TurnModel[] = results.map(result => {
        let turn:TurnModel = new TurnModel();
        if(result['params'] != null)
          turn = Object.assign(turn,result['params']);
        return turn;
      });
      return turns;
    }).catch( reason => {
      throw reason;
    });
  }

  public async getTurn(eventName:String,categoryName:String, order:number):Promise<TurnModel>{
    let url = `${this.apiPath}name=${eventName}/messages`;
    let params = {
      data:JSON.stringify({"filter": `categoria='${categoryName}'&&ord=${order}`})
    };

    let custom_headers = {};
    custom_headers['Accept'] = 'application/json';
    custom_headers['Authorization'] = `FlespiToken ${environment.flespiToken}`;

    let headers = await this.buildHeader(custom_headers);

    return this.customGet(params,url,headers).then((response:HttpResponse<any>) => {
      let results = (response['result'])?response['result']:[];
      let turn:TurnModel = null;
      if(results.length)
        turn = Object.assign(new TurnModel(), results[0]['params']);
      return turn;
    }).catch( reason => {
      throw reason;
    });
  }

}
