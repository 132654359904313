import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit{
  title:string="";
  message:string = "";
  icon:string = null;
  canCancel:boolean = true;
  confirmText:string = this.translate.instant('general.label_ok');
  cancelText:string = this.translate.instant('general.label_cancel');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{title:string, message:string, icon:string, canCancel:boolean},
    public dialogRef: MatDialogRef<ConfirmComponent>,
    private translate: TranslateService,
  ) {
    if(this.data != null && this.data.title != null)
      this.title = this.data.title;
    if(this.data != null && this.data.icon != null)
      this.icon = this.data.icon;
    if(this.data != null && this.data.message != null)
      this.message = this.data.message;
    if(this.data != null && this.data.canCancel != null)
      this.canCancel = this.data.canCancel;
  }

  ngOnInit(){

  }

  cancel(){
    this.close(false);
  }
  confirm(){
    this.close(true);
  }

  close(confirm:boolean){
    this.dialogRef.close(confirm);
  }

}
