import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { SafeHtmlPipe } from 'src/pipes/safe-html';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AuthGuardService} from "../services/auth-guard.service";
import {AuthService} from "../services/auth.service";
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";



@NgModule({
  declarations: [ConfirmComponent, SafeHtmlPipe, UploadDialogComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
  exports:[
    ConfirmComponent,
    UploadDialogComponent,
    SafeHtmlPipe
  ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {}},
        { provide: MatDialogRef,useValue: {},}
    ],
})
export class ComponentsModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}