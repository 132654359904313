import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CompareComponent } from './pages/compare/compare.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { CompareDialogComponent } from './pages/compare/compare-dialog/compare-dialog.component';
import { AddTurnDialogComponent } from './pages/compare/add-turn-dialog/add-turn-dialog.component';
import { EventsComponent } from './pages/events/events.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { LiveComponent } from './pages/live/live.component';
import { TvComponent } from './pages/tv/tv.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ToastrModule } from 'ngx-toastr';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { AnalysisCardComponent } from './pages/analysis/analysis-card/analysis-card.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { ComponentsModule } from './components/components.module';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { TransmissaoComponent } from './pages/transmissao/transmissao.component';
import { Transmissao1Component } from './pages/transmissao1/transmissao1.component';
import { ImageDialogComponent } from './pages/ranking/image-dialog/image-dialog.component';
import { FarolComponent } from './pages/transmissao/farol/farol.component';
import { SafePipe } from 'src/pipes/safe';
import { RankingShareComponent } from './pages/ranking-share/ranking-share.component';
import { TotenComponent } from './pages/toten/toten.component';
import { AnalysisShareComponent } from './pages/analysis/analysis-share/analysis-share.component';
import { InsightComponent } from './pages/insight/insight.component';
import { RankingShareImgComponent } from './pages/ranking/ranking-share-img/ranking-share-img.component';
import { AnalysisPreconfigComponent } from './pages/analysis/analysis-preconfig/analysis-preconfig.component';
import { Transmissao2Component } from './pages/transmissao2/transmissao2.component';
import { Transmissao3Component } from './pages/transmissao3/transmissao3.component';
import { LogosListComponent } from './components/logos-list/logos-list.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDialogModule} from '@angular/material/dialog';
import {CookieService} from 'ngx-cookie-service';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CookieNoticeComponent } from './pages/cookie-notice/cookie-notice.component';
import { TransmissaoBestTvAndRFComponent } from './pages/transmissao-best-tv-and-rf/transmissao-best-tv-and-rf.component';
import { ImageAllDialogComponent } from './pages/ranking/image-all-dialog/image-all-dialog.component';
import { BuyAnalysisComponent } from './pages/buy-analysis/buy-analysis.component';
import { PaymentComponent } from './pages/payment/payment.component';

import { PurchasedAnalysisComponent } from './pages/purchased-analysis/purchased-analysis.component';
import { HowToWorksComponent } from './pages/how-to-works/how-to-works.component';
import { RedirectToByComponent } from './pages/buy-analysis/redirect-to-by/redirect-to-by.component';
import { CompetitorCategoryAnalysisComponent } from './pages/buy-analysis/competitor-category-analysis/competitor-category-analysis.component';
import { CompetitorEventAnalysisComponent } from './pages/buy-analysis/competitor-event-analysis/competitor-event-analysis.component';
import { LiveUpperComponent } from './pages/live-upper/live-upper.component';
import { BuySpecialReportsComponent } from './pages/buy-special-reports/buy-special-reports.component';
import { SpecialReportsComponent } from './pages/special-reports/special-reports.component';
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { BuyComponent } from './pages/buy/buy.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CompareComponent,
    CompareDialogComponent,
    AddTurnDialogComponent,
    EventsComponent,
    CategoriesComponent,
    CategoryCardComponent,
    LiveComponent,
    TvComponent,
    RankingComponent,
    LandingComponent,
    AnalysisComponent,
    AnalysisCardComponent,
    RegisterComponent,
    LoginComponent,
    TransmissaoComponent,
    Transmissao1Component,
    ImageDialogComponent,
    FarolComponent,
    SafePipe,
    RankingShareComponent,
    TotenComponent,
    AnalysisShareComponent,
    InsightComponent,
    RankingShareImgComponent,
    AnalysisPreconfigComponent,
    Transmissao2Component,
    Transmissao3Component,
    LogosListComponent,
    CookieNoticeComponent,
    TransmissaoBestTvAndRFComponent,
    ImageAllDialogComponent,
    BuyAnalysisComponent,
    PaymentComponent,
    PurchasedAnalysisComponent,
    HowToWorksComponent,
    RedirectToByComponent,
    CompetitorCategoryAnalysisComponent,
    CompetitorEventAnalysisComponent,
    LiveUpperComponent,
    BuySpecialReportsComponent,
    SpecialReportsComponent,
    BuyComponent,
  ],
    imports: [
        MatTabsModule,
        MatDialogModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        BrowserModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        NgxWebstorageModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        MatSnackBarModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatButtonModule,
        MatProgressSpinnerModule
    ],
  providers: [
    AuthGuardService,
    AuthService,MatDialog,
    CookieService,

    {provide: MatDialogRef, useValue: {},},
    { provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: 'googleTagManagerId', useValue: 'GTM-NTNV5NR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
