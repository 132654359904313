import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FlespiService } from 'src/app/services/flespi.service';
import { TurnModel } from 'src/app/models/turn.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-turn-dialog',
  templateUrl: './add-turn-dialog.component.html',
  styleUrls: ['./add-turn-dialog.component.scss']
})
export class AddTurnDialogComponent implements OnInit {

  categoryName:string = "";
  eventName:string = "";

  selectedTurns:TurnModel[] = [];

  sortFieldActivated:{name:string, value:number} = {
    name:"pos",
    value:1
  }

  selfTurn:TurnModel = null;

  categories:string[] = [];
  filteredTurns:TurnModel[] = [];
  turns:TurnModel[] = [];

  filterText:string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{categoryName:string, eventName:string, selfTurn:TurnModel, turns:TurnModel[]},
    public dialogRef: MatDialogRef<AddTurnDialogComponent>,
    private flespiService: FlespiService,
    private translate: TranslateService,


  ) {
    this.eventName = this.data.eventName;
    this.categoryName = this.data.categoryName;
    this.selfTurn = this.data.selfTurn;
    this.selectedTurns = this.data.turns.map(turn => Object.assign(new TurnModel(), turn));
  }

  ngOnInit() {
    this.downloadCategories();
  }

  private downloadCategories(){
    this.flespiService.getCategories(this.eventName).then((categories) => {
      this.categories = categories;
      this.downloadTurns(this.categoryName);
    }).catch(error => {
      console.log(error);
    });
  }

  public downloadTurns(categoryName){
    this.categoryName = categoryName;

    this.flespiService.getTurns(this.eventName, categoryName, false).then((turns:TurnModel[]) => {
      this.turns = turns;
      
      if(this.filterText.trim() != ""){
        this.filterTurns(this.filterText);
      }else{
        this.filteredTurns = this.definePlacementTurns(this.turns);
      }

      this.filteredTurns = this.sortTurns(this.filteredTurns,'seq');

        /*
      for(let turn of this.filteredTurns){
        if(!this.selectedTurns.some(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria)){
          this.selectedTurns.push(turn);
        }
      }
    */
      
    }).catch(error => {
      console.log(error);
    })
  }

  private definePlacementTurns(turns:TurnModel[]):TurnModel[]{
    let result = turns.filter( (turn:TurnModel, index) => {
      turn.sat=turn.sat!=true?false:true;
      turn.nc=turn.nc!=true?false:true;
      if(turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true){
          return true;
      }else{
        return false;
      }
    });
    return result;
  }

  public sortTurns(turns:TurnModel[],field="pos",order=1):TurnModel[]{
    let result = turns;
    if(turns.length > 0){
      let noTotalTurns = [];
      if(field == "total"){
        noTotalTurns = result.filter(turn => isNaN(turn[field]) || !turn[field]);
        result = result.filter(turn => !isNaN(turn[field]) && turn[field]);
      }

      result = result.sort((turnA:TurnModel, turnB:TurnModel) => {
        
        if ( turnA[field] < turnB[field]){
          return (order >= 0)? -1 : 1;
        }else if( turnA[field] > turnB[field] ){
          return (order >= 0)? 1 : -1;
        }
        return 0;
      });
      result = result.concat(noTotalTurns);
    }
    return result;
  }

  public filterTurns(text:string){
    this.filterText = text;
    if(text != null && text.trim() != ""){
      text = text.toLowerCase();

      this.filteredTurns = this.turns.filter( turn => {
        turn.sat=turn.sat!=true?false:true;
        turn.nc=turn.nc!=true?false:true;

        let condition = turn.cavalo.toLowerCase().includes(text) || turn.competidor.toLowerCase().includes(text);
        condition = condition && (turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true);
        condition = condition && !(turn.cavalo == this.selfTurn.cavalo && this.selfTurn.competidor == turn.competidor && this.selfTurn.categoria == turn.categoria);
        return condition;
      });
    }else{

      this.filteredTurns = this.turns.filter( turn => {
        turn.sat=turn.sat!=true?false:true;
        turn.nc=turn.nc!=true?false:true;

        let condition = !(turn.cavalo == this.selfTurn.cavalo && this.selfTurn.competidor == turn.competidor && this.selfTurn.categoria == turn.categoria);
        condition = condition && (turn.total != null && isNaN(turn.total)==false && turn.sat!=true && turn.nc!=true);
        return condition;
      });
      this.filteredTurns = this.sortTurns(this.filteredTurns,this.sortFieldActivated.name,this.sortFieldActivated.value);
    }
  }

  isChecked(turn){
    return this.selectedTurns.some(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria);
  }
  onTurnChecked(turn:TurnModel){
    let checked = !this.isChecked(turn);
    if(checked){
      if(!this.selectedTurns.some(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria)){
        this.selectedTurns.push(turn);
      }
    }else{
      let selectedTurn = this.selectedTurns.find(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria);
      if(selectedTurn != null){
        let index = this.selectedTurns.indexOf(selectedTurn);
        this.selectedTurns.splice(index,1);
      }
    }
  }

  public addTurns(){
    this.dialogRef.close(this.selectedTurns);
  }

  public checkAll(event){
    let checked = event.checked;
    if(checked){
      for(let turn of this.filteredTurns){
        if(!this.selectedTurns.some(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria))
          this.selectedTurns.push(turn);
      }
    }else{
      for(let turn of this.filteredTurns){
        for(let i=0;i<this.selectedTurns.length;i++){
          if(this.selectedTurns[i].cavalo == turn.cavalo && this.selectedTurns[i].competidor == turn.competidor && this.selectedTurns[i].categoria == turn.categoria){
            this.selectedTurns.splice(i, 1);
            break;
          }
        }
      }
    }

  }

  public isCheckedAll(){
    let total = this.filteredTurns.length;
    let sel = 0;
    for(let turn of this.filteredTurns){
      if(this.selectedTurns.some(selectedturn => selectedturn.cavalo == turn.cavalo && selectedturn.competidor == turn.competidor && selectedturn.categoria == turn.categoria)){
        sel++;
      }
    }
    return (sel==total);
  }

  public close():void{
    this.dialogRef.close();
  }

}
