<div id="page-content">
    <div class="container-fluid px-0 py-4 scrollbar" style="overflow:auto;background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);    min-height: calc(100vh - var(--header-height));">
        <div class="row w-100 mx-0">
            <div class="col-12 col-md-6 col-lg-6 col-xl-4 mx-auto my-5">

                <div class="box">
                    <h3 class="heading">{{'analysis_preconfig.analysis_title' | translate}}</h3>
                    <p class="mb-2 pb-3" style="border-bottom: 1px solid #ffffff22;">{{'analysis_preconfig.analysis_text' | translate}}:</p>


                    <form [formGroup]="filterForm" (ngSubmit)="set_search()">
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'analysis_preconfig.event' | translate}}</label>
                                <input  class="form-control" type="text" (input)="onEventSearch($event)"
                                [matAutocomplete]="event" formControlName="evento">
                                <mat-autocomplete #event="matAutocomplete">
                                    <mat-option *ngFor="let option of eventOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'analysis_preconfig.class' | translate}}</label>
                                <input class="form-control" type="text" (input)="onCategoriaSearch($event)" formControlName="categoria"  [matAutocomplete]="categoria">
                                <mat-autocomplete #categoria="matAutocomplete">
                                    <mat-option *ngFor="let option of categoriaOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'analysis_preconfig.horse' | translate}}</label>
                                <input class="form-control"  type="text" (input)="onCavaloSearch($event)"
                                 formControlName="cavalo" [matAutocomplete]="cavalo">
                                <mat-autocomplete #cavalo="matAutocomplete">
                                    <mat-option *ngFor="let option of cavaloOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'analysis_preconfig.competitor' | translate}}</label>
                                <input class="form-control" type="text" (input)="onCompetidorSearch($event)" formControlName="competidor" [matAutocomplete]="competidor">
                                <mat-autocomplete #competidor="matAutocomplete">
                                    <mat-option *ngFor="let option of competidorOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-between">
                            <button class="search_button" type="button" (click)="clear_search()">{{'general.label_clear' | translate}}</button>
                            <button class="search_button buscar" type="submit">{{'general.label_ok' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto d-flex flex-column align-items-center my-5">
                <h3 class="heading text-white mb-3">{{'analysis_preconfig.learn_howto' | translate}}</h3>
                <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px" src="https://www.youtube.com/embed/MQMUDS-ILPA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
