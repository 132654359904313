<div id="page-content" [ngStyle]="(background != null)?{'background-color':background}:null">
    <div class="container-fluid px-0 d-flex flex-column" style="height:100vh;justify-content: center;align-items: center;">

        <label for="color" class="mdi mdi-eyedropper d-flex align-items-center justify-content-center cursor-pointer" style="    color: #ccc;position:absolute;top:0px;right:0px;width:40px;height:40px;border-radius:20px;font-size: 22pt;"></label>
        <input id="color" type="color" hidden style="position:absolute;right:0px;top:0px;" (change)="setBackground($event)">
        <div class="best_area d-flex flex-row" style="width: 90%;">
            <div *ngIf="nextTurns.length" class="d-flex flex-column w-50 ranking" style="margin-right: 20px;">
                <div class="w-100 ranking">
                    <div class=" d-flex flex-row"  style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #fbd985 30%, #e3c97c 49%, #a37841 100%);margin: 2px 0;">
                        <div><img src="{{logoBlackPath}}" style="height: 50px;margin: 5px 10px;"></div>
                        <div class="d-flex flex-column flex-grow-1 w-100 px-3 py-1 pt-2">
                            <div style="line-height: 15px;">{{'general.next_runs' | translate}}</div>
                            <div class="d-flex flex-row flex-grow-1" style="padding-right:16px;">
                                <h3 class="mb-0 p-0" style="font-size: 16pt;text-overflow:ellipses;">{{(event != null)?event:''}}</h3>
                                <p class="mb-0 ml-auto p-0" style="font-size: 15pt;">{{(category != null)?category:''}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column" *ngFor="let turn of nextTurns">
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column" style="overflow:hidden;">
                                    <span class="horse">
                                        <img src="../../../assets/images/icons/horse-icon.png">
                                        <div>{{turn.cavalo}}</div>
                                    </span>
                                    <span class="competitor">
                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>
                                    </span>
                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 placing" style="background:transparent;-webkit-text-fill-color: white;">{{(turn != null && turn.ord != null)? turn.ord:"--"}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="nextLogos.length" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                        <div *ngFor="let patrocinador of nextLogos" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth(nextLogos.length)+'px'}">
                            <img [src]="patrocinador" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="ranking.length" class="d-flex flex-column w-50" style="margin-left: 20px;">
                <div class="w-100 ranking">
                    <div class=" d-flex flex-row"  style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #fbd985 30%, #e3c97c 49%, #a37841 100%);margin: 2px 0;">
                        <div><img src="{{logoBlackPath}}" style="height: 50px;margin: 5px 10px;"></div>
                        <div class="d-flex flex-column flex-grow-1 w-100 px-3 py-1 pt-2">
                            <div style="line-height: 15px;">{{'general.current_placing' | translate}}</div>
                            <div class="d-flex flex-row flex-grow-1" style="padding-right:16px;">
                                <h3 class="mb-0 p-0" style="font-size: 16pt;text-overflow:ellipses;">{{(event != null)?event:''}}</h3>
                                <p class="mb-0 ml-auto p-0" style="font-size: 15pt;">{{(category != null)?category:''}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column" *ngFor="let turn of ranking">
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column" style="overflow:hidden;">
                                    <span class="horse">
                                        <img src="../../../assets/images/icons/horse-icon.png">
                                        <div>{{turn.cavalo}}</div>
                                    </span>
                                    <span class="competitor">
                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>
                                    </span>
                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1" style="font-size: 0.8em;"></i>{{toFixed(turn.total,3)}}</span>
                                    <span class="px-2 placing">{{(turn != null && turn.pos_txt != null)? turn.pos_txt:"--"}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rankingLogos.length" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                        <div *ngFor="let patrocinador of rankingLogos" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth(rankingLogos.length)+'px'}">
                            <img [src]="patrocinador" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
