import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class AuthService {
  constructor(public localStorage:LocalStorageService) {}

  public isAuthenticated(): boolean {
    let user = this.localStorage.retrieve('user');
    let tokenIsExpired = false;
    if(user != null && user.token != null)
      tokenIsExpired = user.token != null && user.token.trim() != "";

    return tokenIsExpired;
  }

  public isAdmin(): boolean {
    let user = this.localStorage.retrieve('user');
    return (this.isAuthenticated() && user.admin);
  }
}
