export const environment = {
  production: false,
  MetaNameDescription: 'O Split Timing consiste na medição dos tempos em 7 parciais separadas, sendo retas e viradores. O competidor saberá seu tempo em cada reta do percurso e em cada virador, terá várias opções de avaliação por setores e poderá compará-lo aos demais animais.',
  MetaNameOGDescription: 'O Split Timing consiste na medição dos tempos em 7 parciais separadas, sendo retas e viradores.',
  apiFlespi:'https://flespi.io/storage/containers/',
  flespiToken:'SEsJ98MftFqrkMVhQXbu2wP5iCsdbaYRCeqCc3FwwVLExwJchLw7792fBjgBj5iM',
  urlFront : "test.equsperformance.com.br",
  urlLambda : "https://jvalz6oba5.execute-api.sa-east-1.amazonaws.com/Prod/equs",
  language : 'pt-br',
  format_date :  "DD/MM/YYYY",
  logoHeaderFile : "split-timing-fundotransparente-branco.png",
  logoHomeHorseComp:"compbr.png",
  logoBlack:"split-timing-fundotransparente2.png",
  logoTransmissao:"pocoto.jpg",
  whatIsVideoUrl : "https://www.youtube.com/embed/CPhPAHMs1HQ",
  howToUseVideoUrl : "https://www.youtube.com/embed/N-rSB5Stryc",
  months: "Jan,Fev,Mar,Abr,Mai,Jun,Jul,Ago,Set,Out,Nov,Dez",
  fullMonths:"Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro",
  by_first_analysis_value:4.99,
  by_second_analysis_value:8.99,
  by_third_analysis_value:11.99,
  link_payment_1:'http://linkPay1.com',
  link_payment_2:'http://linkPay2.com',
  link_payment_3:'http://linkPay3.com',
  link_stripe_special_report_by_event: 'https://buy.stripe.com/test_3cs3czbg86JH3Ju14e',
  link_stripe_special_report_by_class: 'https://buy.stripe.com/test_3csaF1gAs4Bz5RCaEN',
  value_stripe_special_report_by_event: 119.99,
  value_stripe_special_report_by_class: 129.99,
  link_stripe_full_report_by_event: 'https://buy.stripe.com/test_28o00n3NG7NLa7SeV5',
  link_stripe_full_report_by_class: 'https://buy.stripe.com/test_14kbJ51Fyec9eo87sE',
  value_stripe_full_report_by_event: 179.99,
  value_stripe_full_report_by_class: 169.99,



};
