import {Injectable, OnDestroy} from '@angular/core';
import {MqttController} from '../controllers/mqtt-controller';

@Injectable({
    providedIn: 'root'
})
export class BrokerService implements OnDestroy {

    MQTTController : MqttController;

    baseTopic: string = '';

    messageFunctionMap = {};
    connectionFunctionMap = {};

    constructor() {
        this.MQTTController = new MqttController(true,true);
        this.init();
    }

    startForComponent(topic: string, func) {
        this.connectionFunctionMap[topic] = func;
        if(this.MQTTController.isConnected()){
            this.connectionFunctionMap[topic](true);
        }
    }

    destroyForComponent(topic:string) {
        this.connectionFunctionMap[topic] = null;
        this.unsubscribe(this.baseTopic+topic);
    }

    init() {
        this.checkAndInitConnection();
    }

    onMessage = (message: any, topic: string) => {
        let func = this.handleTopicAndFindFunction(topic);
        if(func) func(message);
    };

    async initConnection() {
        this.MQTTController.refresh();
        this.MQTTController.onConnected = this.onConnected;
        this.MQTTController.onMessageArrived = this.onMessage;
        this.MQTTController.connectToFlespi();
    }

    subscribe(topic: string, qos: number, func){
        if(this.MQTTController.isConnected()){
            this.MQTTController.subscribeTo(this.baseTopic+topic, qos);
            this.messageFunctionMap[topic] = func;
        }
        else
            console.log("UNABLE TO SUBSCRIBE, MQTT IS NOT CONNECTED");
    }

    private unsubscribe(topic:string) {
        if(this.MQTTController.isConnected()){
            this.MQTTController.unsubscribeTo(topic);
            this.messageFunctionMap[topic] = null;
        }
    }

    onConnected = () => {
        this.alertObservers(true);
    };

    alertObservers(param){
        for (let componentId in this.connectionFunctionMap) {
            let func = this.connectionFunctionMap[componentId];
            if(func != null) func(param);
        }
    }

    checkAndInitConnection(){
        if(!this.MQTTController.isConnecting())
            this.initConnection().then(()=>{});
    }

    closeConnection() {
        if(this.MQTTController != null && this.MQTTController.isConnected()){
            for (let topic in this.connectionFunctionMap) {
                this.unsubscribe(topic);
            }
            this.MQTTController.disconnect();
        }
    }

    ngOnDestroy() {
        this.closeConnection();
    }


    // a hora que chega uma mensagem temos que quebrar o tópico dela, e achar dentre os tópicos assinados, qual é o mais proximo do tópico da mensagem que chegou
    handleTopicAndFindFunction(messageTopic: string): any {
        messageTopic = messageTopic.replace(this.baseTopic, '');      // remove o base topic do topico
        //let messageTopicParts = messageTopic.split('/');
        //messageTopicParts.shift();
        //console.log('part',messageTopic,messageTopicParts);                                             // tem que remover o primeiro pois é uma string vazia (pois foi separado pelo "/"
        let messageTopicParts = [messageTopic];
        let messageTopicSize = messageTopicParts.length;
        for (let i = messageTopicSize; i > 0; i--) {

            for (let [topic, func] of Object.entries(this.messageFunctionMap)) {

                topic = this.formatTopicIfNeeded(topic);                           // remove o "+" se tiver

                if (topic == messageTopic)
                    return func;
            }

            messageTopicParts.pop();
            messageTopic = '/' + messageTopicParts.join('/');                      // tira a última parte pra comparar de novo com os tópicos
        }

        return null;
    }

    // removo o "+" da frente do tópico caso estiver. Vamos considerar que só teremos o "+" no ultimo filtro do path. Exemplo:  "A/D/C/+"
    formatTopicIfNeeded(topic: string) {
        let topicParts = topic.split('/');
        let lastPart = topicParts[topicParts.length - 1];
        if (lastPart == "+")
            topic = topic.substring(0, topic.length - 2);       // remove o "/+" do final
        return topic;
    }

    sendMessage(message) {
        this.MQTTController.sendMessage(message);
    }
}
