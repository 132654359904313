import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { AwsService } from 'src/app/services/aws.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-analysis-preconfig',
  templateUrl: './analysis-preconfig.component.html',
  styleUrls: ['./analysis-preconfig.component.scss']
})
export class AnalysisPreconfigComponent implements OnInit, AfterViewInit {

  loading:boolean = false;

  filterForm:FormGroup = null;

  categories:string[] = [];
  searchText:string = "";
  filterText:string = "";

  eventOptions:string[] = [];
  categoriaOptions:string[] = [];
  cavaloOptions:string[] = [];
  competidorOptions:string[] = [];

  private eventoSubject: Subject<string> = new Subject();
  private categoriaSubject: Subject<string> = new Subject();
  private cavaloSubject: Subject<string> = new Subject();
  private competidorSubject: Subject<string> = new Subject();

  constructor(
    private router:Router,
    private toastr:ToastrService,
    private awsService:AwsService,
    private formBuilder:FormBuilder,
    private translate: TranslateService,
  ) {
    this.filterForm = this.formBuilder.group({
      cavalo:[null],
      competidor:[null],
      categoria:[null],
      evento:[null]
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.eventoSubject.pipe(
        debounceTime(800)
    ).subscribe(searchText => {
        this.callAutocomplete(searchText,"evento");
    });

    this.categoriaSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
        this.callAutocomplete(searchText,"categoria");
    });

    this.cavaloSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
        this.callAutocomplete(searchText,"cavalo");
    });

    this.competidorSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
      this.callAutocomplete(searchText,"competidor");
    });
  }


  onEventSearch(event){
    this.eventoSubject.next(event.target.value);
  }

  onCategoriaSearch(event){
    this.categoriaSubject.next(event.target.value);
  }

  onCavaloSearch(event){
    this.cavaloSubject.next(event.target.value);
  }

  onCompetidorSearch(event){
    this.competidorSubject.next(event.target.value);
  }

  callAutocomplete(search, type){
    if(search.length > 0){
      this.awsService.autocomplete(search,type).then(result => {
        let data = result.data;
        switch(type){
          case 'evento':
            this.eventOptions = data;
            break;
          case 'categoria':
            this.categoriaOptions = data;
            break;
          case 'cavalo':
            this.cavaloOptions = data;
            break;
          case 'competidor':
            this.competidorOptions = data;
            break;
        }
      });
    }
  }

  set_search(){
    let params:any = this.filterForm.value;
    let hasParams:boolean = false;
    Object.keys(params).forEach(key => {
      if(params[key] != null && params[key].trim() != ""){
        hasParams = true;
      }
    });

    if(hasParams){
      this.router.navigate(["/analysis"],{queryParams:params});
    }else{
      this.toastr.warning(
        this.translate.instant('analysis_preconfig.warning_message_fill_one_field_message'),
        this.translate.instant('analysis_preconfig.warning_message_fill_one_field_title'));
    }
  }

  clear_search(){
    this.filterForm.reset();
  }

}
