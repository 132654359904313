<div id="page-content">
    <div class="container-fluid flex-grow-1 scrollbar" style="overflow: auto;">
        <div class="row w-100 mx-0">
            <div class="col-12 col-md-6 col-lg-6 col-xl-4 mx-auto my-5">

                <div class="box">
                    <h3 class="heading">{{'login.login_label' | translate}}</h3>
                    <p class="mb-2 pb-3" style="border-bottom: 1px solid #ffffff22;">{{'login.login_welcome' | translate}}:</p>

                    <form [formGroup]="form" (submit)="login()">
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'login.email_label' | translate}}</label>
                                <input formControlName="email" type="email" class="form-control" id="email">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group mb-1">
                                <label for="password" class="col-form-label">{{'login.password_label' | translate}}</label>
                                <input formControlName="password" type="password" class="form-control" id="password">
                                <span class="d-flex mt-3 cursor-pointer" (click)="resetPassword()">{{'login.forgot_you_password' | translate}}? <span class="ml-2" style="color:#e2c87d;">{{'login.click_here' | translate}}</span></span>
                            </div>
                        </div>
                        <div class="d-flex flex-row flex-wrap pt-3" style="margin-left: -15px;margin-right: -15px;">
                            <div class="col-12 mx-auto pt-3">
                                <button type="submit" [attr.disabled]="(form.invalid)?true:null" class="w-100 py-2 px-4">{{'login.login_label' | translate}}</button>
                            </div>
                            <div class="col-12 py-3">
                                {{'login.dont_have_account' | translate}}? <span class="cursor-pointer" style="color:#e2c87d;" routerLink="/register">{{'login.create_account' | translate}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
<!--            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto d-flex flex-column align-items-center my-5">-->
<!--                <h3 class="heading text-white">Learn how to create your account</h3>-->
<!--                <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px" src="https://www.youtube.com/embed/EXzsEknhAy0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

<!--            </div>-->
        </div>
    </div>
</div>

