<div id="page-content">
    <div class="container-fluid" style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));">
        <div class="row pt-4">
            <div class="col-12 col-md-8">
                <span class="pl-3"
                      style="color: #fff;font-size: 1.5em; text-transform: uppercase; font-weight: 600;">{{eventName}}</span>
            </div>
        </div>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" style="height: 3px; margin-top: 1px"></mat-progress-bar>
    <div *ngIf="!loading" class="container-fluid pt-1 mb-5" class="scroll-area scrollbar">
        <div class="event_card mt-3 h-100 d-flex flex-column live">
            <div style="">
                <span  *ngIf="first" style="font-weight:bold;font-size:1.1em;">{{first.categoria}}</span><br>
            </div>
        </div>

        <div class="video-title"  style="padding-left: 15px; color: #fff; margin-top: 15px; margin-bottom:5px; font-size: 22px;">
            {{'general.label_your_analysis' | translate}}
        </div>
        <div *ngFor="let turn of filteredTurns; let i = index" class="places mb-2" >
            <div class="mb-2 d-flex flex-row align-content-center justify-content-between">
                <span class="placing">{{getPlacing(turn)}}</span>
                <span class="placing ml-auto mr-1">{{getTotalTime(turn)}}</span>
            </div>
            <div class="row px-3">
                <img class="horse" src="../../../assets/images/icons/horse-icon.png"  style="width: 14px;height: 16px;">
                <span>{{turn.cavalo}}</span>

            </div>
            <div class="row  px-3">
                <img class="competitor" src="../../../assets/images/icons/cowboy-icon.png"  style="width: 14px;height: 16px;">
                <span>{{turn.competidor}}</span>
            </div>
            <div class="d-flex flex-row align-content-center justify-content-end">
                    <button (click)="buyAnalisys(turn)"
                            class="bt-buy btn btn-default my-1" matTooltipPosition="above" style="font-size: 1rem">
                        <i class="mdi mdi-currency-usd"></i>
                        {{'general.label_buy_analysis' | translate}}
                    </button>
            </div>
        </div>
        <div class="row mx-0">
            <div class="video-div my-3 col-12 col-lg-5 " >
                <h4 class="my-3  video-title">{{'general.label_how_to_purchase' | translate}}:</h4>
                <iframe width="304" height="540" src="https://www.youtube.com/embed/3JzzFicv1eU" title="How to purchase" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-12 col-lg-2 ">

            </div>
            <div class="video-div col-12 col-lg-5 " >
                <h4  class="my-3 video-title">{{'general.label_what_you_get' | translate}}!</h4>
                <iframe src="https://player.vimeo.com/video/869351414?h=5fc18d897a&title=0&byline=0&portrait=0" width="304" height="540" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

    </div>


</div>