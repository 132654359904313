import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CompareDialogComponent } from '../compare/compare-dialog/compare-dialog.component';
import { TurnModel } from 'src/app/models/turn.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AwsService } from 'src/app/services/aws.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit, AfterViewInit {

  turnOrder: number = null;
  turnPas: number = null;
  eventName = '';
  categoryName = '';

  display = 'parciais';

  loading = false;

  filterOpened = false;
  filterForm: FormGroup = null;


  min: TurnModel = null;

  categories: string[] = [];
  selfTurn: TurnModel = null;
  filteredTurns: TurnModel[] = [];
  turns: TurnModel[] = [];
  searchText = '';
  customSearch = '';
  filterText = '';

  eventOptions: string[] = [];
  categoriaOptions: string[] = [];
  cavaloOptions: string[] = [];
  competidorOptions: string[] = [];

  sortFieldActivated: {name: string, value: number} = {
    name: 'total',
    value: 1
  };

  private eventoSubject: Subject<string> = new Subject();
  private categoriaSubject: Subject<string> = new Subject();
  private cavaloSubject: Subject<string> = new Subject();
  private competidorSubject: Subject<string> = new Subject();

  constructor(
    private awsService: AwsService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {

    this.translate.get('analysis.custom_search').subscribe((translated: string) => {

      this.customSearch = translated
      this.searchText = this.customSearch;
    });


    this.eventName = this.route.snapshot.paramMap.get('event');
    this.categoryName = this.route.snapshot.paramMap.get('category');
    this.turnOrder = parseInt(this.route.snapshot.paramMap.get('order'));
    this.turnPas = parseInt(this.route.snapshot.paramMap.get('pas'));
    this.filterForm = this.formBuilder.group({
      cavalo: [null],
      competidor: [null],
      categoria: [null],
      evento: [null]
    });

  }
  ngAfterViewInit(): void {
    const box = document.querySelector('#box');
    window.addEventListener('click', (e: any) => {
      if (this.filterOpened) {
        if (e.path != null && !e.path.includes(box) && !e.path.some(p => p.classList && p.classList.contains('mat-option')) && !e.path.some(p => p.classList != null && p.classList.contains('filter_button'))) {
          this.filterOpened = false;
        }
      }
    });

    this.eventoSubject.pipe(
        debounceTime(800)
    ).subscribe(searchText => {
        this.autocompletePassadasFilters(searchText, 'evento');
    });

    this.categoriaSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
        this.autocompletePassadasFilters(searchText, 'categoria');
    });

    this.cavaloSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
        this.autocompletePassadasFilters(searchText, 'cavalo');
    });

    this.competidorSubject.pipe(
      debounceTime(800)
    ).subscribe(searchText => {
      this.autocompletePassadasFilters(searchText, 'competidor');
    });

    const evento = this.route.snapshot.queryParamMap.get('evento');
    const competidor = this.route.snapshot.queryParamMap.get('competidor');
    const categoria = this.route.snapshot.queryParamMap.get('categoria');
    const cavalo = this.route.snapshot.queryParamMap.get('cavalo');

    if (
      (evento != null && evento.trim() != '') ||
      (competidor != null && competidor.trim() != '') ||
      (categoria != null && categoria.trim() != '') ||
      (cavalo != null && cavalo.trim() != '')
    ) {
      this.filterForm.patchValue({
        cavalo,
        competidor,
        categoria,
        evento
      });
      this.set_search();
    }
  }

  ngOnInit() {
    }

  private definePlacementTurns(turns: TurnModel[]): TurnModel[] {
    let result = this.sortTurns(turns, this.sortFieldActivated.name, this.sortFieldActivated.value);
    result = result.filter( (turn: TurnModel, index) => {
      turn = this.refineTurn(turn);

      if (turn.total != null && isNaN(turn.total) == false && turn.sat == true || turn.sat == false && turn.nc != true) {
        if (turn.placing == null) {
          turn.placing = index + 1;
        }
        return true;
      } else {
        return false;
      }
    });

    return result;
  }

  refineTurn(turn) {
    turn.v1 = (turn.v1 != null) ? parseFloat(turn.v1.toFixed(3)) : null;
    turn.v2 = (turn.v2 != null) ? parseFloat(turn.v2.toFixed(3)) : null;
    turn.v3 = (turn.v3 != null) ? parseFloat(turn.v3.toFixed(3)) : null;
    turn.r1 = (turn.r1 != null) ? parseFloat(turn.r1.toFixed(3)) : null;
    turn.r2 = (turn.r2 != null) ? parseFloat(turn.r2.toFixed(3)) : null;
    turn.r3 = (turn.r3 != null) ? parseFloat(turn.r3.toFixed(3)) : null;
    turn.rf = (turn.rf != null) ? parseFloat(turn.rf.toFixed(3)) : null;
    turn.tr = (turn.tr != null) ? parseFloat(turn.tr.toFixed(3)) : null;
    turn.tv = (turn.tv != null) ? parseFloat(turn.tv.toFixed(3)) : null;
    turn.rv1 = (turn.rv1 != null) ? parseFloat(turn.rv1.toFixed(3)) : null;
    turn.rv2 = (turn.rv2 != null) ? parseFloat(turn.rv2.toFixed(3)) : null;
    turn.rv3 = (turn.rv3 != null) ? parseFloat(turn.rv3.toFixed(3)) : null;
    turn.rvr1 = (turn.rvr1 != null) ? parseFloat(turn.rvr1.toFixed(3)) : null;
    turn.rvr2 = (turn.rvr2 != null) ? parseFloat(turn.rvr2.toFixed(3)) : null;
    turn.rvr3 = (turn.rvr3 != null) ? parseFloat(turn.rvr3.toFixed(3)) : null;

    turn.rvrt = (turn.rvr1 != null && turn.rvr2 != null && turn.rvr3 != null) ? (turn.rvr1 + turn.rvr2 + turn.rvr3) : null;

    if (turn.total != null && !isNaN(turn.total)) {
      turn.total = (turn.total != null) ? parseFloat(turn.total.toFixed(3)) : null;
    }

    turn.sat = turn.sat != true ? false : true;
    turn.nc = turn.nc != true ? false : true;
    return turn;
  }

  public sortTurns(turns: TurnModel[], field= 'total', order= 1): TurnModel[] {
    let result = turns;
    if (turns.length > 0) {
      let noTotalTurns = [];
      if (field == 'total' || field == 'pos') {
        noTotalTurns = result.filter(turn => isNaN(turn[field]) || !turn[field]);
        result = result.filter(turn => !isNaN(turn[field]) && turn[field]);
      }

      result = result.sort((turnA: TurnModel, turnB: TurnModel) => {

        if ( turnA[field] < turnB[field]) {
          return (order >= 0  && (turnA[field]>0)  && (turnA.sat == false)  && (turnA.nc == false)) ? -1 : 1;
        } else if ( turnA[field] > turnB[field] ) {
          return (order >= 0 && (turnA[field]>0)  || (turnA.sat == true) || (turnA.nc == true)) ? 1 : -1;
        }
        return 0;
      });
      result = result.concat(noTotalTurns);
    }
    return result;
  }

  public toFixed(value: number, places: number): string {
    if (!isNaN(value)) {
      return parseFloat(value.toString()).toFixed(places);
    }
    return '--';
  }

  public getDif(field, turnA, turnB) {
    let label = '--';
    if (turnA != null && turnB != null) {
      if (turnA[field] != null && turnB[field] != null) {
        const dif = turnA[field] - turnB[field];
        label = (dif > 0 ? '+' : '') + (dif).toFixed(3);
      }
    }
    return label;
  }

  public openCompareDialog(turn: TurnModel, best= false): void {
    let self = this.selfTurn;

    if (!this.selfTurn) {
      self = this.min;
    }


    if (self == this.min || turn == this.min) {
      best = true;
    }

    if (turn == this.min) {
      const a = self;
      self = turn;
      turn = a;
    }


    if (self != null && turn != self) {
      const categoria = this.route.snapshot.queryParamMap.get('categoria');
      const evento = this.route.snapshot.queryParamMap.get('evento');
      this.dialog.open(CompareDialogComponent, {
        data: {
          selfTurn: turn,
          turn: self,
          best,
          categoria,
          evento,
        },
        maxHeight: 'calc(100vh - 30px)',
        maxWidth: 'calc(100vw - 30px)',
        panelClass: 'dialog'
      });
    }
  }
  public resetSort() {
    this.sortFieldActivated.name = 'total';
    this.sortFieldActivated.value = 1;

    this.filteredTurns = this.sortTurns(this.filteredTurns, this.sortFieldActivated.name, this.sortFieldActivated.value);
  }
  public sortBy(event, field) {
    if (this.filteredTurns.length) {
      const target = event.target;
      let activeArrow = target;
      if (!target.classList.contains('mdi-arrow-down')) {
        activeArrow = target.querySelector('.mdi-arrow-down');
      }
      document.querySelectorAll('.turn_totals_fields .mdi-arrow-down').forEach(arrow => {
        if (arrow != activeArrow) { arrow.classList.add('mdi-rotate-180'); }
        arrow.classList.remove('active');
      });
      activeArrow.classList.add('active');

      let order = 1;
      if (activeArrow.classList.contains('mdi-rotate-180')) {
        activeArrow.classList.remove('mdi-rotate-180');
      } else {
        activeArrow.classList.add('mdi-rotate-180');
        order = -1;
      }

      this.sortFieldActivated.name = field;
      this.sortFieldActivated.value = order;

      this.filteredTurns = this.sortTurns(this.filteredTurns, field, order);
    }
  }

  onEventSearch(event) {
    this.eventoSubject.next(event.target.value);
  }

  onCategoriaSearch(event) {
    this.categoriaSubject.next(event.target.value);
  }

  onCavaloSearch(event) {
    this.cavaloSubject.next(event.target.value);
  }

  onCompetidorSearch(event) {
    this.competidorSubject.next(event.target.value);
  }

  callAutocomplete(search, type) {
    if (search.length > 0) {
      this.awsService.autocomplete(search, type).then(result => {
        const data = result.data;
        switch (type) {
          case 'evento':
            this.eventOptions = data;
            break;
          case 'categoria':
            this.categoriaOptions = data;
            break;
          case 'cavalo':
            this.cavaloOptions = data;
            break;
          case 'competidor':
            this.competidorOptions = data;
            break;
        }
      });
    }
  }

 autocompletePassadasFilters(search, type) {
      const params = {
          type,
          search
      };
      const evento = this.filterForm.value.evento;
      const categoria = this.filterForm.value.categoria;
      const cavalo = this.filterForm.value.cavalo;
      const competidor = this.filterForm.value.competidor;

      if (type !== 'evento' && evento !== null && evento !== '') {
         params['evento'] = evento;
      }
      if (type !== 'categoria' && categoria !== null && categoria !== '') {
         params['categoria'] = categoria;
      }
      if (type !== 'cavalo' && cavalo !== null && cavalo !== '') {
         params['cavalo'] = cavalo;
      }
      if (type !== 'competidor' && competidor !== null && competidor !== '') {
         params['competidor'] = competidor;
      }

      if (search.length > 0) {
          this.awsService.autocompletePassadasFilters(params).then(result => {
              const data = result.data.map((item) => item.res);
              switch (type) {
                  case 'evento':
                      this.eventOptions = data;
                      break;
                  case 'categoria':
                      this.categoriaOptions = data;
                      break;
                  case 'cavalo':
                      this.cavaloOptions = data;
                      break;
                  case 'competidor':
                      this.competidorOptions = data;
                      break;
              }
          });
      }
  }

  preventPushUpBug() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  clear_search() {
    this.filterForm.reset();
    const search_input: HTMLInputElement = document.querySelector('#search');
    search_input.readOnly = false;
    this.searchText = '';
    this.search();
    this.router.navigate([], { relativeTo: this.route, queryParams: { evento: null, categoria: null, cavalo: null, competidor: null } });
  }

  click_search(event) {
    if (event.target.readOnly) {
      event.stopPropagation();
      this.filterOpened = true;
    }
  }

  attachTurn(turn: TurnModel) {
    this.filteredTurns = this.definePlacementTurns(this.turns);
    if (turn != this.min) {
      const index = this.filteredTurns.indexOf(turn);
      if (index >= 0) {
        this.selfTurn = turn;
        this.filteredTurns.splice(index, 1);
      }
    } else {
      this.selfTurn = turn;
    }
  }
  removeAttachedTurn() {
    this.filteredTurns = this.definePlacementTurns(this.turns);
    this.localStorageService.clear('atachedTurn');
    this.selfTurn = null;
  }

  public set_search() {
    const params: any = this.filterForm.value;
    let hasParams = false;
    Object.keys(params).forEach(key => {
      if (params[key] != null && params[key].trim() != '') {
        hasParams = true;
      }
    });

    const search_input: HTMLInputElement = document.querySelector('#search');
    if (hasParams) {
      search_input.readOnly = true;
      if (this.searchText.trim() != this.customSearch) {
        this.searchText = this.customSearch;
      }
    } else {
      search_input.readOnly = false;
      this.searchText = '';
    }
    this.filterOpened = false;
    this.search();
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  search() {
    let params: any = {...this.filterForm.value};

    let hasParams = false;

    Object.keys(params).forEach(key => {
      if (params[key] != null && params[key].trim() != '') {
        params[key] = params[key].toUpperCase();
        hasParams = true;
      } else {
        delete params[key];
      }
    });

    let searchText = this.searchText;

    if ((searchText.trim() != '' && searchText.trim() != this.customSearch) || hasParams) {
      if (!hasParams) {
        params = { search: '*' + searchText.toUpperCase() + '*' };
      }
      searchText = '';
      this.loading = true;
      params.best = true;
      this.awsService.getTurns(params).then((response: {passadas: TurnModel[], best: TurnModel}) => {
        this.turns = response.passadas;
        this.filteredTurns = this.definePlacementTurns(this.turns);

        const selfTurn = this.localStorageService.retrieve('atachedTurn');
        if (this.selfTurn == null && selfTurn != null) {
          const r = this.filteredTurns.find(turn => turn.evento == selfTurn.evento && turn.categoria == selfTurn.categoria && turn.cavalo == selfTurn.cavalo && turn.competidor == selfTurn.competidor);
          if (r != null) {
            this.attachTurn(r);
          }
        }
        if (response.best != null) {
          this.min = Object.assign(new TurnModel(), response.best);
          this.min.bestTotal = this.min.total;
          this.min = this.refineTurn(this.min);
        } else {
          this.min = null;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    } else {
      this.filteredTurns = [];


      this.turns = [];
      this.min = null;
    }
  }

  onFocus(type: string) {
      switch (type) {
          case 'evento':
              this.eventOptions = [];
              break;
          case 'categoria':
              this.categoriaOptions = [];
              break;
          case 'cavalo':
              this.cavaloOptions = [];
              break;
          case 'competidor':
              this.competidorOptions = [];
              break;
      }
  }
}

