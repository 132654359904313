import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TurnModel } from 'src/app/models/turn.model';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-analysis-card',
  templateUrl: './analysis-card.component.html',
  styleUrls: ['./analysis-card.component.scss']
})
export class AnalysisCardComponent implements OnInit {

  @Input('turn') turn:TurnModel = new TurnModel();
  @Input('min') min:TurnModel = new TurnModel();
  @Input('best') best:boolean = false;
  @Input('display') display:string = null;
  @Input('atached') atached:boolean = false;
  @Output('attach') attach = new EventEmitter<void>();
  @Output('removeAttach') removeAttach = new  EventEmitter<void>();

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  public getDif(field, turnA, turnB){
    let label = '--';
    if(turnA && turnA[field] != null && turnB && turnB[field] != null){
      if(turnA != null && turnB != null){
        if(turnA[field]!=null && turnB[field] != null){
          let dif = turnB[field]-turnA[field];
          label = (dif > 0?'+':'') + (dif).toFixed(3);
        }
      }
    }
    return label;
  }

  public setAttached(event){
    event.stopPropagation();
    this.attach.emit();
  }

  public removeAttached(event){
    event.stopPropagation();
    this.removeAttach.emit();
  }

  public getTotal(turn){
    let total = '--';
    if(this.best){
      switch(this.display){
        case "parciais":
          total = (turn && turn.tot_parciais)?this.toFixed(turn.tot_parciais,3):'--';
          break;
        case "rvr":
          total = (turn && turn.tot_rvr)?this.toFixed(turn.tot_rvr,3):'--';
          break;
        case "rv":
          total = (turn && turn.tot_rv)?this.toFixed(turn.tot_rv,3):'--';
          break;
        case "trtv":
          total = (turn && turn.tot_trtv)?this.toFixed(turn.tot_trtv,3):'--';
          break
      }
    }else{
      return (turn && turn.total)?this.toFixed(turn.total,3):'--';
    }
    return total;
  }

}
