import {Component} from '@angular/core';
import {CategoryModel} from "../../../models/category.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AwsService} from "../../../services/aws.service";
import {LocalStorageService} from "ngx-webstorage";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-redirect-to-by',
	templateUrl: './redirect-to-by.component.html',
	styleUrls: ['./redirect-to-by.component.scss']
})
export class RedirectToByComponent {

	category_id:number = 0;
	seqPrincipal:number = 0;
	other:number = 0;

	constructor(
		private router: Router,
		private awsService: AwsService,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService,
		private translate: TranslateService,
	) {



		if (this.route.snapshot.paramMap.has("category")) {
			this.category_id = Number(this.route.snapshot.paramMap.get("category"));
		}

		if (this.route.snapshot.paramMap.has("seq")) {
			this.seqPrincipal = Number(this.route.snapshot.paramMap.get("seq"));
		}

		if (this.category_id && this.seqPrincipal){
			this.localStorage.store("purchasedRedirectTo", `rb/${this.category_id}/${this.seqPrincipal}`);
			this.downloadCategories();
		}
		else{
			this.router.navigateByUrl('');
		}

	}

	downloadCategories() {
		let params = {
			id_categoria: this.category_id,
			best: true,
			stat: true
		};
		this.awsService.getCategories(params).then(categories => {
			if (categories.length > 0) {
				console.log('this.categories[0]', categories[0])
				this.setCategory(categories[0])

			}


		}).catch(() => {
			this.router.navigateByUrl('', {skipLocationChange: true});
		});
	}


	setCategory(cat: CategoryModel) {
		this.localStorage.store("selectedCategory", cat.categoria);
		this.localStorage.store("selectedEvent", cat.evento);
		this.localStorage.store("eventId", cat.id_evento);
		this.localStorage.store('seqPrincipal', this.seqPrincipal);
		if (cat.monetize) {
			this.router.navigateByUrl('buy-analysis/' + cat.id_evento);
		} else {
			this.router.navigateByUrl('home/' + cat.id_evento);
		}
	}

}
