import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EventModel} from 'src/app/models/event.model';
import {TurnModel} from 'src/app/models/turn.model';
import {AwsService} from 'src/app/services/aws.service';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Ranking} from '../ranking/ranking.component';
import {CategoryModel} from 'src/app/models/category.model';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar'
import {ImageDialogComponent} from '../ranking/image-dialog/image-dialog.component';
import domtoimage from 'dom-to-image';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {CookieNoticeComponent} from "../cookie-notice/cookie-notice.component";
import {CookieService} from "ngx-cookie-service";
import {NewCommandModule} from "@angular/cli/src/commands/new/cli";
import {now} from "moment";

export interface IEvento {
	evento: string;
	id: number;
}

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
	logoHeaderFile = "assets/images/" + environment.logoHeaderFile;
	logoHomeHorseComp = "assets/images/" + environment.logoHomeHorseComp;
	whatIsVideoUrl: SafeResourceUrl = '';
	howToUseVideoUrl: SafeResourceUrl = '';
	firstScroll = true;
	months: string[] = [];
	// tslint:disable-next-line:variable-name
	full_months: string[] = [];

	events: any[] = [];
	pastEvents: any[] = [];

	// @ts-ignore
	pastEventsMatFiltered: Observable<IEvento[]> = [];
	pastEventsMatControl = new FormControl('');


	categories: any[] = [];
	filteredEvents: IEvento[] = [];
	rankings: any[] = [];
	form: FormGroup;
	patrocinadores: string[] = [];
	selectedRanking: Ranking = new Ranking();
	selectedEvent: any = {};
	selectedCategory: any = {};
	eventName = '';

	liveEvents: any[] = [];
	loading = false;

	showNotice = false;

	brasil = environment.language == 'pt-br'

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private awsService: AwsService,
		private formBuilder: FormBuilder,
		public toastr: ToastrService,
		// tslint:disable-next-line:variable-name
		private _snackBar: MatSnackBar,
		private translate: TranslateService,
		private sanitizer: DomSanitizer,
		private cookieService: CookieService,
	) {
		this.translate.setDefaultLang('en');
		this.translate.use(environment.language);

		this.whatIsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.whatIsVideoUrl);
		this.howToUseVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.howToUseVideoUrl);

		this.months = environment.months.split(',');
		this.full_months = environment.fullMonths.split(',');


		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', Validators.required],
			phone: ['', Validators.required],
			message: ['', Validators.required]
		});
		window.scrollTo(0, 0);
	}

	ngAfterViewInit(): void {
		const sidebar = document.querySelector('#sidebar_landing');

		window.addEventListener('click', (e: any) => {
			if (sidebar.classList.contains('open')) {
				if (!e.path.includes(sidebar) && e.path != null && !e.path.some(element => {
					return element.classList != null && element.classList.contains('toggle-sidebar');
				})) {
					e.preventDefault();
					sidebar.classList.remove('open');
					document.querySelector('html').style.overflowY = 'auto';
				}
			}
		});
	}

	ngOnInit() {
		const cookieAnswered = this.checkCookieAnswered();
		let diffInDays = 0
		if (cookieAnswered) {

			const today = new Date();

			const dateAnswered = new Date(cookieAnswered);
			// Calculando a diferença em milissegundos
			const diffEmMilissegundos = Math.abs(today.getTime() - dateAnswered.getTime());

			// Convertendo a diferença para dias
			diffInDays = Math.ceil(diffEmMilissegundos / (1000 * 60 * 60 * 24));
		}

		if (!cookieAnswered || diffInDays>30) {
			this.showNotice = true;
		}
		this.downloadEvents();
		this.pastEventsMatFiltered = this.pastEventsMatControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);
	}

	public onCookieButton(accepted: boolean) {
		this.showNotice = false;
		const cookieAccepted = accepted ? 'accept' : 'decline'
		localStorage.setItem('cookieAccepted', cookieAccepted);
		const today = new Date()
		localStorage.setItem('cookieAnswered', today.toString());
		if (!accepted){
			this.cookieService.deleteAll();
		}
	}

	preventPushUpBug() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}

	downloadRankings() {
		const category = (this.selectedCategory != null && this.selectedCategory.id != null) ? this.selectedCategory.id : null;
		const event = (this.selectedEvent != null && this.selectedEvent.id != null) ? this.selectedEvent.id : null;
		this.loading = true;
		this.awsService.getRanking(event, category, 'tr,tv,total').then((rankings: any[]) => {
			let turns: TurnModel[] = [];
			if (rankings != null) {
				for (const ranking of rankings) {
					if (ranking.ranking != null) {
						turns = ranking.ranking.map(turn => Object.assign(new TurnModel(), turn));
						ranking.data = turns;
					}
				}
			}
			this.rankings = rankings;
		}).catch(error => {
			console.log(error);
		}).finally(() => {
			console.log('loading end');
			this.loading = false;
		});
	}

	public toFixed(value: number, places: number): string {
		if (!isNaN(value)) {
			return parseFloat(value.toString()).toFixed(places);
		}
		return '--';
	}

	openLink(event, path, params) {
		event.preventDefault();
		event.stopPropagation();
		const url = this.router.serializeUrl(
			this.router.createUrlTree([path], {queryParams: params})
		);

		window.open(url, '_blank');
	}

	openLinkPatrocinador() {
		const url = 'https://kinderbarrelrace.com/';
		window.open(url, '_blank');
	}

	setRanking(rankingName) {
		this.selectedRanking = this.rankings.find(ranking => ranking.label === rankingName);
	}

	setEvent(selected) {

		const eventoName = selected.option.value;
		const selectedEvent = this.pastEvents.find(event => event.evento === eventoName);
		const id = selectedEvent && selectedEvent.id ? selectedEvent.id : null;
		if (id != null && id !== 'null') {
			this.selectedEvent = this.events.find(event => event.id === Number(id));
		} else {
			this.selectedEvent = this.events[0];
		}
		if (this.selectedEvent.id == null) {
			this.downloadRankings();
		} else {
			this.downloadCategories(this.selectedEvent.id);
		}
	}

	clearSelectedEvent() {

		this.pastEventsMatControl.setValue(null);
		this.selectedCategory = {};
		this.categories = [];
		this.selectedEvent = {id: null, evento: ''};
		this.downloadRankings();
		//this.filterEvents();
	}

	setCategory(id) {
		if (id != null && id != 'null') {
			this.selectedCategory = this.categories.find(category => category.id == Number(id));
		} else {
			this.selectedCategory = this.categories[0];
		}
		this.downloadRankings();
	}

	// tslint:disable-next-line:variable-name
	downloadCategories(id_evento: number) {
		this.loading = true;
		this.awsService.getCategories({id_evento}).then((categorias) => {
			this.categories = (categorias != null && categorias.length) ? categorias : [];
			this.categories = this.categories.sort((a, b) => {
				const keyA = a['categoria'];
				const keyB = b['categoria'];

				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
				return 0;
			});

			if (this.categories.length) {
				this.categories.unshift({id: null, categoria: 'Geral'});
				this.selectedCategory = this.categories[0];
			}
			this.downloadRankings();
		}).catch((error) => {
			console.log(error);
		}).finally(() => this.loading = false);
	}

	public filterEvents() {
		this.filteredEvents = [];
		const filteredEvents = this.events;
		const dates = {};
		for (const event of filteredEvents) {

			// tslint:disable-next-line:variable-name
			let data_ini = null;
			if (event.dtinicio != null && event.dtinicio.trim() != '') {
				data_ini = moment(event.dtinicio).toDate();
				event.tsini = data_ini.getTime();
				event.day = (data_ini.getDate() < 10) ? '0' + data_ini.getDate() : data_ini.getDate();
				event.month = this.months[data_ini.getMonth()];
				event.de = event.day + ' de ' + event.month;
			}

			let data_fim = null;
			if (event.dttermino != null && event.dttermino.trim() != '') {
				data_fim = moment(event.dttermino).toDate();
				const day = (data_fim.getDate() < 10) ? '0' + data_fim.getDate() : data_fim.getDate();
				event.ate = day + ' de ' + this.months[data_fim.getMonth()];
			}

			if (data_fim != null && data_ini != null) {
				const today = moment().toDate();

				if (data_ini.getTime() > today.getTime()) {
					const month = data_ini.getMonth();
					const year = data_ini.getFullYear();
					if (!dates.hasOwnProperty(year)) {
						dates[year] = {};
					}
					if (!dates[year].hasOwnProperty(month)) {
						dates[year][month] = [];
					}
					dates[year][month].push(event);
				}
			}
		}
		// @ts-ignore
		this.filteredEvents = Object.keys(dates).map(year => {
			return {
				year,
				months: Object.keys(dates[year]).sort(((a, b) => parseInt(a) - parseInt(b))).map(month => {
					return {
						month: this.full_months[month],
						events: dates[year][month].sort((a, b) => parseInt(a.tsini) - parseInt(b.tsini))
					};
				})
			};
		});
	}

	copyRankingLink(ranking = null) {
		const selBox = document.createElement('textarea');
		const event = (this.selectedEvent != null && this.selectedEvent.id != null) ? this.selectedEvent.id : 0;
		const category = (this.selectedCategory != null && this.selectedCategory.id != null) ? this.selectedCategory.id : 0;
		const type = (ranking != null && ranking.ranking != null && ranking.ranking.length) ? ranking.ranking[0].tipo : '';

		let val = `${window.location.origin}/ranking-share`;
		let params = '';
		if (event != 0) {
			params += `e=${event}`;
		}
		if (category != 0) {
			params += (params != '' ? '&' : '') + `&c=${category}`;
		}
		if (type.trim() != '') {
			params += (params != '' ? '&' : '') + `r=${type}`;
		}
		if (params.trim() != '') {
			val += '?' + params;
		}

		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		this.openSnackBar('Link copied!', 'success');
	}

	public openSnackBar(message: string, type: string) {
		const config: MatSnackBarConfig = {
			panelClass: 'snack_' + type,
			duration: 6000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom'
		};
		this._snackBar.open(message, 'Ok', config);
	}

	public maskPhone(val) {
		if (val != '') {
			setTimeout(() => {
				const v = this.mphone(val);
				if (v != val) {
					this.form.get('phone').setValue(v);
				}
			}, 1);
		}
	}

	public mphone(v) {
		if (this.brasil) {
			var r = v.replace(/\D/g, "");
			r = r.replace(/^0/, "");
			if (r.length > 10) {
				r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
			} else if (r.length > 6) {
				r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
			} else if (r.length > 2) {
				r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
			} else {
				r = r.replace(/^(\d*)/, "($1");
			}
			return r;
		} else {
			return v.replace(/\D/g, '')
				.replace(/^(\d)/, '($1')
				.replace(/^(\(\d{3})(\d)/, '$1) $2')
				.replace(/(\d{3})(\d{1,5})/, '$1-$2')
				.replace(/(-\d{4})\d+?$/, '$1');
		}
	}

	public sendContactMessage() {
		if (this.form.valid) {
			const values = this.form.value;
			this.form.disable();

			const submitButton = document.querySelector('#contact-button');
			const buttonContent = submitButton.innerHTML;
			submitButton.innerHTML = `<i class="mdi mdi-spin mdi-loading mr-2"></i> SENDING MESSAGE...`;

			this.awsService.contactMessage(values.name, values.email, values.phone, values.message).then((response) => {
				this.form.reset();
				this.toastr.success('We received your message. Thank you!', 'Sent Message');
			}).catch(() => {
				this.toastr.error('Unable to send your message, please try again!', 'An error has occurred');
			}).finally(() => {
				submitButton.innerHTML = buttonContent;
				this.form.enable();

			});
		}
	}

	public goToLink(event, url: string) {
		event.stopPropagation();
		window.open(url, '_blank');
	}

	toggleMenu() {
		const sidebar = document.querySelector('#sidebar_landing');
		const open = sidebar.classList.contains('open');
		if (open) {
			sidebar.classList.remove('open');
			document.querySelector('html').style.overflowY = 'auto';
		} else {
			sidebar.classList.add('open');
			document.querySelector('html').style.overflowY = 'hidden';
		}
	}

	scrollTo(elementSelector) {
		const element = document.querySelector(`#${elementSelector}`);
		if (element != null) {
			const sidebar = document.querySelector('#sidebar_landing');
			const active = sidebar.querySelector(`[link="${elementSelector}"]`);
			sidebar.querySelectorAll('li').forEach(element => {
				element.classList.remove('active');
			});
			active.classList.add('active');

			if (element != null) {
				const top = element.getBoundingClientRect().top - 80;
				this.scrollBy(top, 500);
			}
			sidebar.classList.remove('open');
			document.querySelector('html').style.overflowY = 'auto';
		}
	}

	scrollBy(distance, duration) {

		const scrollIni = Math.ceil(document.querySelector('.content').scrollTop);
		const difference = Math.ceil(distance - scrollIni);
		if (scrollIni != Math.abs(difference)) {
			const startTime = new Date().getTime();

			function step() {
				let percentageProggress = ((new Date().getTime() - startTime) * 100) / duration;
				if (percentageProggress > 100) {
					percentageProggress = 100;
				}
				const scroll = scrollIni + ((distance * percentageProggress) / 100);
				document.querySelector('.content').scrollTo(0, scroll);
				if (percentageProggress < 100) {
					window.requestAnimationFrame(step);
				}
			}

			window.requestAnimationFrame(step);
		}
	}

	public generateImage(event, rank, ranking) {
		event.target.classList.add('mdi-loading', 'mdi-spin');
		event.target.classList.remove('mdi-image');
		this.patrocinadores = (ranking.url_mkt != null && ranking.url_mkt.length>0) ? [...ranking.url_mkt] : [];

		const element = document.getElementById('img-model');
		const list = rank.cloneNode(true);
		list.classList.remove('mb-5');
		let child = list.querySelector('[button]');
		child.parentElement.removeChild(child);
		child = list.querySelector('.mdi-loading');
		child.parentElement.removeChild(child);
		child = list.querySelector('.title');
		child.parentElement.removeChild(child);

		list.classList.remove('col-lg-6');

		list.style.fontSize = '0.8em';
		list.querySelectorAll('.placing').forEach(placing => {
			placing.classList.add('placing_img');
			placing.classList.remove('placing');
		});

		element.querySelector('.cont').innerHTML = '';
		element.querySelector('.cont').append(list);
		element.querySelector('[subtitle]').innerHTML = ranking.subtitulo;

		setTimeout(() => {
			domtoimage.toPng(element).then((dataUrl) => {
				const img = document.createElement('img');
				img.src = dataUrl;
				// let jan = window.open('','_blank');
				// jan.document.body.appendChild(img);
				this.openImage(dataUrl);
				event.target.classList.remove('mdi-loading');
				event.target.classList.remove('mdi-spin');
				event.target.classList.add('mdi-image');
			});
		}, 100);
	}

	public openImage(image) {
		this.dialog.open(ImageDialogComponent, {
			width: '500px',
			height: '6250px',
			maxWidth: '100%',
			maxHeight: '100%',
			data: image,
			panelClass: 'dialog'
		});
	}

	public convertBase64ToFile(image) {
		const byteString = atob(image.split(',')[1]);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i += 1) {
			ia[i] = byteString.charCodeAt(i);
		}
		const newBlob = new Blob([ab], {
			type: 'image/png',
		});
		return newBlob;
	}

	public getPatrocinadoresHeight() {
		return 95;
		// return ((500/((this.patrocinadores.length >= 4)?this.patrocinadores.length:4))*(900/16))/100;
	}

	public getPatrocinadoresWidth() {
		return (500 / ((this.patrocinadores.length >= 4) ? this.patrocinadores.length : 4));
	}

	private checkCookieAnswered() {
		return localStorage.getItem('cookieAnswered');
	}

	private _filter(value: string): IEvento[] {
		if (!value) {
			const all = this.pastEvents.filter(event => event.evento !== '');
			return all;
		}
		const filterValue = value.toLowerCase();
		let result = []
		if (filterValue !== '') {
			result = this.pastEvents.filter(option => option.evento.toLowerCase().includes(filterValue));
		} else {
			result = this.pastEvents.filter(event => event.evento !== '');
		}

		result = result.sort((a, b) => {
			if (a['dtinicio'] < b['dtinicio']) {
				return 1;
			} else if (a['dtinicio'] > b['dtinicio']) {
				return -1;
			}
			return 0;
		});
		return result;
	}

	private isPastEvent(event: any): boolean {
		let dataIni = null;
		if (event.dtinicio != null && event.dtinicio.trim() != "") {
			dataIni = moment(event.dtinicio).toDate();
			dataIni.setHours(0, 0, 0, 1);
		}

		let dataFim = null;
		if (event.dttermino != null && event.dttermino.trim() != "") {
			dataFim = moment(event.dttermino).toDate();
			dataFim.setHours(0, 0, 0, 1);
		}

		if (dataFim != null && dataIni != null) {
			const now = moment().toDate();
			now.setHours(0, 0, 0, 1);

			const entre = (dataFim.getTime() >= now.getTime() && now.getTime() >= dataIni.getTime());
			const apos = (dataFim.getTime() <= now.getTime());
			const result = ((entre || apos) && !!event.publico);
			return result;
		} else {
			return false;
		}
	}

	private downloadEvents() {

		this.pastEvents = [];
		const today=now();

		this.loading = true;

		// retirei o only_open
		this.awsService.getEvents({ order: 'dtinicio'}).then((events: any[]) => {
			// events = events.filter(event => event.publico == 1) agora a api que filtra isso

			events.sort((a, b) => {
				if (moment(a.dtinicio).toDate() > moment(b.dtinicio).toDate())
					return -1
				else if ((moment(a.dtinicio).toDate() == moment(b.dtinicio).toDate())
					&& a.evento > b.evento)
					return -1
				else
					return 1
			});
			for (const event of events) {


				if (this.isPastEvent(event)) {
					this.pastEvents.push(event);
				}

				const today = moment().format('YYYY-MM-DD')

				if (event.live == 1 || event.dttermino==today) {
					// tslint:disable-next-line:triple-equals
					if (event.dtinicio != null && event.dtinicio.trim() != '') {
						// tslint:disable-next-line:variable-name
						const data_ini = moment(event.dtinicio).toDate();
						event.tsini = data_ini.getTime();
						event.day = (data_ini.getDate() < 10) ? '0' + data_ini.getDate() : data_ini.getDate();
						event.month = this.months[data_ini.getMonth()];
						event.de = event.day + ' de ' + event.month;
					}
					// tslint:disable-next-line:triple-equals
					if (event.dttermino != null && event.dttermino.trim() != '') {
						// tslint:disable-next-line:variable-name
						const data_fim = moment(event.dttermino).toDate();
						const day = (data_fim.getDate() < 10) ? '0' + data_fim.getDate() : data_fim.getDate();
						event.ate = day + ' de ' + this.months[data_fim.getMonth()];
					}
					if (event.logos && event.logos.trim() !== '') {
						const logosList = event.logos.split(',');
						event.logosList = logosList;
					}
					this.liveEvents.push(event);
				} else {
					this.events.unshift(event);
				}
			}


			this.events.unshift({id: null, evento: ''}); // Estava "All"
			this.pastEvents.unshift({id: null, evento: ''}); // Estava "All"

			this.selectedEvent = this.pastEvents[0];

			this.categories = [];
			this.selectedCategory = {id: null, categoria: 'Geral'};
			this.downloadRankings();

			this.filterEvents();
		}).catch(error => {
			console.log(error);
		}).finally(() => this.loading = false);


	}

	goToEvent(eventId:number, monetized:boolean){
		if (monetized){
			this.router.navigateByUrl('buy/' + eventId)

		}else {
			this.router.navigateByUrl('categories/' + eventId);
		}
	}

}
