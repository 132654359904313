import {Component, OnInit} from '@angular/core';
import {CategoryModel} from '../../models/category.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {AwsService} from 'src/app/services/aws.service';
// @ts-ignore
import moment from 'moment';
import {AuthService} from 'src/app/services/auth.service';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import * as url from "url";

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

	public categories: CategoryModel[] = [];
	public eventId: number = null;
	public eventName: string = "";
	public filteredCategories: any[] = [];
	public live: CategoryModel = null;
	public dateCategoryList: { date: string, categories: CategoryModel[] } [] = [];

	public monetize: boolean = false;

	public brasil = environment.language == 'pt-br';

	constructor(
		private router: Router,
		private awsService: AwsService,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService,
		private translate: TranslateService,
	) {
		if (this.route.snapshot.paramMap.has("event")) {
			this.eventId = Number(this.route.snapshot.paramMap.get("event"));
			this.localStorage.store("eventId", this.eventId);
		}
	}

	ngOnInit() {
		this.downloadCategories();
	}

	downloadCategories() {
		let params = {
			id_evento: this.eventId,
			best: true,
			stat: true
		};
		this.awsService.getCategories(params).then(categories => {
			if (categories.length > 0) {
				this.eventName = categories[0].evento;
				this.monetize = categories[0].monetize;
			}

			categories.sort((a, b) => {
				if (moment(a['data']).unix() > moment(b['data']).unix()) {
					return 1;
				} else if (moment(a['data']).unix() < moment(b['data']).unix()) {
					return -1;
				}
				return 0;
			}).map((category: any) => {
				category.data = moment(category.data).format(environment.format_date || "MM/DD/YYYY");
				if (category["best"] != null) {
					category["best"]["evento"] = category["evento"];
					category["best"]["categoria"] = category["categoria"];
				}
				return category;
			});

			if (categories.length == 0) {
				this.router.navigateByUrl('home/' + this.eventId, {skipLocationChange: true});
			} else {
				this.categories = categories;
				this.filteredCategories = this.categories;
				this.organizeByDate();
			}
		}).catch(() => {
			this.router.navigateByUrl('home/' + this.eventId, {skipLocationChange: true});
		});
	}

	organizeByDate() {

		let dateObj = {};
		this.dateCategoryList = [];
		this.live = null;

		this.filteredCategories.forEach(cat => {
			if (dateObj[cat.data] == null) {
				dateObj[cat.data] = {
					categories: [cat]
				}
			} else {
				dateObj[cat.data].categories.push(cat);
			}
			if (cat.status == 'Live') {
				this.live = cat;
			}
		});

		for (let key of Object.keys(dateObj)) {

			let catDate = {
				date: key,
				categories: []
			};

			catDate.categories = dateObj[key].categories.sort((a, b) => {
				if (moment(a['data']).unix() > moment(b['data']).unix()) {
					return 1;
				} else if (moment(a['data']).unix() < moment(b['data']).unix()) {
					return -1;
				}
				return 0;
			});

			this.dateCategoryList.push(catDate);
		}

	}

	public filterCategories(text = "") {
		this.filteredCategories = this.categories;

		if (text.trim() != "") {
			this.filteredCategories = this.categories.filter((category: any) => {
				let name = category.categoria.toLowerCase().includes(text.toLowerCase());
				let cavalo = category.cavalo != null && category.cavalo.toLowerCase().includes(text.toLowerCase());
				let competidor = category.competidor != null && category.competidor.toLowerCase().includes(text.toLowerCase());
				return name || cavalo || competidor;
			});
		}
		this.organizeByDate();
	}

	clickCategory(cat: CategoryModel) {
		this.localStorage.store("selectedCategory", cat.categoria);
		this.localStorage.store("selectedEvent", cat.evento);
		this.localStorage.store("eventId", this.eventId);
		if (this.monetize) {
			this.router.navigateByUrl('buy-analysis/' + this.eventId);
		} else {
			this.router.navigateByUrl('home/' + this.eventId);
		}
	}
	buySpecialReports(){
		this.localStorage.store("selectedEvent", this.eventName);
		this.localStorage.store("eventId", this.eventId);
		this.router.navigateByUrl('buy-special-reports/' + this.eventId);
	}


	handleClickWhatYouGet() {
		const url = "https://youtube.com/shorts/cmp4S0u0vfs?feature=share"
		window.open(url, '_system');
	}

	handleClickHowToPurchase() {
		const url = "https://youtube.com/shorts/ud6x5eN04UU?feature=share"
		window.open(url, '_system');

	}

}
