<div id="page-content">
    <div class="container-fluid pt-3 px-3 "
         style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));border-top: 1px solid rgb(255 255 255 / 25%);">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="d-flex flex-row align-items-center h-100">
                    <h3 class="text-white"
                        style="text-transform: uppercase;">{{'ranking_share_img.ranking_label' | translate}}</h3>
                    <div *ngIf="this.rankings && this.rankings[0] && this.rankings[0].url_mkt && this.rankings[0].url_mkt.length > 0; then thenAllBlock else elseAllBlock"></div>
                    <ng-template #thenAllBlock>
                        <i (click)="copyRankingLink()" button
                           class="mdi mdi-content-copy cursor-pointer ml-auto text-white"></i>

                        <i *ngIf="selectedCategory && selectedCategory.id != null"
                            (click)="generateAllImage($event)" button
                           class="mdi mdi-image cursor-pointer ml-3 text-white"></i>
                    </ng-template>
                    <ng-template #elseAllBlock>
                        <i button class="mdi mdi-content-copy ml-auto text-white"></i>

                        <i *ngIf="selectedCategory && selectedCategory.id != null"
                            button class="mdi mdi-image ml-3 text-white"></i>
                    </ng-template>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 py-3 d-inline-flex flex-column justify-content-end">
                <div class="equsSelect">
                    <span>{{'general.search_event_label' | translate}}</span>

                    <input #inputSearch (blur)="preventPushUpBug()"
                           [formControl]="pastEventsMatControl"
                           [matAutocomplete]="autoEventoSearch"
                           aria-label="Number" matInput
                           type="text"
                    >

                    <mat-autocomplete
                        #autoEventoSearch="matAutocomplete"
                        (optionSelected)="setEvent($event);inputSearch.blur();"
                        [panelWidth]="'auto'"

                    >
                        <mat-option *ngFor="let event of pastEventsMatFiltered  | async" [value]="event.evento"
                                    class="equsAutoComplete">
                            {{event.evento}}
                        </mat-option>
                    </mat-autocomplete>
                    <i (click)="clearSelectedEvent()" *ngIf="this.selectedEvent && this.selectedEvent.evento"
                       button
                       class="mdi mdi-close-outline cursor-pointer ml-auto delete"
                       style="float: right;margin-right: 5px;margin-top: 5px; height: 100%;">
                    </i>
                </div>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-12 col-sm-6 col-md-6 py-3 d-inline-flex flex-column justify-content-end">
                <div *ngIf="categories.length" class="equsSelect mdi mdi-menu-down">
                    <span>{{'general.search_class_label' | translate}}</span>
                    <select (change)="setCategory($event.target.value)">
                        <option *ngFor="let category of categories" [selected]="category.id==selectedCategory.id"
                                [value]="category.id">{{category.categoria}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <h3 class="title text-white">{{selectedRanking.label}}</h3>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 px-3 scrollbar" style="overflow: auto; margin-top: 7px;">
        <div *ngIf="rankings && rankings.length" class="row pb-4" id="rankings">

            <div #rank *ngFor="let ranking of rankings; let i = index" class="col-12 col-lg-6 mb-3 item_rank"
                 id="{{'rankDiv_'+i}}">
                <div class="d-flex flex-row">
                    <h3 class="title text-white d-inline-flex">{{ranking.subtitulo}}</h3>
                    <div *ngIf="ranking.url_mkt && ranking.url_mkt.length > 0; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <i (click)="copyRankingLink(ranking)" button
                           class="mdi mdi-content-copy cursor-pointer ml-auto text-white"></i>

                        <i (click)="generateImage($event,rank,ranking)" button
                           class="mdi mdi-image cursor-pointer ml-3 text-white"></i>
                    </ng-template>
                    <ng-template #elseBlock>
                        <i button class="mdi mdi-content-copy ml-auto text-white"></i>

                        <i button class="mdi mdi-image ml-3 text-white"></i>
                    </ng-template>

                </div>
                <div *ngIf="(ranking.data.length>0 && selectedCategory.id != null)"
                     class="d-flex flex-column p-2 mb-1 text-white"
                     style="background: rgba(0, 0, 0, 0.8);">
                    <div style="text-transform: uppercase;font-size: 1.3em; font-weight: bold;">{{ranking.data[0].evento}}</div>
                    <div style="font-size: 1.4em;">{{ranking.data[0].categoria}}</div>
                </div>
                <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                    <div *ngFor="let turn of ranking.data" [ngStyle]="(selectedCategory.id != null)?{'font-size': '1.1em','padding': '15px 8px;'}:{}"
                         class="turn d-flex flex-column">
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column" style="overflow:hidden;">
                                <span class="horse">
                                    <img src="/assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="/assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>
                            <div class="d-flex flex-row align-items-center ml-auto">
                                <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1"
                                                            style="font-size: 0.8em;"></i>{{toFixed(turn.tempo, 3)}}</span>
                                <span class="px-2 placing">{{(turn != null && turn.pos_txt != null) ? turn.pos_txt : turn.pos + "º"}}</span>
                            </div>
                        </div>
                        <div *ngIf="(selectedCategory.id == null)"
                             class="d-flex flex-row justify-content-between pt-2 text-white">
                            <span style="text-transform: uppercase;">{{turn.evento}}</span><span
                                class="px-2">{{turn.categoria}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="width:0px;height:0px;overflow:hidden;">
        <div id="img-model"
             style="width:500px;auto;background: linear-gradient(to top, black,rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('/assets/images/horse-background.jpg') no-repeat 30% center;background-size: cover;">
            <div style="height:100px;width:100%;background-color:rgba(0,0,0,0.5);padding: 16px;border-bottom:#d5aa4b solid 3px;">
                <img alt="" src="{{logoHeaderFile}}" style="width:65px;float: left;">
                <div style="width: 360px;float: right; margin-right: 30px;">
                    <h3 *ngIf="selectedEvent == null || selectedEvent.id == null"
                        style="font-family:roboto;color:#fff;font-size:18pt;text-align:right;margin:0px;">
                        RANKING {{getLabel(selectedCategory.id)}}
                    </h3>
                    <h3 *ngIf="selectedEvent != null && selectedEvent.id != null"
                        style="font-family:roboto;color:#fff;font-size:18pt;text-align:right;margin:0px;">{{selectedEvent.evento}}</h3>
                    <h3 style="font-family:roboto;color:#fff;font-size:14pt;text-align:right;margin:0px;" subtitle></h3>
                </div>
            </div>
            <div [ngStyle]="{'height':(625 - 94 + (getPatrocinadoresHeight() * ((patrocinadores.length)?-1:1)))+'px'}"
                 class="cont" style="width:100%;padding:10px 0;">
            </div>
            <div *ngIf="patrocinadores.length > 0" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}"
                 class="d-flex justify-content-center"
                 style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);">
                <div *ngFor="let patrocinador of patrocinadores"
                     [ngStyle]="{'width':getPatrocinadoresWidth()+'px'}"
                     style="display:flex;align-items:center;justify-content:center;height:100%;">
                    <img [crossOrigin]="'anonymous'" [src]="patrocinador+ '?not-from-cache'"
                         alt="" style="max-width:100%; max-height:100%;">
                </div>
            </div>
        </div>
    </div>
</div>
<!--  gerar as 3 iamgens numa só-->
<!-- style="width:0px;height:0px;overflow:hidden;" -->


<div style="width:0px;height:0px;overflow:hidden;">
    <div id="all-img-model"
         style="width:1080px;background: linear-gradient(to top, black,rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
              url('/assets/images/horse-background.jpg');background-size: cover;">
        <div style="height:auto;width:100%;background-color:rgba(0,0,0,0.5);padding: 16px;border-bottom:#d5aa4b solid 3px;">
            <img alt="" src="{{logoHeaderFile}}" style="width:130px;float: left;">
            <div style="margin-right: 30px; ">
                <h1 *ngIf="selectedEvent == null || selectedEvent.id == null"
                    style="font-family:roboto;color:#fff;font-size:22pt;text-align:right;margin:0px;">
                    RANKING {{getLabel(selectedCategory.id)}}
                </h1>
                <h1 *ngIf="selectedEvent != null && selectedEvent.id != null"
                    style="font-family:roboto;color:#fff;font-size:26pt;text-align:right;margin:0px;">{{selectedEvent.evento}}</h1>
                <h1 *ngIf="selectedCategory" style="font-family:roboto;color:#fff;font-size:20pt;text-align:right;margin:0px;" subtitle>{{selectedCategory.categoria}}</h1>
            </div>
            <div *ngIf="rankings && rankings.length" class="row pt-2 mt-3" id="rankings" style="border-top:#d5aa4b solid 3px;">

                <div #rank *ngFor="let ranking of rankings; let iRancing = index"[ngClass]="iRancing == 0? 'col-12 classTotal mb-3' : 'col-6'"
                     id="{{'rankDiv_'+iRancing}}">
                    <div style="text-align: center">
                            <h1 class="title title-gold" style="width: 100%">{{ranking.subtitulo}}</h1>

                    </div>
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div *ngFor="let turn of ranking.data; let i = index" [ngStyle]="(iRancing != 0)?{'margin': '5px','font-size': '1.2em','padding': '8px 8px'}:{'margin': '5px','font-size': '1.6em','padding': '4px 8px'}"
                             class="turn d-flex flex-column" >
                            <div class="d-flex flex-row">
                                <div class="w-100 d-flex flex-column" style="overflow:hidden;">
                                    <span class="horse">
                                        <img src="/assets/images/icons/horse-icon.png">
                                        <div class="">{{turn.cavalo}}</div>
                                        <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1"
                                                                    style="font-size: 0.8em;"></i>{{toFixed(turn.tempo, 3)}}</span>
                                                                    <span class="px-2 text-gold-placing">{{(turn != null && turn.pos_txt != null) ? turn.pos_txt : turn.pos + "º"}}</span>
                                    </span>
                                    <span class="competitor">
                                        <img src="/assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="(selectedCategory.id == null)"
                                 class="d-flex flex-row justify-content-between pt-2 text-white">
                                <span style="text-transform: uppercase;">{{turn.evento}}</span><span
                                    class="px-2">{{turn.categoria}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div [ngStyle]="{'height':(625 - 94 + (getPatrocinadoresHeight() * ((patrocinadores.length)?-1:1)))+'px'}"-->
<!--             class="cont" style="width:100%;padding:10px 0;">-->
<!--        </div>-->
        <div *ngIf="patrocinadores.length > 0" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}"
             class="d-flex justify-content-center"
             style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);">
            <div *ngFor="let patrocinador of patrocinadores"
                 [ngStyle]="{'width':getPatrocinadoresWidthAllImages()+'px'}"
                 style="display:flex;align-items:center;justify-content:center;height:100%;">
                <img [crossOrigin]="'anonymous'" [src]="patrocinador+ '?not-from-cache'"
                     alt="" style="max-width:100%; max-height:100%;">
            </div>
        </div>
    </div>
</div>
