
<div #container container>
    <div class="content">
        <header [attr.hide]="hideMenu">
            <div class="container-fluid px-3">
                <div class="d-flex flex-row mx-0 align-items-center w-100 h-100">
                    <img routerLink="/" class="logo cursor-pointer" src="{{logoHeaderFile}}" alt="">
                    <ul id="sidebar" class="ml-auto">
                        <li>
                            <img routerLink="/" class="logo cursor-pointer" src="{{logoHeaderFile}}" alt="">
                        </li>
                        <li link="home" routerLink="/" (click)="toggleMenu()" class="active">HOME</li>
                        <li *ngIf="isLogged()" link="events" (click)="toggleMenu()" routerLink="/events">{{'split_timing.menu_events' | translate}} </li>
                        <li *ngIf="isLogged()" link="ranking" (click)="toggleMenu()" routerLink="/ranking">{{'split_timing.menu_ranking' | translate}}</li>
                        <li link="analysis"  *ngIf="isAdmin()" (click)="toggleMenu('analysis')">{{'split_timing.menu_analysis' | translate}}</li>
                        <li *ngIf="isLogged()" (click)="logout()">{{'split_timing.menu_logout' | translate}}</li>
                        <li *ngIf="isAdmin()" [routerLink]="['/admin','events']" ><i class="mdi mdi-cog"></i></li>

                        <!--li link="contact" (click)="toggleMenu()" routerLink="/" [queryParams]="{section: 'contact'}">CONTATO</li-->
                    </ul>
                    <i class="mdi mdi-menu toggle-sidebar" (click)="toggleMenu()" ></i>
                </div>
            </div>
        </header>

        <router-outlet></router-outlet>
    </div>
</div>
<!--button class="fullscreen-toggler" #btFullscreen (click)="fulscreenMode($event)">
    <i class="mdi mdi-fullscreen"></i>
</button-->


