<div id="page-content">
    <div class="container-fluid" style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));">
        <div class="row pt-4">
            <div class="col-12 col-md-8">
                <span class="pl-3" style="color: #fff;font-size: 1.5em; text-transform: uppercase; font-weight: 600;">{{eventName}}</span>
            </div>
            <div class="col-12 col-md-4">
                <div (focus)="$event.target.firstElementChild.focus()" class="equsSearchInput mdi mdi-magnify">
                    <input type="text" placeholder="{{'general.label_search' | translate}}" (input)="filterCategories($event.target.value)">
                </div>
            </div>
        </div>
        <div *ngIf="monetize && !brasil" class="row" >
            <div class="col-12 py-3 d-flex flex-row justify-content-between">
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer" (click)="handleClickWhatYouGet()">What You Get</button>
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer" (click)="handleClickHowToPurchase()">How to Purchase</button>
            </div>
        </div>
    </div>
    <div class="container-fluid pt-1 mb-5" class="scroll-area scrollbar">
        <div  *ngIf="monetize && !brasil"  class="row pt-4">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 col col-sm-6 py-1">
                    <button class="button1 w-100"  type="button" style="font-size: 0.8em; cursor: pointer" (click)="buySpecialReports()">Buy Special Reports</button>
            </div>
        </div>

        <div class="my-3" *ngIf="live">
            <span style="color: #fff;font-size: 1.4em;">{{'general.label_live' | translate}}:</span>
            <div class="row mt-2">
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 col col-sm-6 py-1" (click)="clickCategory(live)">
                    <div class="event_card h-100 d-flex flex-column live">
                        <app-category-card
                            [cat]="live"
                        >
                        </app-category-card>
                    </div>
                </div>
            </div>
        </div>

        <div *ngFor="let dateCat of dateCategoryList" class="mt-2">
            <span style="color: #fff;font-size: 1.4em;"><i class="mdi mdi-calendar"></i> {{dateCat.date}}</span>
            <div class="row mt-2" style="border-left: 1px solid rgba(255,255,255,0.2);margin-left: 10px;">
                <div *ngFor="let cat of dateCat.categories" class="col-12 col-md-6 col-lg-4 col-xl-3 col col-sm-6 py-1" (click)="clickCategory(cat)" >
                    <div class="event_card h-100 d-flex flex-column">
                        <app-category-card
                            [cat]="cat"
                            [idEvento]="eventId"
                        >
                        </app-category-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
