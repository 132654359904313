import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

    private getFileName(name: string) {
        let timeSpan = new Date().toISOString();
        let sheetName = name || "ExportResult";
        let fileName = `${sheetName}-${timeSpan}`;
        return {
            sheetName,
            fileName
        };
    };

    private fitToColumn = data => {
        const columnWidths = [];
        for (const property in data[0]) {
            columnWidths.push({
                wch: Math.max(property ? property.toString().length : 0, ...data.map(obj => obj[property] ? obj[property].toString().length : 0))
            })
            ;
        }
        return columnWidths;
    };

    public exportTableToExcel(tableId: string, name?: string) {
        let { sheetName, fileName } = this.getFileName(name);
        let targetTableElm = document.getElementById(tableId);
        let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
            sheet: sheetName
        });
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    public exportArrayToExcel(arr: any[], name?: string) {
        let { sheetName, fileName } = this.getFileName(name);
        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(arr);

        //eu verifico o tamanho mínimo de cada coluna olhando os conteúdos de cada objeto para que nenhuma célula fique cortada quando o excel for gerado
        worksheet['!cols'] = this.fitToColumn(arr);

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    public exportArrayToCsv(data: any[], filename?: string){
        // Verifique se há dados para exportar
        if (data.length === 0) {
            console.warn('Nenhum dado para exportar.');
            return;
        }

        // Obtenha as chaves do primeiro objeto para criar o cabeçalho CSV
        const headers = Object.keys(data[0]);

        // Crie uma matriz que conterá os dados CSV, começando com o cabeçalho
        const csvData = [headers];

        // Itere sobre os objetos e extraia os valores para o CSV
        data.forEach((item) => {
            const row = headers.map((header) => item[header]);
            csvData.push(row);
        });

        // Gere o CSV com papaparse
        const csv = Papa.unparse(csvData);

        // Crie um elemento de âncora e atribua o CSV a ele
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename + '.csv'; // Define o nome do arquivo

        // Simule o clique no link para iniciar o download
        a.click();

        // Libere o objeto URL criado
        window.URL.revokeObjectURL(url);


    }

    public ExcelToJson(file){
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();

            fileReader.onload = function (e) {
                let data = fileReader.result;
                let workbook = XLSX.read(data, { type: "binary" });
                let sheet = workbook.Sheets[workbook.SheetNames[0]]; // pegando apenas a primeira folha do excel
                let json = XLSX.utils.sheet_to_json(sheet);

                resolve(json);
            };

            fileReader.onerror = function (e) {
                reject(e);
            };

            fileReader.readAsBinaryString(file);
        });
    }
}