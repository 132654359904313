<div  *ngIf="!best" [ngClass]="((atached)?'pb-2':'')" class="turn">
    <div class="turn_header">
        <span *ngIf="atached" class="mdi mdi-close cursor-pointer" (click)="removeAttached($event)" style="width: 40px; align-items: center; justify-content: center; display: flex; margin-right: 4px;"></span>
        <span *ngIf="!atached" class="mdi mdi-pin-outline cursor-pointer" (click)="setAttached($event)" style="width: 40px; align-items: center; justify-content: center; display: flex; margin-right: 4px;"></span>
        <span class="placing">
            {{(turn && turn.pos_txt !== null) ? turn.pos_txt : "--"}}
        </span>
        <div class="d-flex flex-column flex-grow-1" style="overflow: hidden;">
            <div class="participant">
                <div class="horse">
                    <img src="../../../assets/images/icons/horse-icon.png">
                    <div>{{(turn)?turn.cavalo:''}}</div>
                </div>
                <div class="competitor">
                    <img src="../../../assets/images/icons/cowboy-icon.png">
                    <div>{{(turn)?turn.competidor:'--'}}</div>
                </div>
            </div>
            <div class="participant">
                <div class="horse" >
                    <div style="font-weight: 700;">{{(turn)?turn.evento:''}}</div>
                </div>
                <div class="competitor">
                    <div>{{(turn)?turn.categoria:''}}</div>
                </div>
            </div>
        </div>

        <div style="display: flex;flex-direction: row;">
            <span [ngClass]="(turn && turn.total == min.total)?'max':null" class="total">
                <i *ngIf="turn == null || turn.pas==null" class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                <i *ngIf="turn != null && turn.pas!=null" class="mdi relogio mdi-timer-outline"
                [attr.data-pas]="(turn != null && turn.pas)?turn.pas:null" style="margin-top: 0px; font-size: 1.8em; position: relative; display: flex; justify-content: center; align-items: center; font-weight: 100;    line-height: 1em;margin-right: 3px;"></i>
                {{getTotal(turn)}}
            </span>
        </div>
    </div>
    <div class="turn_totals" [attr.display]="'parciais'" *ngIf="display == 'parciais'">
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r1 == min.r1)?'max':null">
                {{(turn && turn.r1 != null)?toFixed(turn.r1,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('r1', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r1', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v1 == min.v1)?'max':null">
                {{(turn && turn.v1 != null)?toFixed(turn.v1,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('v1', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v1', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r2 == min.r2)?'max':null">
                {{(turn && turn.r2 != null)?toFixed(turn.r2,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('r2', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r2', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v2 == min.v2)?'max':null">
                {{(turn && turn.v2 != null)?toFixed(turn.v2,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('v2', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v2', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.r3 == min.r3)?'max':null">
                {{(turn && turn.r3 != null)?toFixed(turn.r3,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('r3', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r3', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.v3 == min.v3)?'max':null">
                {{(turn && turn.v3 != null)?toFixed(turn.v3,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('v3', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v3', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rf == min.rf)?'max':null">
                {{(turn && turn.rf != null)?toFixed(turn.rf,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rf', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rf', turn, min)}}
            </span>
        </div>
    </div>

    <div class="turn_totals" [attr.display]="'rv'" *ngIf="display == 'rv'">
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rv1 == min.rv1)?'max':null">
                {{(turn && turn.rv1 != null)?toFixed(turn.rv1,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rv1', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv1', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rv2 == min.rv2)?'max':null">
                {{(turn && turn.rv2 != null)?toFixed(turn.rv2,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rv2', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv2', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rv3 == min.rv3)?'max':null">
                {{(turn && turn.rv3 != null)?toFixed(turn.rv3,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rv3', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv3', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rv3 == min.rf)?'max':null">
                {{(turn && turn.rf != null)?toFixed(turn.rf,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rf', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rf', turn, min)}}
            </span>
        </div>
    </div>

    <div class="turn_totals" [attr.display]="'rvr'" *ngIf="display == 'rvr'">
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rvr1 == min.rvr1)?'max':null">
                {{(turn && turn.rvr1 != null)?toFixed(turn.rvr1,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rvr1', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr1', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rvr2 == min.rvr2)?'max':null">
                {{(turn && turn.rvr2 != null)?toFixed(turn.rvr2,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rvr2', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr2', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.rvr3 == min.rvr3)?'max':null">
                {{(turn && turn.rvr3 != null)?toFixed(turn.rvr3,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('rvr3', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr3', turn, min)}}
            </span>
        </div>
    </div>

    <div class="turn_totals"  [attr.display]="'trtv'" *ngIf="display == 'trtv'">
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.tr == min.tr)?'max':null">
                {{(turn && turn.tr != null)?toFixed(turn.tr,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('tr', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('tr', turn, min)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" [ngClass]="(turn && turn.tv == min.tv)?'max':null">
                {{(turn && turn.tv != null)?toFixed(turn.tv,3):'--'}}
            </span>
            <span *ngIf="!atached" [ngClass]="(getDif('tv', turn, min).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('tv', turn, min)}}
            </span>
        </div>
    </div>
</div>

<div *ngIf="best" class="turn" style="background:#e7e7e7;">
    <div class="turn_header">
        <span *ngIf="atached" class="mdi mdi-close cursor-pointer" (click)="removeAttached($event)" style="width: 40px; align-items: center; justify-content: center; display: flex; margin-right: 4px;"></span>
        <span *ngIf="!atached" class="mdi mdi-pin-outline cursor-pointer" (click)="setAttached($event)" style="width: 40px; align-items: center; justify-content: center; display: flex; margin-right: 4px;"></span>

        <span class="placing">
            <i class="mdi mdi-star"></i>
        </span>
        <div class="participant">
            <span class="horse"  style="width: unset;">
                <div>{{'analysis_car.best_partials_label' | translate}}</div>
            </span>
        </div>
        <div style="display: flex;flex-direction: row;"></div>
        <div style="display: flex;flex-direction: row;">
            <span class="total" style="color: #000;">
                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                {{getTotal(min)}}
            </span>

        </div>
    </div>
    <div class="turn_totals" [attr.display]="'parciais'" *ngIf="display == 'parciais'">
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.r1 != null)?toFixed(min.r1,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('r1',  min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r1', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.v1 != null)?toFixed(min.v1,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('v1', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v1', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.r2 != null)?toFixed(min.r2,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('r2', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r2', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.v2 != null)?toFixed(min.v2,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('v2', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v2', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.r3 != null)?toFixed(min.r3,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('r3', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('r3', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.v3 != null)?toFixed(min.v3,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('v3', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('v3', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.rf != null)?toFixed(min.rf,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rf', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rf', min, turn)}}
            </span>
        </div>
    </div>
    <div class="turn_totals" [attr.display]="'rv'" *ngIf="display == 'rv'">
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.rv1 != null)?toFixed(min.rv1,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rv1', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv1', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.rv2 != null)?toFixed(min.rv2,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rv2', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv2', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.rv3 != null)?toFixed(min.rv3,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rv3', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rv3', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center">
                {{(min && min.rf != null)?toFixed(min.rf,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rf', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rf', min, turn)}}
            </span>
        </div>
    </div>
    <div class="turn_totals" [attr.display]="'rvr'" *ngIf="display == 'rvr'">
        <div>
            <span class="d-flex flex-column align-items-center" class="max">
                {{(min && min.rvr1 != null)?toFixed(min.rvr1,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rvr1', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr1', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" class="max">
                {{(min && min.rvr2 != null)?toFixed(min.rvr2,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rvr2', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr2', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" class="max">
                {{(min && min.rvr3 != null)?toFixed(min.rvr3,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('rvr3', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('rvr3', min, turn)}}
            </span>
        </div>
    </div>
    <div class="turn_totals" [attr.display]="'trtv'" *ngIf="display == 'trtv'">
        <div>
            <span class="d-flex flex-column align-items-center" class="max">
                {{(min && min.tr != null)?toFixed(min.tr,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('tr', min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('tr', min, turn)}}
            </span>
        </div>
        <div>
            <span class="d-flex flex-column align-items-center" class="max">
                {{(min && min.tv != null)?toFixed(min.tv,3):'--'}}
            </span>
            <span *ngIf="turn != null && turn != min" [ngClass]="(getDif('tv',  min, turn).includes('+'))?'diferences-pos':'diferences-neg'">
                {{getDif('tv', min, turn)}}
            </span>
        </div>
    </div>
</div>
