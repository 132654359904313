import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/category.model';
import { EventModel } from 'src/app/models/event.model';
import { ActivatedRoute } from '@angular/router';
import { AwsService } from 'src/app/services/aws.service';
import { TurnModel } from 'src/app/models/turn.model';
import * as moment from 'moment';

import {Meta, MetaDefinition} from '@angular/platform-browser';
import {environment} from "../../../environments/environment";


class Ranking{
  constructor(
    public label:string = "",
    public data:TurnModel[] = []
  ){}
}

@Component({
  selector: 'app-ranking-share',
  templateUrl: './ranking-share.component.html',
  styleUrls: ['./ranking-share.component.scss']
})
export class RankingShareComponent implements OnInit {
  logoHeaderFile = "assets/images/"+environment.logoHeaderFile;
  rankings:any[] = [];
  event_id:string;
  category_id:string;
  type:string;
  patrocinadores:string[] = [];

  constructor(
    private route: ActivatedRoute,
    private awsService:AwsService
  ) {
    this.event_id = this.route.snapshot.queryParamMap.get("e")
    this.category_id = this.route.snapshot.queryParamMap.get("c")
    this.type = this.route.snapshot.queryParamMap.get("r")
  }

  ngOnInit() {
    this.downloadRankings(this.category_id, this.event_id, this.type);
    setTimeout(() => {this.removeChat();},3000);
  }

  removeChat(){
    let r = document.getElementById('LeadboosterContainer');
    if(r != null) r.parentElement.removeChild(r);
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  downloadRankings(category, event,type){
    this.awsService.getRanking(event, category,type).then((rankings:any[]) => {
      let turns:TurnModel[] = [];
      if(rankings != null && Array.isArray(rankings)){
        for(let ranking of rankings){
          if(ranking.ranking != null){
            turns = ranking.ranking.map(turn => Object.assign(new TurnModel(), turn));
            ranking.data = turns;
          }
        }
        if(rankings.length && rankings[0]["url_mkt"] != null){
          this.patrocinadores = rankings[0]["url_mkt"].filter(img => {
            return (img != null && img.trim() != "");
          });
        }
        this.rankings = rankings;
      }
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      console.log("loading end");
    });
  }

  public getPatrocinadoresHeight(){
    return 95;
  }
  public getPatrocinadoresWidth(){
    return (500/((this.patrocinadores.length >= 4)?this.patrocinadores.length:4));
  }

}
