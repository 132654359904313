<div id="page-content">
    <div class="container-fluid p-3 " style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));">
        <div class="row">
            <div class="col-12 d-flex flex-row align-items-center">
                <img routerLink="/" style="height:65px;" class="mr-5 cursor-pointer"
                     src="{{logoHeaderFile}}" alt="">
                <h3 class="text-white"
                    style="text-transform: uppercase;">{{'ranking_share_img.ranking_label' | translate}}</h3>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 px-3 scrollbar" style="overflow: auto;">
        <div id="rankings" class="row" *ngIf="ranking_images && ranking_images.length">
            <div *ngFor="let image of ranking_images"
                 class="col-12 col-lg-6 mt-3 d-flex flex-column item_rank"
                 [ngClass]="(rankings.length)?'mx-auto':''">
                <img [src]="image.src" style="width:500px;height:600px;" crossorigin="anonymous" alt="">
                <span class="instagram">
                    <span *ngFor="let i of image.instagrams" class="mr-3" (click)="copyInstagram(i)">{{i}}</span>
                </span>
            </div>
        </div>
    </div>

    <div style="width:0px;height:0px;overflow:hidden;">
        <div id="img-model"
             style="width:500px;height:600px;background: linear-gradient(to top, black,rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('/assets/images/horse-background.jpg') no-repeat 30% center;background-size: cover;">
            <div style="height:104px;width:100%;background-color:rgba(0,0,0,0.5);padding: 16px;border-bottom:#d5aa4b solid 3px;">
                <img style="width:70px;float: left;" src="/{{logoHeaderFile}}" alt="">
                <div style="display: inline-block; width: 360px;float: right;">
                    <h3 *ngIf="selectedEvent == null || selectedEvent.id == null"
                        style="font-family:roboto;color:#fff;font-size:18pt;text-align:right;margin:0px;">
                        {{'ranking_share_img.ranking_label' | translate}} {{getLabel(selectedCategory.id)}}</h3>
                    <h3 *ngIf="selectedEvent != null && selectedEvent.id != null"
                        style="font-family:roboto;color:#fff;font-size:18pt;text-align:right;margin:0px;">
                        {{selectedEvent.evento}}</h3>
                    <h3 subtitle style="font-family:roboto;color:#fff;font-size:14pt;text-align:right;margin:0px;"></h3>
                </div>
            </div>
            <div [ngStyle]="{'height':(640 - 94 - ((patrocinadores.length)?90:0))+'px'}"
                 style="width:100%;padding:10px 0;" class="cont">
                <div id="rank" class="col-12 item_rank">
                    <div class="d-flex flex-column w-100" *ngIf="ranking != null">
                        <div class="d-flex flex-row justify-content-between">
                            <h3 class="title text-white d-inline-flex w-100"
                                style="white-space:nowrap">{{ranking.subtitulo}}</h3>
                        </div>
                        <div class="d-flex flex-column p-2 mb-1 text-white"
                             *ngIf="(ranking.data.length>0 && category_id != null)"
                             style="background: rgba(0, 0, 0, 0.8);">
                            <div style="text-transform: uppercase;font-size: 1.3em; font-weight: bold;">{{ranking.data[0].evento}}</div>
                            <div style="font-size: 1.4em;">{{ranking.data[0].categoria}}</div>
                        </div>
                        <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                            <div class="turn d-flex flex-column" *ngFor="let turn of ranking.data"
                                 [ngStyle]="(category_id != null)?{'font-size': '1.1em','padding': '15px 8px'}:{}">
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column" style="overflow:hidden;">
                                        <span class="horse">
                                            <img src="/assets/images/icons/horse-icon.png">
                                            <div>{{turn.cavalo}}</div>
                                        </span>
                                        <span class="competitor">
                                            <img src="/assets/images/icons/cowboy-icon.png">
                                            <div>{{turn.competidor}}</div>
                                        </span>
                                    </div>
                                    <div class="d-flex flex-row align-items-center ml-auto">
                                        <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1"
                                                                    style="font-size: 0.8em;"></i>{{toFixed(turn.tempo, 3)}}</span>
                                        <span class="px-2 placing_img">{{(turn != null && turn.pos_txt != null) ? turn.pos_txt :  turn.pos+"º"}}</span>
                                    </div>
                                </div>
                                <div class="d-flex flex-row justify-content-between pt-2 text-white"
                                     *ngIf="(category_id == null)">
                                    <span style="text-transform: uppercase;">{{turn.evento}}</span><span
                                        class="px-2">{{turn.categoria}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="patrocinadores.length"
                 style="height:90px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);"
                 class="d-flex justify-content-center">
                <div *ngFor="let patrocinador of patrocinadores"
                     style="display:flex;align-items:center;justify-content:center;height:100%;"
                     [ngStyle]="{'width':getPatrocinadoresWidth()+'px'}">
                    <img [src]="patrocinador+ '?not-from-cache'" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
