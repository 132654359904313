import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TurnModel } from 'src/app/models/turn.model';
import { BrokerService } from 'src/app/services/broker.service';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-transmissao1',
  templateUrl: './transmissao1.component.html',
  styleUrls: ['./transmissao1.component.scss']
})
export class Transmissao1Component implements OnInit, AfterViewInit {

  logoBlackPath= "../../../assets/images/"+environment.logoBlack;

  id_evento:number;
  nextTurns:any[] = [];
  ranking:any[] =  [];
  category:string="";
  event:string="";
  nextLogos:string[]=[];
  rankingLogos:string[]=[];
  test_message:any = {"evento": "Copa 3DM - Potro Brasil 2021","categoria": "GP ABQM","current": null,"best": null,"next": [{"ord": 1,"cavalo": "CHEYENNE VICTORY IMP","competidor": "RENATO DA SILVA"},{"ord": 2,"cavalo": "SANTO MAY DASH","competidor": "CLAUDIO PEREIRA DA SILVA"},{"ord": 3,"cavalo": "VF CREAM RISES","competidor": "RODRIGO FERNANDES DE OLIVEIRA"},{"ord": 4,"cavalo": "KARAMELLO BEAVER 3D","competidor": "NEHEMIAS LIMA DOS SANTOS FILHO"},{"ord": 5,"cavalo": "COUNTRY MOOSE HSC","competidor": "KEILA SUELEN APARECIDA DE MENDONCA"},{"ord": 6,"cavalo": "ST CASHEW BULLET","competidor": "MARTHA HELENA MADEIRA HERWEG"},{"ord": 7,"cavalo": "ST TIP TIP","competidor": "PAULO CEZAR DOS SANTOS"},{"ord": 8,"cavalo": "TAKITO FAME","competidor": "DANIEL ARAUJO LOURENCO DA SILVA"},{"ord": 9,"cavalo": "BONIQUITA ZORRERO","competidor": "LUAN ALVES DOS SANTOS"},{"ord": 10,"cavalo": "STREAK OF KING EK","competidor": "MATHEUS MARTINS UNGARO"}],"top10": [{"pos": 1,"cavalo": "CHEYENNE VICTORY IMP","competidor": "RENATO DA SILVA"},{"pos": 2,"cavalo": "SANTO MAY DASH","competidor": "CLAUDIO PEREIRA DA SILVA"},{"pos": 3,"cavalo": "VF CREAM RISES","competidor": "RODRIGO FERNANDES DE OLIVEIRA"},{"pos": 4,"cavalo": "KARAMELLO BEAVER 3D","competidor": "NEHEMIAS LIMA DOS SANTOS FILHO"},{"pos": 5,"cavalo": "COUNTRY MOOSE HSC","competidor": "KEILA SUELEN APARECIDA DE MENDONCA"},{"pos": 6,"cavalo": "ST CASHEW BULLET","competidor": "MARTHA HELENA MADEIRA HERWEG"},{"pos": 7,"cavalo": "ST TIP TIP","competidor": "PAULO CEZAR DOS SANTOS"},{"pos": 8,"cavalo": "TAKITO FAME","competidor": "DANIEL ARAUJO LOURENCO DA SILVA"},{"pos": 9,"cavalo": "BONIQUITA ZORRERO","competidor": "LUAN ALVES DOS SANTOS"},{"pos": 10,"cavalo": "STREAK OF KING EK","competidor": "MATHEUS MARTINS UNGARO"}]};
  background:string = null;

  constructor(
    private brokerService: BrokerService,
    private route:ActivatedRoute,
    private translate: TranslateService,
  ) {
    this.id_evento = Number(this.route.snapshot.queryParamMap.get("id_evento"));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.removeChat();
    },3000);
  }

  ngOnInit(){
    this.brokerService.startForComponent('',() => {
      this.brokerService.subscribe('equslive_'+this.id_evento,2,(message) => {
        this.onNewData(message);
      });
    });
    this.handleNewdata({});
  }

  removeChat(){
    let r = document.getElementById('LeadboosterContainer');
    if(r != null) r.parentElement.removeChild(r);
  }

  onNewData(message){
    this.handleNewdata(message);
  }

  handleNewdata(message){
    this.removeChat();
    this.category = message["categoria"];

    let eventFromMessage = message['evento'];

    if(eventFromMessage != null)
        this.event = eventFromMessage;

    if(message["next"] != null) {
      this.nextTurns = message["next"].map(turn => {
        return this.adjustTurn(Object.assign(new TurnModel(), turn));
      });
      this.ranking = message["top10"].map(turn => {
        return this.adjustTurn(Object.assign(new TurnModel(), turn));
      });
      this.nextLogos = message["next_logo"];
      this.rankingLogos = message["top10_logo"];

      //console.log(this.ranking);
    }

    let qtd = this.nextTurns.length;
    if(qtd<10){
      for(let i=0;i < 10 - qtd;i++)
        this.nextTurns.push(new TurnModel());
    }

    qtd = this.ranking.length;
    if(qtd<10){
      for(let i=0;i < 10 - qtd;i++)
        this.ranking.push(new TurnModel());
    }
  }

  setBackground(event){
    this.background = event.target.value;
  }

  adjustTurn(turn){
    turn.v1 = (turn.v1 != null)? parseFloat(turn.v1).toFixed(3) : null;
    turn.v2 = (turn.v2 != null)? parseFloat(turn.v2).toFixed(3) : null;
    turn.v3 = (turn.v3 != null)? parseFloat(turn.v3).toFixed(3) : null;
    turn.r1 = (turn.r1 != null)? parseFloat(turn.r1).toFixed(3) : null;
    turn.r2 = (turn.r2 != null)? parseFloat(turn.r2).toFixed(3) : null;
    turn.r3 = (turn.r3 != null)? parseFloat(turn.r3).toFixed(3) : null;
    turn.rf = (turn.rf != null)? parseFloat(turn.rf).toFixed(3) : null;
    turn.tr = (turn.tr != null)? parseFloat(turn.tr).toFixed(3) : null;
    turn.tv = (turn.tv != null)? parseFloat(turn.tv).toFixed(3) : null;
    turn.rv1 = (turn.rv1 != null)? parseFloat(turn.rv1).toFixed(3) : null;
    turn.rv2 = (turn.rv2 != null)? parseFloat(turn.rv2).toFixed(3) : null;
    turn.rv3 = (turn.rv3 != null)? parseFloat(turn.rv3).toFixed(3) : null;
    turn.rvr1 = (turn.rvr1 != null)? parseFloat(turn.rvr1).toFixed(3) : null;
    turn.rvr2 = (turn.rvr2 != null)? parseFloat(turn.rvr2).toFixed(3) : null;
    turn.rvr3 = (turn.rvr3 != null)? parseFloat(turn.rvr3).toFixed(3) : null;

    turn.rvrt = (turn.rvr1 != null && turn.rvr2 != null && turn.rvr3 != null)?(turn.rvr1 + turn.rvr2 + turn.rvr3):null;

    if(turn.total != null && !isNaN(turn.total)){
      turn.total = (turn.total != null)?parseFloat(turn.total).toFixed(3):null;
    }else{
      turn.total = "--";
    }

    turn.sat=turn.sat!=true?false:true;
    turn.nc=turn.nc!=true?false:true;
    turn.pos_txt = (turn.pos_txt != null) ? turn.pos_txt : turn.pos+"º";

    return turn;
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  public getPatrocinadoresHeight(){
    return 95;
    //return ((500/((this.patrocinadores.length >= 4)?this.patrocinadores.length:4))*(900/16))/100;
  }
  public getPatrocinadoresWidth(length){
    return (500/((length >= 4)?length:4));
  }

}
