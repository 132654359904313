<div id="page-content" [ngStyle]="(background != null)?{'background-color':background}:null">
    <div class="container-fluid px-0 d-flex flex-column" style="height:100vh;">

        <label for="color" class="mdi mdi-eyedropper d-flex align-items-center justify-content-center cursor-pointer" style="color: #ccc;position:absolute;top:0px;right:0px;width:40px;height:40px;border-radius:20px;font-size: 22pt;"></label>
        <input id="color" type="color" hidden style="position:absolute;right:0px;top:0px;" (change)="setBackground($event)">

        <div class="best_area flex-grow-1">
            <div class="mx-auto" style="width: 220px;padding-top: 46px;">
                <div class="d-flex flex-column w-100" style="background: #222;">
                    <div class="w-100" style="border-bottom: #77777711 solid 1px;width:220px;height:170px;background:var(--bg-transmissao);background-size: cover;background-position: center;background-color: #4A4A4A;" [ngStyle]="{'background-image':'url('+((farol['img'])?farol['img']:'../../../assets/images/split-timing-fundopreto.png')+')'}">
                    </div>
                    <div class="farol">
                        <div [ngStyle]="{'background-color':((farol['T1'])?farol['T1']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['T2'])?farol['T2']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['T3'])?farol['T3']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['rf'])?farol['rf']:'transparent')}"></div>
                    </div>
                    <div class="text-center p-2 d-flex justify-content-center" style="font-family:'ds-digi', sans-serif;background: linear-gradient(to top, #222, rgb(255 255 255 / 10%), rgb(255 255 255 / 30%)); font-size: 40pt;line-height: 40pt;font-weight: 700;" [ngStyle]="{'color':((farol['acc_color'])?farol['acc_color']:'#fff')}">
                        <div class="text-center py-2 d-flex justify-content-center" style="width: 100%;font-family:'ds-digi', sans-serif;background: #000; font-size: 40pt;line-height: 40pt;font-weight: 700;" [ngStyle]="{'color':((farol['acc_color'])?farol['acc_color']:'#fff')}">{{((farol['acc'])?farol['acc']:'--')}}</div>
                    </div>
                </div>
                <div class="chart d-flex flex-column d-flex flex-row text-white w-100" style=" height: 190px;background:#231212;">
                    <div class="chart-inner position-relative d-flex pt-2 justify-content-center  flex-grow-1">
                        <img src="/assets/images/laco_seq0.png" class="image">

                        <img *ngIf="farol['T1']" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(farol['T1'])+'deg) brightness(2)'}" src="/assets/images/laco_seq1.png" class="image">

                        <img *ngIf="farol['T2']" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(farol['T2'])+'deg) brightness(2)'}" src="/assets/images/laco_seq2.png" class="image">

                        <img *ngIf="farol['T3']" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(farol['T3'])+'deg) brightness(2)'}" src="/assets/images/laco_seq3.png" class="image">

                        <img *ngIf="farol['rf']" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(farol['rf'])+'deg) brightness(2)'}" src="/assets/images/laco_seq4.png" class="image">
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
        </div>
    </div>
    <!--div class="lateral">
        <div style="margin:15px 0;"><h3>Acompanhe o <br>evento ao vivo</h3>
            <h2 style="font-size:3em;margin-top:30px;">Acesse</h2>
            www.equsperformance.com.br</div>
        <div><img src="../../../assets/images/qrcode.jpeg" style="width:85%;"></div>
    </div-->
</div>
