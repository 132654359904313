<div id="page-content">
    <div class="container-fluid px-0">
        <div class="header d-flex flex-column flex-wrap"
             style="padding: 0px 30px;border-top: 1px solid rgb(255 255 255 / 25%);">
            <div class="w-100 p-0 d-flex flex-column justify-content-end mb-2 mb-md-3">
                <div class="row mx-0">
                    <div class="col-12 col-md-2 col-lg-2 pt-3 pt-3">
                        <span style="font-size: 1.1em;margin-bottom: 12px;color: #000;font-size: 1.6em; text-transform: uppercase; font-weight: 600;">{{'analysis.title_analysis' | translate}}</span>
                    </div>
                    <div class="pt-3 col-12 col-md-3 col-lg-3 d-flex flex-column justify-content-end">
                        <div class="equsSelect mdi mdi-menu-down">
                            <select (change)="resetSort()" [(ngModel)]="display">
                                <option value="parciais">{{'analysis.partials' | translate}}</option>
                                <option value="rv">{{'analysis.straights_turns' | translate}}</option>
                                <option value="rvr">{{'general.label_straight_turn_straight' | translate}}</option>-->
                                <option value="trtv">{{'analysis.total_straights_total_turns' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-9 col-md-5 col-lg-5 d-flex flex-column justify-content-end">
                        <div (focus)="$event.target.firstElementChild.focus()" class="equsSearchInput mdi mdi-magnify">
                            <input id="search" type="text" placeholder="{{'general.label_search' | translate}}" (click)="click_search($event)"
                                   (keydown)="keyDownFunction($event)" [(ngModel)]="searchText">
                            <div class="filter_button" (click)="filterOpened = !filterOpened">
                                <i class="mdi mdi-filter-menu"></i></div>
                            <form id="box" [formGroup]="filterForm" (ngSubmit)="set_search()"
                                  class="flex-column bilter_box" [ngClass]="((filterOpened)?'d-flex':'d-none')">
                                <ul>
                                    <li>
                                        <span>
                                           {{'analysis.event' | translate}}
                                        </span>
                                        <input type="text" (blur)="preventPushUpBug()"
                                               (focus)="onFocus('evento')"
                                               (input)="onEventSearch($event)" [matAutocomplete]="event"
                                               formControlName="evento">
                                        <mat-autocomplete #event="matAutocomplete">
                                            <mat-option *ngFor="let option of eventOptions" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </li>
                                    <li>
                                        <span>
                                            {{'analysis.class' | translate}}
                                        </span>
                                        <input type="text"
                                               (blur)="preventPushUpBug()"
                                               (click)="('categoria')"
                                               (input)="onCategoriaSearch($event)"

                                               formControlName="categoria"
                                               [matAutocomplete]="categoria">
                                        <mat-autocomplete #categoria="matAutocomplete">
                                            <mat-option *ngFor="let option of categoriaOptions" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </li>
                                    <li>
                                        <span>
                                            {{'analysis.horse' | translate}}
                                        </span>
                                        <input type="text" (blur)="preventPushUpBug()" (focus)="onFocus('cavalo')"
                                               (input)="onCavaloSearch($event)" formControlName="cavalo"
                                               [matAutocomplete]="cavalo">
                                        <mat-autocomplete #cavalo="matAutocomplete">
                                            <mat-option *ngFor="let option of cavaloOptions" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </li>
                                    <li>
                                        <span>
                                            {{'analysis.competitor' | translate}}
                                        </span>
                                        <input type="text" (blur)="preventPushUpBug()" (focus)="onFocus('competidor')"
                                               (input)="onCompetidorSearch($event)" formControlName="competidor"
                                               [matAutocomplete]="competidor">
                                        <mat-autocomplete #competidor="matAutocomplete">
                                            <mat-option *ngFor="let option of competidorOptions" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </li>

                                </ul>
                                <div class="d-flex flex-row justify-content-between">
                                    <button class="search_button" type="button"
                                            (click)="clear_search()">{{'general.label_clear' | translate}}</button>
                                    <button class="search_button"
                                            type="submit">{{'general.label_ok' | translate}}</button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div class="col-3 col-md-2 col-lg-2 ml-auto ml-md-0 d-flex flex-column justify-content-end">
                        <button class="search_button" (click)="search()"><i *ngIf="loading"
                                                                            class="mdi mdi-spin mdi-loading"></i> {{(!loading) ? 'OK' : ''}}
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="turn_totals_fields" [attr.display]="'parciais'" *ngIf="display == 'parciais'">
            <span style="width: 24px;"></span>
            <span (click)="sortBy($event,'r1')">{{'analysis.label_1st_s' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'v1')">{{'analysis.label_1st_b' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'r2')">{{'analysis.label_2nd_s' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'v2')">{{'analysis.label_2nd_b' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'r3')">{{'analysis.label_3rd_s' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'v3')">{{'analysis.label_3rd_b' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rf')">{{'analysis.label_fs' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'total')" style="width: 58px;">{{'analysis.label_tot' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>

        <div class="turn_totals_fields" [attr.display]="'rv'" *ngIf="display == 'rv'">
            <span style="width: 24px;"></span>
            <span (click)="sortBy($event,'rv1')">{{'analysis.label_1st_st' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rv2')">{{'analysis.label_2nd_st' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rv3')">{{'analysis.label_3rd_st' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rf')">{{'analysis.label_fs' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'total')" style="width: 58px;">{{'analysis.label_tot' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>

        <div class="turn_totals_fields" [attr.display]="'rvr'" *ngIf="display == 'rvr'">
            <span style="width: 24px;"></span>
            <span (click)="sortBy($event,'rvr1')">{{'analysis.label_1st_sts' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rvr2')">{{'analysis.label_2nd_sts' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'rvr3')">{{'analysis.label_3rd_sts' | translate}}<i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'total')" style="width: 58px;">{{'analysis.label_tot' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>

        <div class="turn_totals_fields" [attr.display]="'trtv'" *ngIf="display == 'trtv'">
            <span style="width: 24px;"></span>
            <span (click)="sortBy($event,'tr')">{{'analysis.label_tot_straight' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'tv')">{{'analysis.label_tot_turn' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'total')" style="width: 58px;">{{'analysis.label_tot' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>

        <app-analysis-card *ngIf="selfTurn" [best]="selfTurn == min" (removeAttach)="removeAttachedTurn()"
                           [atached]="true" [min]="min" [turn]="selfTurn" [display]="display"></app-analysis-card>

    </div>
    <div id="turns" class="scrollbar" style="padding-bottom:80px;" *ngIf="turns.length">
        <app-analysis-card *ngIf="min != null && selfTurn != min" (attach)="attachTurn(min)" [best]="true" [min]="min"
                           [turn]="selfTurn" [display]="display" (click)="openCompareDialog(min)"></app-analysis-card>
        <app-analysis-card (attach)="attachTurn(turn)" [min]="((selfTurn != null)?selfTurn:min)" [turn]="turn"
                           [display]="display" *ngFor="let turn of filteredTurns"
                           (click)="openCompareDialog(turn)"></app-analysis-card>
    </div>
    <div class="w-100 p-3" *ngIf="!turns.length">
        <div style="    background-color: rgb(189 189 189);border: none; color: #2b2b2b;font-size: 14pt;"
             class="alert alert-primary" role="alert">
            <h3 style="font-size: 14pt;display: flex;align-items: center;">
                <i style="font-size: 18pt;" class="mdi mdi-alert-box-outline mr-2"></i>
                {{'analysis.label_empty_list' | translate}}
            </h3>
            {{'analysis.label_to_start_analysis_use' | translate}}
        </div>
    </div>
    <!--//-->

</div>
