<div id="page-content">
    <div class="container-fluid px-0 d-flex flex-column" style="height:100vh;">
        <div class="best_area flex-grow-1">
            <div class="ml-auto mr-3" style="width: 220px;padding-top: 46px;">
                <div class="d-flex flex-column w-100" style="background: #222;">
                    <div class="w-100" style="border-bottom: #77777711 solid 1px;">
                        <img style="width:220px;" [src]="((farol['img'])?farol['img']:'{{logoTransmissaoPath}}')">
                    </div>
                    <div class="farol">
                        <div [ngStyle]="{'background-color':((farol['T1'])?farol['T1']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['T2'])?farol['T2']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['T3'])?farol['T3']:'transparent')}"></div>
                        <div [ngStyle]="{'background-color':((farol['rf'])?farol['rf']:'transparent')}"></div>
                    </div>
                    <span class="text-center py-4 d-flex justify-content-center" style="background: linear-gradient(to top, #222, rgb(255 255 255 / 10%), rgb(255 255 255 / 30%)); font-size: 28pt;font-weight: 700;" [ngStyle]="{'color':((farol['acc_color'])?farol['acc_color']:'#fff')}">{{((farol['acc'])?farol['acc']:'--')}}</span>
                </div>
            </div>
            <div class="d-flex flex-column mr-auto" style="background: #222;width: 300px;">
                <div id="dif-table" style="width:300px;">
                    <table class="dif_table">
                        <thead >
                            <tr>
                                <td><img class="logo" src="{{logoHeaderFile}}"></td>
                                <td style="max-width: 70px;font-size: 0.8em;line-height: 1em;font-weight: bold;">{{'general.current_best_time' | translate}}</td>
                                <td style="max-width: 70px;font-size: 1em;line-height: 1em; font-weight: bold;">DIF.</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="r1">1st Straight</td>
                                <td>{{bestTurn.r1}}</td>
                                <td [ngClass]="getClassColor('r1')">{{getDif('r1')}}</td>
                            </tr>
                            <tr>
                                <td class="v1">1st Turn</td>
                                <td>{{bestTurn.v1}}</td>
                                <td [ngClass]="getClassColor('v1')">{{getDif('v1')}}</td>
                            </tr>
                            <tr>
                                <td class="r2">2nd Straight</td>
                                <td>{{bestTurn.r2}}</td>
                                <td [ngClass]="getClassColor('r2')">{{getDif('r2')}}</td>
                            </tr>
                            <tr>
                                <td class="v2">2nd Turn</td>
                                <td>{{bestTurn.v2}}</td>
                                <td [ngClass]="getClassColor('v2')">{{getDif('v2')}}</td>
                            </tr>
                            <tr>
                                <td class="r3">3rd Straight</td>
                                <td>{{bestTurn.r3}}</td>
                                <td [ngClass]="getClassColor('r3')">{{getDif('r3')}}</td>
                            </tr>
                            <tr>
                                <td class="v3">3rd Turn</td>
                                <td>{{bestTurn.v3}}</td>
                                <td [ngClass]="getClassColor('v3')">{{getDif('v3')}}</td>
                            </tr>
                            <tr>
                                <td class="rf">Final Straight</td>
                                <td>{{bestTurn.rf}}</td>
                                <td [ngClass]="getClassColor('rf')">{{getDif('rf')}}</td>
                            </tr>
                            <tr>
                                <td class="t">Total</td>
                                <td>{{bestTurn.total}}</td>
                                <td [ngClass]="getClassColor('total')">{{getDif('total')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="chart d-flex flex-column d-flex flex-row text-white w-100" style=" height: 285px;background:#00000066;border-bottom:#ffffff22 solid 1px;">
                    <div class="chart-inner position-relative d-flex pt-4 justify-content-center  flex-grow-1">
                        <img src="../../../../assets/images/laco.png" class="image">
                        <div class="difs">
                            <span class="dif">{{getDif('r1')}}</span>
                            <span class="dif">{{getDif('v1')}}</span>
                            <span class="dif">{{getDif('r2')}}</span>
                            <span class="dif">{{getDif('v2')}}</span>
                            <span class="dif">{{getDif('r3')}}</span>
                            <span class="dif">{{getDif('v3')}}</span>
                            <span class="dif">{{getDif('rf')}}</span>
                            <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T1 == 1" class="mdi mdi-close"></i> </label>
                            <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T2 == 1" class="mdi mdi-close"></i> </label>
                            <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T3 == 1" class="mdi mdi-close"></i> </label>
                        </div>
                    </div>
                    <div style="clear:both;"></div>
                </div>

                <div style="margin:15px 0;">
                    <h3 *ngIf="nextTurns.length > 0" class="text-white" style="margin: 0px 15px 10px">Next runs</h3>
                    <ul class="turns scrollbar">
                        <li *ngFor="let turn of nextTurns" class="turn">

                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="order" [attr.data-text]="turn.ord"></span>
                            </div>
                            <div class="d-flex flex-column w-100">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    <!--div class="lateral">
        <div style="margin:15px 0;"><h3>Acompanhe o <br>evento ao vivo</h3>
            <h2 style="font-size:3em;margin-top:30px;">Acesse</h2>
            www.equsperformance.com.br</div>
        <div><img src="../../../assets/images/qrcode.jpeg" style="width:85%;"></div>
    </div-->
</div>
