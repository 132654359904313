import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryModel } from 'src/app/models/category.model';
import { EventModel } from 'src/app/models/event.model';
import { TurnModel } from 'src/app/models/turn.model';
import { AwsService } from 'src/app/services/aws.service';
import { Ranking } from '../ranking.component';
import domtoimage from 'dom-to-image';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar'
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-ranking-share-img',
  templateUrl: './ranking-share-img.component.html',
  styleUrls: ['./ranking-share-img.component.scss']
})
export class RankingShareImgComponent implements OnInit {
  logoHeaderFile = "assets/images/"+environment.logoHeaderFile;
  rankings:any[] = [];
  event_id:string;
  category_id:string;
  type:string;

  ranking_images:any[] = [];

  categories:CategoryModel[] = [];
  events:any[] = [];

  selectedRanking:Ranking = new Ranking();
  selectedEvent:EventModel = new EventModel();
  selectedCategory:CategoryModel = new CategoryModel();
  eventName:string="";
  patrocinadores:string[] = [];

  ranking=null;

  constructor(
    private route: ActivatedRoute,
    private awsService:AwsService,
    private _snackBar:MatSnackBar,
    private translate: TranslateService,
  ) {
    this.event_id = this.route.snapshot.queryParamMap.get("e")
    this.category_id = this.route.snapshot.queryParamMap.get("c")
    this.type = this.route.snapshot.queryParamMap.get("r")
  }

  ngOnInit() {
    this.downloadRankings(this.category_id, this.event_id, this.type);
    setTimeout(() => {this.removeChat();},3000);
  }

  removeChat(){
    let r = document.getElementById('LeadboosterContainer');
    if(r != null) r.parentElement.removeChild(r);
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  downloadRankings(category, event,type){
    this.awsService.getRanking(event, category,type).then((rankings:any[]) => {
      let turns:TurnModel[] = [];
      if(rankings != null && Array.isArray(rankings)){
        for(let ranking of rankings){
          if(ranking.ranking != null){
            turns = ranking.ranking.map(turn => Object.assign(new TurnModel(), turn));
            ranking.data = turns;
          }
        }
        if(rankings.length && rankings[0]["url_mkt"] != null){
          this.patrocinadores = rankings[0]["url_mkt"].filter(img => {
            return (img != null && img.trim() != "");
          });
        }
        this.rankings = rankings;

        setTimeout(() => {
          let generate = (index = 0) => {
            this.ranking = null;
            if(this.rankings.length > index)
              this.generateImage(this.rankings[index]).then(() => generate(index+1));
          }
          generate();
        },500);
      }
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      console.log("loading end");
    });
  }

  copyInstagram(instagram){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = instagram;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar(this.translate.instant('ranking_share_img.instagram_copied_message'), 'success');
  }

  public openSnackBar(message: string, type: string) {
    let config: MatSnackBarConfig = {
      panelClass:'snack_' + type,
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    };
    this._snackBar.open(message, 'Ok', config);
  }

  getLabel(id){
    return (id != null) ?
        this.translate.instant('ranking_share_img.ranking_label') :
        this.translate.instant('ranking_share_img.split_general_label')
  }


  public generateImage(ranking){

    return new Promise((resolve,reject) => {
      this.ranking = ranking;
      this.patrocinadores = (ranking.url_mkt != null)?ranking.url_mkt.filter(patrocinador => patrocinador != null && patrocinador.trim() != ""):[];
      var element = document.getElementById('img-model');
      let list:HTMLElement = element.querySelector("#rank");
      list.style.fontSize="0.8em";

      element.querySelector(".cont").innerHTML = "";
      element.querySelector(".cont").append(list);
      element.querySelector("[subtitle]").innerHTML = ranking.subtitulo;

      setTimeout(()=>{
        domtoimage.toPng(element).then((dataUrl) => {
          var img = document.createElement("img");
          img.src = dataUrl;
          let instagrams = [];
          let ins = this.ranking.data.map(turn => turn.instagram).filter(instagram => instagram != null && instagram.trim() != "");

          for(let i of ins){
            if(!instagrams.includes(i))
              instagrams.push(i);
          }

          instagrams = instagrams.join(",").split(",");

          this.ranking_images.push({src:dataUrl,instagrams: instagrams});
          return resolve(null);
        }).catch((error) => {
          return reject(error);
        });
      },1000);
    });

  }


  public getPatrocinadoresWidth(){
    return (500/((this.patrocinadores.length >= 4)?this.patrocinadores.length:4));
  }

  public convertBase64ToFile(image){
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/png',
    });
    return newBlob;
  };



}
