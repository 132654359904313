import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CompareComponent } from './pages/compare/compare.component';
import { EventsComponent } from './pages/events/events.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { LiveComponent } from './pages/live/live.component';
import { TvComponent } from './pages/tv/tv.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';

import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { TransmissaoComponent } from './pages/transmissao/transmissao.component';
import { Transmissao1Component } from './pages/transmissao1/transmissao1.component';
import { Transmissao2Component } from './pages/transmissao2/transmissao2.component';
import { RankingShareComponent } from './pages/ranking-share/ranking-share.component';
import { TotenComponent } from './pages/toten/toten.component';
import { AnalysisShareComponent } from './pages/analysis/analysis-share/analysis-share.component';
import { InsightComponent } from './pages/insight/insight.component';
import { RankingShareImgComponent } from './pages/ranking/ranking-share-img/ranking-share-img.component';
import { AnalysisPreconfigComponent } from './pages/analysis/analysis-preconfig/analysis-preconfig.component';
import { Transmissao3Component } from './pages/transmissao3/transmissao3.component';
import {TransmissaoBestTvAndRFComponent} from "./pages/transmissao-best-tv-and-rf/transmissao-best-tv-and-rf.component";
import {BuyAnalysisComponent} from "./pages/buy-analysis/buy-analysis.component";
import {PaymentComponent} from "./pages/payment/payment.component";
import {PurchasedAnalysisComponent} from "./pages/purchased-analysis/purchased-analysis.component";
import {HowToWorksComponent} from "./pages/how-to-works/how-to-works.component";
import {RedirectToByComponent} from "./pages/buy-analysis/redirect-to-by/redirect-to-by.component";
import {CompetitorCategoryAnalysisComponent} from "./pages/buy-analysis/competitor-category-analysis/competitor-category-analysis.component";
import {
  CompetitorEventAnalysisComponent
} from "./pages/buy-analysis/competitor-event-analysis/competitor-event-analysis.component";
import {LiveUpperComponent} from "./pages/live-upper/live-upper.component";
import {BuySpecialReportsComponent} from "./pages/buy-special-reports/buy-special-reports.component";
import {SpecialReportsComponent} from "./pages/special-reports/special-reports.component";
import {BuyComponent} from "./pages/buy/buy.component";


const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path:'how-to',
    component: HowToWorksComponent
  },
  {
    path: 'analysis',
    component: AnalysisComponent,
    canActivate: [AuthGuard]
  },{
    path: 'analysis/preconfig',
    component: AnalysisPreconfigComponent,
    canActivate: [AuthGuard]
  },{
    path: 'analysis-share',
    component: AnalysisShareComponent
  },{
    path: 'special-report',
    component: SpecialReportsComponent
  },{
    path: 'events/analysis',
    component: EventsComponent,
    canActivate: [AuthGuard]
  },{
    path: 'events',
    component: EventsComponent,
  },
  {
    path: 'home/:event',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },{
    path: 'buy/:event',
    component: BuyComponent
  },
  {
    path: 'buy-analysis/:event',
    component: BuyAnalysisComponent
  },{
    path: 'buy-special-reports/:event',
    component: BuySpecialReportsComponent
  },
  {
    path:'purchased-analysis',
    component: PurchasedAnalysisComponent
  },
  {
    path: 'categories/:event',
    component: CategoriesComponent
    //canActivate: [AuthGuard]
  },{
    path:'rb/:category/:seq',
    component: RedirectToByComponent
  },
  {
    path:'ca/:category/:competitor',
    component: CompetitorCategoryAnalysisComponent
  },
  {
    path:'ev/:event/:competitor',
    component: CompetitorEventAnalysisComponent
  },
  {
    path: 'compare/:event/:category/:order/:pas',
    component: CompareComponent,
    canActivate: [AuthGuard]
  },{
    path: 'live',
    component: LiveComponent
  },{
    path: 'live2',
    component: LiveUpperComponent
  },{
    path: 'totem',
    component: TotenComponent
  },{
    path: 'tv',
    component: TvComponent
  },{
    path: 'transmissao',
    component: TransmissaoComponent
  },{
    path: 'transmissao1',
    component: Transmissao1Component
  },{
    path: 'transmissao2',
    component: Transmissao2Component
  },{
    path: 'transmissao3',
    component: Transmissao3Component
  },
  {
    path:'transmissao-best-tv-and-rf',
    component: TransmissaoBestTvAndRFComponent
  },
  {
    path: 'ranking-share',
    component: RankingShareComponent
  },{
    path: 'ranking-share-image',
    component: RankingShareImgComponent
  },{
    path: 'ranking',
    component: RankingComponent,
    canActivate: [AuthGuard]
  },{
    path: 'register',
    component: RegisterComponent
  },{
    path: 'login',
    component: LoginComponent
  },{
    path: 'insight',
    component: InsightComponent
  },{
    path: 'admin',
   // loadChildren: './pages/admin/admin.module#AdminModule'
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
