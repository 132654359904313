import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AwsService} from "../../services/aws.service";
import {LocalStorageService} from "ngx-webstorage";

@Component({
  selector: 'app-purchased-analysis',
  templateUrl: './purchased-analysis.component.html',
  styleUrls: ['./purchased-analysis.component.scss']
})
export class PurchasedAnalysisComponent {

    purchasedRedirectTo:string = '';
constructor(
    private translate: TranslateService,
    private router: Router,
    private localStorage:LocalStorageService,
    ){
        this.purchasedRedirectTo =  this.localStorage.retrieve('purchasedRedirectTo');
        console.log(this.purchasedRedirectTo);
    }

    byMore(){

        if (this.purchasedRedirectTo){
            this.localStorage.clear('purchasedRedirectTo');
            this.router.navigateByUrl(this.purchasedRedirectTo);
        }else {
            const eventId = this.localStorage.retrieve('eventId');
            if (!eventId) {
                this.router.navigateByUrl('');
            } else {

                const monetizedAnalyze = this.localStorage.retrieve('monetizedAnalyze');
                let categoria = '';
                let evento = '';
                if (monetizedAnalyze && monetizedAnalyze.principal) {
                    categoria = monetizedAnalyze.principal.categoria;
                    evento = monetizedAnalyze.principal.evento;
                }
                this.localStorage.store("selectedCategory", categoria);
                this.localStorage.store("selectedEvent", evento);
                this.router.navigateByUrl('buy-analysis/' + eventId);
            }
        }
    }
    backToHome(){
        this.router.navigateByUrl('');
    }

}
