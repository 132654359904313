<div id="page-content">
    <div class="container-fluid p-3 " style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));">
        <div class="row">
            <div class="col-12 d-flex flex-row align-items-center">
                <img routerLink="/" style="height:52px;" class="mr-5 cursor-pointer" src="{{logoHeaderFile}}" alt="">
                <h3 class="text-white" style="text-transform: uppercase;">{{'ranking_share_img.ranking_label' | translate}}</h3>
            </div>
        </div>
    </div>
    <div *ngIf="patrocinadores.length" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
        <div *ngFor="let patrocinador of patrocinadores" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth()+'px'}">
            <img [src]="patrocinador+ '?not-from-cache'" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
        </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 px-3 scrollbar" style="overflow: auto;">
        <div id="rankings" class="row" *ngIf="rankings && rankings.length">

            <div #rank class="col-12 col-lg-6 mt-3 item_rank" [ngClass]="(rankings.length)?'mx-auto':''" *ngFor="let ranking of rankings">
                <div class="d-flex flex-row justify-content-between">
                    <h3 class="title text-white d-inline-flex">{{ranking.subtitulo}}</h3>
                </div>
                <div class="d-flex flex-column p-2 mb-1 text-white" *ngIf="(ranking.data.length>0 && category_id != null)" style="background: rgba(0, 0, 0, 0.8);">
                    <div style="text-transform: uppercase;font-size: 1.3em; font-weight: bold;">{{ranking.data[0].evento}}</div><div style="font-size: 1.4em;">{{ranking.data[0].categoria}}</div>
                </div>
                <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                    <div class="turn d-flex flex-column" *ngFor="let turn of ranking.data"  [ngStyle]="(category_id != null)?{'font-size': '1.1em','padding': '15px 8px'}:{}">
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column" style="overflow:hidden;">
                                <span class="horse">
                                    <img src="/assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="/assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>
                            <div class="d-flex flex-row align-items-center ml-auto">
                                <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1" style="font-size: 0.8em;"></i>{{toFixed(turn.tempo,3)}}</span>
                                <span class="px-2 placing">{{(turn != null && turn.pos_txt != null) ? turn.pos_txt :  turn.pos+"º"}}</span>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-between pt-2 text-white" *ngIf="(category_id == null)">
                            <span style="text-transform: uppercase;">{{turn.evento}}</span><span class="px-2">{{turn.categoria}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
