import {BaseModel} from './base.model';

export class TurnModel extends BaseModel {
    constructor(
        public seq?:number,
        public tr?:number,
        public tv?:number,
        public r1?:number,
        public r2?:number,
        public r3?:number,
        public rf?:number,
        public v1?:number,
        public v2?:number,
        public v3?:number,
        public T1?:number,
        public T2?:number,
        public T3?:number,
        public rv1?:number,
        public rv2?:number,
        public rv3?:number,
        public rvr1?:number,
        public rvr2?:number,
        public rvr3?:number,
        public rvrt?:number,
        public pas?:number,
        public tot_parciais?:number,
        public tot_rvr?:number,
        public tot_rv?:number,
        public tot_trtv?:number,
        public d?:string,
        public ord?:number,
        public type?:number,
        public total?:number,
        public cavalo?:string,
        public placing?:number,
        public categoria?:string,
        public competidor?:string,
        public id_cavalo?:number,
        public id_competidor?:number,
        public id_categoria?:number,
        public id_evento?:number,
        public evento?:string,
        public best?:any,

        public sat?:boolean,
        public nc?:boolean,
        public pos?:number,
        public pos_txt?: number,
        public bestTotal?:number,
        public posd?:number,
        public instagram?:string,

        public selected?:boolean,

        public email?:string,
        public emailcavalo?:string,
        public phone?:string,
        public phonecavalo?:string
    ){ super(); }

    static fromJson(data:any) : TurnModel {
        return Object.assign(new TurnModel(),data);
    }
}
