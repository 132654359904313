<div>
    <h1 mat-dialog-title style="float: left">RANKING - INSTAGRAM</h1>
    <span style="float: right" [mat-dialog-close]>X</span>
</div>

<div mat-dialog-content  style="clear: both">
     <div style="display: flex; flex-wrap: wrap; padding-top: 3px">
       <span *ngFor="let i of instagrams" class="mr-3 insta"  (click)="copyInstagram(i)">{{i}}</span>
     </div>
    <img *ngIf="image!=null" style="max-width: 100%;margin: 5px 0; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);" [src]="image" alt="">

</div>
