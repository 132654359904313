import {Component} from '@angular/core';
import {environment} from "../../../environments/environment";
import {TurnModel} from "../../models/turn.model";
import {BrokerService} from "../../services/broker.service";
import {FlespiService} from "../../services/flespi.service";
import {Location} from "@angular/common";
import {AwsService} from "../../services/aws.service";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-live-upper',
    templateUrl: './live-upper.component.html',
    styleUrls: ['./live-upper.component.scss']
})
export class LiveUpperComponent {
    logoHeaderFile = "../../../assets/images/" + environment.logoHeaderFile;
    id_evento: number;
    evento: string = '';
    categoria: string = '';
    currentTurn: TurnModel = new TurnModel();
    bestTurn: TurnModel = new TurnModel();
    nextTurns: TurnModel[] = [];
    sample_data: any = {};
    height: number;
    video: string = "";
    video_type: string = "youtube";
    top10: TurnModel[] = [];
    farol: any = {
        'acc': 0.0,
        'T1': null,
        'T2': null,
        'T3': null,
        'rf': null,
        'acc_color': '#fff',
        'img': '../../../assets/images/banners/' + environment.logoTransmissao
    };
    patrocinadores: string[] = [];

    constructor(private brokerService: BrokerService,
                private flespiService: FlespiService,
                private location: Location,
                private awsService: AwsService,
                private route: ActivatedRoute,
                private translate: TranslateService,
    ) {
        this.id_evento = Number(this.route.snapshot.queryParamMap.get("id_evento"));
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.removeChat();
        }, 3000);
    }

    removeChat() {
        let r = document.getElementById('LeadboosterContainer');
        if (r != null) r.parentElement.removeChild(r);
    }

    ngOnInit() {
        this.getVideo();

        this.brokerService.startForComponent('live', () => {
            this.brokerService.subscribe('equslive_' + this.id_evento, 2, (message) => {
                this.onNewData(message);
            });
        });
        this.brokerService.startForComponent('transmissao', () => {
            this.brokerService.subscribe('transmissao_' + this.id_evento, 2, (message) => {
                this.onNewTransmissionData(message);
            });
        });

        this.downloadLastMessage();

        this.sample_data = {
            "evento": "Copa 3DM - Potro Brasil 2021",
            "categoria": "Potro do Futuro - FINAL PRATA",
            "current": {
                "seq": 35,
                "pas": 1,
                "ord": 35,
                "competidor": "PAULO SERGIO PEREIRA DE ARAUJO",
                "cavalo": "JUNIOR PRIMETARY HR",
                "nc": 0,
                "T1": 0,
                "T2": 0,
                "T3": 1,
                "sat": 0,
                "r1": 2.099,
                "v1": 1.845,
                "r2": 2.912,
                "v2": 2.186,
                "r3": 3.384,
                "v3": 1.709,
                "rf": 4.117,
                "total": 18.252,
                "tr": 12.512,
                "tv": 5.74,
                "rvr1": 6.856,
                "rvr2": 8.482,
                "rvr3": 9.21,
                "rv1": 3.944,
                "rv2": 5.098,
                "rv3": 5.093,
                "del": 0,
                "pen": 5,
                "d": "3D",
                "pos": 15,
                "posd": 4,
                "class": null
            },
            "best": {
                "seq": 22,
                "pas": 1,
                "ord": 22,
                "competidor": "FERNANDO HENRIQUE MORALES",
                "cavalo": "WV SARAH CORONA",
                "nc": 0,
                "T1": 0,
                "T2": 0,
                "T3": 0,
                "sat": 0,
                "r1": 1.816,
                "v1": 1.705,
                "r2": 2.756,
                "v2": 1.98,
                "r3": 3.223,
                "v3": 1.603,
                "rf": 4.016,
                "total": 17.099,
                "tr": 11.811,
                "tv": 5.288,
                "rvr1": 6.277,
                "rvr2": 7.959,
                "rvr3": 8.842,
                "rv1": 3.521,
                "rv2": 4.736,
                "rv3": 4.826,
                "del": 0,
                "pen": 0,
                "d": "1D",
                "pos": 1,
                "posd": 1,
                "class": null
            },
            "next": [{"ord": 36, "cavalo": "JUNIOR TARI KEYS HR", "competidor": "LUCAS LENCIONE TUAO"}, {
                "ord": 37,
                "cavalo": "JET FAME ZZ",
                "competidor": "AGRINALDO SILVA MATOS"
            }, {
                "ord": 38,
                "cavalo": "THE ULTIMATE FAST",
                "competidor": "MARCOS APARECIDO MONZINHO OLIVEIRA"
            }, {"ord": 39, "cavalo": "IMP PERRY ZORRERA", "competidor": "LINDOMAR ALVES BONFIM"}, {
                "ord": 40,
                "cavalo": "SHADY FIRST ZORRERO",
                "competidor": "DECIO GASPAR TALON"
            }, {"ord": 41, "cavalo": "BAHIA DASH", "competidor": "PATRICK DE CASTRO JOSE"}, {
                "ord": 42,
                "cavalo": "TIGER FAME FNSL",
                "competidor": "NIVALDO ALMEIDA DA SILVA"
            }, {"ord": 43, "cavalo": "IMP SILK N TIMES", "competidor": "NIVALDO ALMEIDA DA SILVA"}, {
                "ord": 44,
                "cavalo": "TACK PERKS HRA",
                "competidor": "LUCIANO SILVA DE SOUZA"
            }, {"ord": 45, "cavalo": "3D OSKLEN FAME", "competidor": "NIVALDO ALMEIDA DA SILVA"}],
            "top10": [{
                "pos": 1,
                "total": 17.099,
                "cavalo": "WV SARAH CORONA",
                "competidor": "FERNANDO HENRIQUE MORALES"
            }, {
                "pos": 2,
                "total": 17.229,
                "cavalo": "MY SUGARGIRL NHP",
                "competidor": "RAFAEL SANCHES NEVES"
            }, {
                "pos": 3,
                "total": 17.379,
                "cavalo": "FIESTA N DASH 4M",
                "competidor": "LEANDRO RAMOS DE SOUZA SANTOS"
            }, {
                "pos": 4,
                "total": 17.461,
                "cavalo": "VITORIA BRYAN RIL",
                "competidor": "RAFAEL JOSE DE BRITO VINAGRE"
            }, {"pos": 5, "total": 17.576, "cavalo": "SIXIM JAY", "competidor": "VINICIUS FRAGA MORAIS"}, {
                "pos": 6,
                "total": 17.625,
                "cavalo": "ST NIKNUK",
                "competidor": "ERISON KLEBER MONTEIRO DE SIQUEIRA"
            }, {"pos": 7, "total": 17.764, "cavalo": "IANK FAME HNSA", "competidor": "JOSE JUNIOR FILHO"}, {
                "pos": 8,
                "total": 17.815,
                "cavalo": "JUSTUS OF MOON HR",
                "competidor": "PAULO VITOR MENDES DE ARAUJO"
            }, {
                "pos": 9,
                "total": 17.831,
                "cavalo": "ZECA FAME EK",
                "competidor": "KEILA SUELEN APARECIDA DE MENDONCA"
            }, {"pos": 10, "total": 17.89, "cavalo": "GLADSTONE FAME", "competidor": "VINICIUS FRAGA MORAIS"}]
        };
    }

    getVideo() {
        this.awsService.getVideo({id_evento: this.id_evento}).then((response) => {

            this.video_type = response["data"]["source"];
            let video = response["data"]["url"];
            if (this.video_type == "twitch")
                video = `https://player.twitch.tv/?channel=${response["data"]["url"]}&parent=${window.location.hostname}&autoplay=1&vol=0&title=0&byline=0&portrait=0`;
            else if (video != null && video.trim())
                video = video + "?autoplay=1&loop=1&playlist=L9GoXHF66G0&mute=1";
            this.video = video;
        });
    }

    onNewTransmissionData(message) {
        this.farol = message;

        // Mostra a diferença a cada passada
        this.currentTurn.r1 = message['current']['r1']
        this.currentTurn.r2 = message['current']['r2']
        this.currentTurn.r3 = message['current']['r3']
        this.currentTurn.v1 = message['current']['v1']
        this.currentTurn.v2 = message['current']['v2']
        this.currentTurn.v3 = message['current']['v3']
        this.currentTurn.rf = message['current']['rf']
        this.currentTurn.total = message['current']['total']
        this.currentTurn = this.adjustTurn(this.currentTurn)
    }

    downloadLastMessage() {
        this.flespiService.getLastLiveMessage().then(message => {
            this.onNewData(message);
        }).catch(error => {
            console.log(error);
        }).finally(() => {

        })
    }

    onNewData(message) {
        //message = this.sample_data;
        this.removeChat();
        if (message["evento"] != null) this.evento = message['evento'];
        if (message["categoria"] != null) this.categoria = message['categoria'];

        if (message["best"] != null) this.bestTurn = this.adjustTurn(Object.assign(new TurnModel(), message["best"]));
        this.currentTurn = this.adjustTurn(Object.assign(new TurnModel(), message["current"]));
        if (message["next"] != null) {
            this.nextTurns = message["next"].map(turn => {
                return this.adjustTurn(Object.assign(new TurnModel(), turn));
            });
        }
        if (message["top10_logo"] != null) this.patrocinadores = message["top10_logo"];

        if (message["top10"] != null) this.top10 = message["top10"].map(turn => this.adjustTurn(Object.assign(new TurnModel(), turn)));
    }

    adjustTurn(turn) {
        turn.v1 = (turn.v1 != null) ? parseFloat(turn.v1).toFixed(3) : null;
        turn.v2 = (turn.v2 != null) ? parseFloat(turn.v2).toFixed(3) : null;
        turn.v3 = (turn.v3 != null) ? parseFloat(turn.v3).toFixed(3) : null;
        turn.r1 = (turn.r1 != null) ? parseFloat(turn.r1).toFixed(3) : null;
        turn.r2 = (turn.r2 != null) ? parseFloat(turn.r2).toFixed(3) : null;
        turn.r3 = (turn.r3 != null) ? parseFloat(turn.r3).toFixed(3) : null;
        turn.rf = (turn.rf != null) ? parseFloat(turn.rf).toFixed(3) : null;
        turn.tr = (turn.tr != null) ? parseFloat(turn.tr).toFixed(3) : null;
        turn.tv = (turn.tv != null) ? parseFloat(turn.tv).toFixed(3) : null;
        turn.rv1 = (turn.rv1 != null) ? parseFloat(turn.rv1).toFixed(3) : null;
        turn.rv2 = (turn.rv2 != null) ? parseFloat(turn.rv2).toFixed(3) : null;
        turn.rv3 = (turn.rv3 != null) ? parseFloat(turn.rv3).toFixed(3) : null;
        turn.rvr1 = (turn.rvr1 != null) ? parseFloat(turn.rvr1).toFixed(3) : null;
        turn.rvr2 = (turn.rvr2 != null) ? parseFloat(turn.rvr2).toFixed(3) : null;
        turn.rvr3 = (turn.rvr3 != null) ? parseFloat(turn.rvr3).toFixed(3) : null;

        turn.rvrt = (turn.rvr1 != null && turn.rvr2 != null && turn.rvr3 != null) ? (turn.rvr1 + turn.rvr2 + turn.rvr3) : null;

        if (turn.total != null && !isNaN(turn.total)) {
            turn.total = (turn.total != null) ? parseFloat(turn.total).toFixed(3) : null;
        } else {
            turn.total = "--";
        }

        turn.sat = turn.sat != true ? false : true;
        turn.nc = turn.nc != true ? false : true;
        turn.pos_txt = (turn.pos_txt != null) ? turn.pos_txt : turn.pos + "º";

        return turn;
    }

    public getDif(field) {
        let label = '--';
        if (!isNaN(this.bestTurn[field]) != null && this.bestTurn[field] != null && this.currentTurn[field] != null && !isNaN(this.currentTurn[field])) {
            let dif = this.currentTurn[field] - this.bestTurn[field];
            label = (dif == 0) ? '--' : (dif > 0 ? '+' : '') + (dif).toFixed(3);
        }
        return label;
    }

    public getClassColor(field) {
        let cls = '';
        if (this.currentTurn[field] != null && !isNaN(this.currentTurn[field]) && !isNaN(this.bestTurn[field]) != null && this.bestTurn[field] != null) {
            let dif = this.currentTurn[field] - this.bestTurn[field];
            cls = (dif == 0) ? '' : (dif > 0 ? 'diferences-pos' : 'diferences-neg');
        }
        return cls;
    }

    goBack() {
        this.location.back();
    }

}
