import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss']
})
export class CookieNoticeComponent {
  @Output() buttonClicked = new EventEmitter<boolean>();



  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  closeCookieNotice(result) {
    this.buttonClicked.emit(result);
  }

}
