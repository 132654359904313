import {Component} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AwsService} from "../../services/aws.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TurnModel} from "../../models/turn.model";

@Component({
    selector: 'app-special-reports',
    templateUrl: './special-reports.component.html',
    styleUrls: ['./special-reports.component.scss']
})
export class SpecialReportsComponent {
    logoHeaderFile = 'assets/images/' + environment.logoHeaderFile;
    uuid: string = null;
    categoria_id:number = null;
    report_type_field:string= '';
    report_type_label:string = "";
    principal: TurnModel = null;
    top_5_data: TurnModel[] = [];
    loading:boolean = false;

    constructor(
        private awsService: AwsService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private router: Router,
    ) {
        this.uuid = this.route.snapshot.queryParamMap.get("uuid");
    }

    ngOnInit() {
        if (this.uuid)
            this.downloadAnalysis();
    }

    private downloadAnalysis() {


        const params = {};
        if (this.uuid) {
            params['uuid'] = this.uuid;
        }
        this.loading = true;
        this.awsService.getAnalysis(params).then((result) => {
            const analysis = JSON.parse(result.data.obj);
            console.log('this.analysis ->',analysis);
            this.categoria_id = analysis && analysis['categoria_id'] ? analysis['categoria_id']: null;
            this.report_type_field = analysis && analysis['report_type_field'] ? analysis['report_type_field']: null;
            this.report_type_label = analysis && analysis['report_type_label'] ? analysis['report_type_label']: null;
            this.principal = analysis && analysis['principal'] ? analysis['principal']: null;
            this.top_5_data = analysis && analysis['top_5_data'] ? analysis['top_5_data']: [];
        }).catch(error => {
            console.log(error);
        }).finally(()=>this.loading=false);
    }

    getTurnPos(turn){
        if (turn && turn.pos_txt)
            return turn.pos_txt
        if (turn && turn.pos)
            return turn.pos+"º"
        return '--';
    }

    public toFixed(value:number, places:number):string{
        if(!isNaN(value))
            return parseFloat(value.toString()).toFixed(places);
        return '--';
    }

}
