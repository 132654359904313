<div id="page-content">
    <div class="container-fluid flex-grow-1 scrollbar" style="overflow: auto;">
        <div class="row w-100 mx-0">
            <div class="col-12 col-md-6 col-lg-6 col-xl-4 mx-auto my-5">

                <div class="box">
                    <h3 class="heading">{{'register.register_label' | translate}}</h3>
                    <p class="mb-2 pb-3" style="border-bottom: 1px solid #ffffff22;">{{'register.fill_fields_message' | translate}}:</p>

                    <form [formGroup]="form" (submit)="register()" >
                        <div class="row">
                            <div class="col-md-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'register.field_name_label' | translate}}</label>
                                <input formControlName="name" (input)="getError('name')" type="text" class="form-control" id="name">
                                <span class="error">{{errorMessages["name"]}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group mb-1">
                                <label for="name" class="col-form-label">{{'register.field_email_label' | translate}}</label>
                                <input formControlName="email" (input)="getError('email')" (blur)="verifyEmail($event)" type="email" class="form-control" id="email">
                                <span class="error">{{errorMessages["email"]}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group mb-1">
                                <label for="password" class="col-form-label">{{'register.field_new_password' | translate}}</label>
                                <input formControlName="password" (input)="getError('password')" type="password" class="form-control" id="password">
                                <span class="error">{{errorMessages["password"]}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group mb-1">
                                <label for="confirm" class="col-form-label">{{'register.field_confirm_password' | translate}}</label>
                                <input formControlName="confirm" (input)="getError('confirm')" type="password" class="form-control" id="confirm">
                                <span class="error">{{errorMessages["confirm"]}}</span>
                            </div>
                        </div>
                        <div class="d-flex flex-md-row flex-wrap" style="margin-left: -15px;margin-right: -15px;">
                            <div class="col-12 mx-auto pt-3">
                                <button type="submit" [attr.disabled]="(form.invalid)?true:null" class="w-100 py-2 px-4">{{'register.register_label' | translate}}</button>
                            </div>
                            <div class="col-12 py-3">
                                {{'register.already_have_account_label' | translate}}? <span class="cursor-pointer" style="color:#e2c87d;" routerLink="/login">{{'register.login_label' | translate}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
