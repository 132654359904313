import {Component} from '@angular/core';
import {CategoryModel} from "../../models/category.model";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {AwsService} from "../../services/aws.service";
import {LocalStorageService} from "ngx-webstorage";
import {TranslateService} from "@ngx-translate/core";
// @ts-ignore
import moment from 'moment';

@Component({
    selector: 'app-buy',
    templateUrl: './buy.component.html',
    styleUrls: ['./buy.component.scss']
})
export class BuyComponent {
    public categories: CategoryModel[] = [];
    public eventId: number = null;
    public eventName: string = "";
    public filteredCategories: any[] = [];
    public live: CategoryModel = null;
    public dateCategoryList: { date: string, categories: CategoryModel[] } [] = [];

    public monetize: boolean = false;

    public brasil = environment.language == 'pt-br';

    // step 1 - Purchase Analysis | Purchase Top 5 Per Segments
    // step 2 -                   | Choose Segments
    // step 3 -                   | Per Events | Per Class
    // step 4 - Choose Class
    step: number = 1;
    step1Choice: number = null;
    step2Choice: number = null;
    step3Choice: number = null;
    step4Choice: number = null;

    top5Types: { id: number, key: string, keylang: string }[] = [];

    value_stripe_special_report_by_event: number = 0;
    value_stripe_special_report_by_class: number = 0;
    value_stripe_full_report_by_event: number = 0;
    value_stripe_full_report_by_class: number = 0;


    constructor(
        private router: Router,
        private awsService: AwsService,
        private route: ActivatedRoute,
        private localStorage: LocalStorageService,
        private translate: TranslateService,
    ) {
        if (this.route.snapshot.paramMap.has("event")) {
            this.eventId = Number(this.route.snapshot.paramMap.get("event"));
            this.localStorage.store("eventId", this.eventId);
        }
        this.value_stripe_special_report_by_event = environment.value_stripe_special_report_by_event;
        this.value_stripe_special_report_by_class = environment.value_stripe_special_report_by_class;
        this.value_stripe_full_report_by_event = environment.value_stripe_full_report_by_event;
        this.value_stripe_full_report_by_class = environment.value_stripe_full_report_by_class;
    }

    // step 1 - Purchase Analysis | Purchase Top 5 Per Segments
    // step 2 -                   | Choose Segments
    // step 3 -                   | Per Events | Per Class
    // step 4 - Choose Class
    setChoice(step: number, step1Choice: number, step2Choice: number, step3Choice: number, step4Choice: number) {
        this.step = step;
        this.step1Choice = step1Choice;
        this.step2Choice = step2Choice;
        this.step3Choice = step3Choice;
        this.step4Choice = step4Choice;
    }

    ngOnInit() {
        this.top5Types = [
            {id: 0, key: "r1", keylang: "by_special_reports.label_1st_straight"},
            {id: 1, key: "v1", keylang: "by_special_reports.label_1st_turn"},
            {id: 2, key: "r2", keylang: "by_special_reports.label_2nd_straight"},
            {id: 3, key: "v2", keylang: "by_special_reports.label_2nd_turn"},
            {id: 4, key: "r3", keylang: "by_special_reports.label_3rd_straight"},
            {id: 5, key: "v3", keylang: "by_special_reports.label_3rd_turn"},
            {id: 6, key: "rf", keylang: "by_special_reports.label_final_straight"},
            {id: 7, key: "rv1", keylang: "by_special_reports.label_1st_straight_turn"},
            {id: 8, key: "rv2", keylang: "by_special_reports.label_2nd_straight_turn"},
            {id: 9, key: "rv3", keylang: "by_special_reports.label_3rd_straight_turn"},
            {id: 10, key: "all", keylang: "by_special_reports.label_full_report"},
        ]
        this.downloadCategories();
    }

    getTotalValueByEvent(): number {
        let price = 0;
        if (this.step2Choice<10){
            price = this.value_stripe_special_report_by_event;
        }else{
            price = this.value_stripe_full_report_by_event;
        }


        return price;
    }

    getTotalValueByClass(): number {
        let price = 0;
        if (this.step2Choice<10){
            price = this.value_stripe_special_report_by_class;
        }else{
            price = this.value_stripe_full_report_by_class;
        }


        return price;
    }

    downloadCategories() {
        let params = {
            id_evento: this.eventId,
            best: true,
            stat: true
        };
        this.awsService.getCategories(params).then(categories => {
            if (categories.length > 0) {
                this.eventName = categories[0].evento;
                this.monetize = categories[0].monetize;
            }

            categories.sort((a, b) => {
                if (moment(a['data']).unix() > moment(b['data']).unix()) {
                    return 1;
                } else if (moment(a['data']).unix() < moment(b['data']).unix()) {
                    return -1;
                }
                return 0;
            }).map((category: any) => {
                category.data = moment(category.data).format(environment.format_date || "MM/DD/YYYY");
                if (category["best"] != null) {
                    category["best"]["evento"] = category["evento"];
                    category["best"]["categoria"] = category["categoria"];
                }
                return category;
            });

            if (categories.length == 0) {
                this.router.navigateByUrl('home/' + this.eventId, {skipLocationChange: true});
            } else {
                this.categories = categories;
                this.filteredCategories = this.categories;
                this.organizeByDate();
                if (this.categories[0].value_stripe_special_report_by_evento) {
                    this.value_stripe_special_report_by_event = this.categories[0].value_stripe_special_report_by_evento;
                }
                if (this.categories[0].value_stripe_special_report_by_categoria) {
                    this.value_stripe_special_report_by_class = this.categories[0].value_stripe_special_report_by_categoria;
                }
                if (this.categories[0].value_stripe_full_report_by_evento) {
                    this.value_stripe_full_report_by_event = this.categories[0].value_stripe_full_report_by_evento;
                }
                if (this.categories[0].value_stripe_full_report_by_categoria) {
                    this.value_stripe_full_report_by_class = this.categories[0].value_stripe_full_report_by_categoria;
                }
            }
        }).catch(() => {
            this.router.navigateByUrl('home/' + this.eventId, {skipLocationChange: true});
        });
    }

    organizeByDate() {

        let dateObj = {};
        this.dateCategoryList = [];
        this.live = null;

        this.filteredCategories.forEach(cat => {
            if (dateObj[cat.data] == null) {
                dateObj[cat.data] = {
                    categories: [cat]
                }
            } else {
                dateObj[cat.data].categories.push(cat);
            }
            if (cat.status == 'Live') {
                this.live = cat;
            }
        });

        for (let key of Object.keys(dateObj)) {

            let catDate = {
                date: key,
                categories: []
            };

            catDate.categories = dateObj[key].categories.sort((a, b) => {
                if (moment(a['data']).unix() > moment(b['data']).unix()) {
                    return 1;
                } else if (moment(a['data']).unix() < moment(b['data']).unix()) {
                    return -1;
                }
                return 0;
            });

            this.dateCategoryList.push(catDate);
        }
    }

    filterCategories(text = "") {
        this.filteredCategories = this.categories;

        if (text.trim() != "") {
            this.filteredCategories = this.categories.filter((category: any) => {
                let name = category.categoria.toLowerCase().includes(text.toLowerCase());
                let cavalo = category.cavalo != null && category.cavalo.toLowerCase().includes(text.toLowerCase());
                let competidor = category.competidor != null && category.competidor.toLowerCase().includes(text.toLowerCase());
                return name || cavalo || competidor;
            });
        }
        this.organizeByDate();
    }


    clickCategory(cat: CategoryModel) {
        this.localStorage.store("selectedCategory", cat.categoria);
        this.localStorage.store("selectedEvent", cat.evento);
        this.localStorage.store("eventId", this.eventId);
        this.localStorage.store("segments_index", this.step2Choice);
        if (this.step1Choice == 1) {
            this.router.navigateByUrl('buy-analysis/' + this.eventId);
        }
        if (this.step1Choice == 2) {
            this.router.navigateByUrl('buy-special-reports/' + this.eventId);
        }
    }

    clickEvent() {
        this.localStorage.store("selectedEvent", this.categories[0].evento);
        this.localStorage.store("eventId", this.eventId);
        this.localStorage.clear("selectedCategory");
        this.localStorage.store("segments_index", this.step2Choice);
        //
        // if(this.step1Choice==1) {
        //     this.router.navigateByUrl('buy-analysis/' + this.eventId);
        // }
        //
        // if(this.step1Choice==2) {
        this.router.navigateByUrl('buy-special-reports/' + this.eventId);
        // }
    }

    buySpecialReports() {
        this.localStorage.store("selectedEvent", this.eventName);
        this.localStorage.store("eventId", this.eventId);
        this.router.navigateByUrl('buy-special-reports/' + this.eventId);
    }

    handleClickWhatYouGet() {
        const url = "https://youtube.com/shorts/cmp4S0u0vfs?feature=share"
        window.open(url, '_system');
    }

    handleClickHowToPurchase() {
        const url = "https://youtube.com/shorts/ud6x5eN04UU?feature=share"
        window.open(url, '_system');

    }


}
