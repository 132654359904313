<div id="page-content" class="scrollbar" style="overflow: auto;flex-direction: column;">
    <div class="d-flex flex-column">
        <div class="container-fluid">
            <div class="row mb-3">
                <div class="col-12 col-sm-4 col-lg-4 col-xl-4 pt-3 logo_title">
                    <img class="h-100 cursor-pointer" style="width: 148px;"  src="{{logoHeaderFile}}" routerLink="/events">
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-white" *ngIf="evento != null">
                    <h3 class="title">{{evento.evento}}</h3>
                </div>
            </div>
            <div class="row" *ngFor="let insight of insights; let i = index">
                <div class="col-12 mt-3 pb-3" *ngIf="insight.dados != null && insight.dados.length">
                    <h3 class="text-white title">{{insight.titulo}}</h3>
                    <table class="text-white w-100">
                        <thead style="border-bottom: 1px solid #ffffff33;">
                            <tr>
                                <th *ngFor="let field of insight.header">
                                    <span class="n">{{field.n}}</span>
                                    <span class="a">{{field.a}}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of insight.dados" style="border-bottom: 1px solid #ffffff22;">

                                <td *ngFor="let field of insight.header;let i = index;">
                                    <span [ngStyle]="getColor(row, field)">
                                        {{getValue(row, field)}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
