import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-how-to-works',
  templateUrl: './how-to-works.component.html',
  styleUrls: ['./how-to-works.component.scss']
})
export class HowToWorksComponent {
  logoHeaderFile = "assets/images/" + environment.logoHeaderFile;
  logoHomeHorseComp = "assets/images/" + environment.logoHomeHorseComp;

  whatIsVideoUrl: SafeResourceUrl = '';
  howToUseVideoUrl: SafeResourceUrl = '';

  brasil = environment.language == 'pt-br';

  constructor(
      private route: ActivatedRoute,
      private router: Router,

      private sanitizer: DomSanitizer,

      private translate: TranslateService,

  ){
    this.translate.setDefaultLang('en');
    this.translate.use(environment.language);

    this.whatIsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.whatIsVideoUrl);
    this.howToUseVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.howToUseVideoUrl);


  }

  ngAfterViewInit(): void {
    const sidebar = document.querySelector('#sidebar_landing');

    window.addEventListener('click', (e: any) => {
      if (sidebar.classList.contains('open')) {
        if (!e.path.includes(sidebar) && e.path != null && !e.path.some(element => {
          return element.classList != null && element.classList.contains('toggle-sidebar');
        })) {
          e.preventDefault();
          sidebar.classList.remove('open');
          document.querySelector('html').style.overflowY = 'auto';
        }
      }
    });

    this.scrollTo('howWorks')
  }

  scrollTo(elementSelector) {
    const element = document.querySelector(`#${elementSelector}`);
    if (element != null) {
      const sidebar = document.querySelector('#sidebar_landing');
      const active = sidebar.querySelector(`[link="${elementSelector}"]`);
      sidebar.querySelectorAll('li').forEach(element => {
        element.classList.remove('active');
      });
      active.classList.add('active');

      if (element != null) {
        const top = element.getBoundingClientRect().top - 80;
        this.scrollBy(top, 500);
      }
      sidebar.classList.remove('open');
      document.querySelector('html').style.overflowY = 'auto';
    }
  }

  scrollBy(distance, duration) {

    const scrollIni = Math.ceil(document.querySelector('.content').scrollTop);
    const difference = Math.ceil(distance - scrollIni);
    if (scrollIni != Math.abs(difference)) {
      const startTime = new Date().getTime();

      function step() {
        let percentageProggress = ((new Date().getTime() - startTime) * 100) / duration;
        if (percentageProggress > 100) {
          percentageProggress = 100;
        }
        const scroll = scrollIni + ((distance * percentageProggress) / 100);
        document.querySelector('.content').scrollTo(0, scroll);
        if (percentageProggress < 100) {
          window.requestAnimationFrame(step);
        }
      }

      window.requestAnimationFrame(step);
    }
  }

  toggleMenu() {
    const sidebar = document.querySelector('#sidebar_landing');
    const open = sidebar.classList.contains('open');
    if (open) {
      sidebar.classList.remove('open');
      document.querySelector('html').style.overflowY = 'auto';
    } else {
      sidebar.classList.add('open');
      document.querySelector('html').style.overflowY = 'hidden';
    }
  }

}
