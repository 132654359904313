import { Component, OnInit, Input } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-farol',
  templateUrl: './farol.component.html',
  styleUrls: ['./farol.component.scss']
})
export class FarolComponent implements OnInit {
  logoTransmissaoPath = '../../../assets/images/banners/'+environment.logoTransmissao;

  @Input('publicity')publicity = true;
  @Input('proportional')proportional = false;
  @Input('type')type = 'transmission';
  @Input('acc')acc = 0.0;
  @Input('t1')t1 = null;
  @Input('t2')t2 = null;
  @Input('t3')t3 = null;
  @Input('rf')rf = null;
  @Input('acc-color')acc_color = "#fff";
  @Input('img')img = '../../../assets/images/banners/'+environment.logoTransmissao;

  constructor() { }

  ngOnInit() {
  }

  public hexToHsl(hex){
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return h;
  }
}
