<header>
    <div class="container px-4">
        <div class="d-flex flex-row mx-0 align-items-center w-100 h-100">
            <img class="logo" src="{{logoHeaderFile}}" alt="">


            <ul id="sidebar_landing" class="ml-auto">
                <li>
                    <img class="logo" src="{{logoHeaderFile}}" alt="">

                </li>
                <li link="home"  [routerLink]="'/'">HOME</li>
                <li  class="active" link="howWorks" [routerLink]="'/how-to'">{{'landing.menu_how_it_works' | translate}}</li>
                <li [routerLink]="'/events'">{{'landing.menu_split_timing' | translate}}</li>
                <!--                estava sendo redicionado para /analysis/preconfig Ricardo solicitou alteração dia 14/11/2022          -->
            </ul>
            <i class="mdi mdi-menu toggle-sidebar" (click)="toggleMenu()"></i>
        </div>
    </div>
</header>
<div class="content">
    <div style="background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('assets/images/horse-background.jpg') no-repeat 30% center;background-size: cover;">


<!--        <div class="banner pt-4" id="home" style="background:linear-gradient(180deg, #222222, #151515, #070707 69%, #15151500);">-->
<!--            <div style="position:absolute;height:100%;width:100%;bottom:0px;background-image: url(assets/images/comp_bg.png);    background-size: cover;"></div>-->
<!--            <img src="{{logoHomeHorseComp}}" class="cowboy">-->
<!--        </div>-->

        <div class="container px-4">
            <div class="row pt-3 pb-4 mb-3 " id="howWorks">
                <div>
                    <h3 class="mb-4 d-flex justify-content-center" style="text-transform: uppercase;">{{'landing.menu_how_it_works' | translate}}</h3>
                    <p class="mb-4" style="text-align: justify;">{{'landing.how_it_works_text' | translate}}</p>
                </div>
                <div class="col-12 col-lg-6 pb-4">
                    <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px"
                            [src]="whatIsVideoUrl"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                    </iframe>

                </div>
                <div class="col-12 col-lg-6">

                    <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px"
                            [src]="howToUseVideoUrl"
                            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-12 pb-1 pt-0 d-flex flex-column align-items-center">
                    <!--                   estava sendo redirecionado para /analysis/preconfig  (Ricardo solicitou alteração dia 14/11/2022            -->
                    <button class="button1" type="button" [routerLink]="'/events'" style="padding-left:90px;"><img src="assets/images/selo_opacity.png" style="width: 93px;position: absolute;left: -13px;top: 0px;"> {{'landing.button_your_analyses_label' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <footer class="text-white text-center" style="background-color: #333;">
        <div class="container py-1 px-4">
            <div class="row mt-2">
                <div class="col-12 col-md-4 mb-3 logo_footer">
                    <img  src="{{logoHeaderFile}}" alt="Split Timing" style="width: 115px;">
                </div>
                <div class="col-12 col-md-8">
                    <div *ngIf="brasil" class="apoio_label">Apoio</div>
                    <div *ngIf="brasil" class="row justify-content-end apoio_logo">
                        <div  class="col-12 col-md-4 mb-3">
                            <a href="https://www.organnact.com.br/" style="text-decoration:none;" target="_blank">
                                <img  src="assets/images/logos/organnact.png" alt="Organnact" style="width: 175px;">
                            </a>
                        </div>
                        <div  class="col-12 col-md-4 mb-3 apoio_logo">
                            <a href="https://www.instagram.com/sportequus/" style="text-decoration:none;" target="_blank">
                                <img  src="assets/images/logos/equus.jpg" alt="Organnact" style="width: 175px;">
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="w-100 text-center p-3" style="background-color: #222 !important;" >
            <p class="copyright" style="padding: 0 !important; margin: 0 !important; font-size: 13px;">{{'landing.copyright_message' | translate}}</p>
        </div>
    </footer>
</div>