import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AwsService} from "../../../services/aws.service";
import {LocalStorageService} from "ngx-webstorage";
import {TranslateService} from "@ngx-translate/core";
import {TurnModel} from "../../../models/turn.model";

@Component({
	selector: 'app-competitor-category-analysis',
	templateUrl: './competitor-category-analysis.component.html',
	styleUrls: ['./competitor-category-analysis.component.scss']
})
export class CompetitorCategoryAnalysisComponent {

	category_id: number = 0;

	competitor_id: number = 0;

	// other: number = 0;

	filteredTurns: TurnModel[] = [];
	turns: TurnModel[] = [];

	best: TurnModel = null;
	first: TurnModel = null;

	loading: boolean = false;

	eventName:string = null;

	constructor(
		private router: Router,
		private awsService: AwsService,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService,
		private translate: TranslateService,
	) {
		if (this.route.snapshot.paramMap.has("category")) {
			this.category_id = Number(this.route.snapshot.paramMap.get("category"));
		}

		if (this.route.snapshot.paramMap.has("competitor")) {
			this.competitor_id = Number(this.route.snapshot.paramMap.get("competitor"));
		}

		if (this.category_id && this.competitor_id) {
			this.localStorage.store("purchasedRedirectTo", `ca/${this.category_id}/${this.competitor_id}`);
			this.downloadRuns();
		} else {
			this.router.navigateByUrl('');
		}

	}

	downloadRuns() {
		this.loading = true;

		let params = {
			id_categoria: this.category_id,
			best: true
		};
		this.filteredTurns = [];
		this.awsService.getTurns(params).then((turns: { passadas: TurnModel[], best: TurnModel }) => {

			this.turns = this.sortTurns(turns.passadas);

			if (this.turns && this.turns.length){
				this.eventName = this.turns[0].evento;
				this.first = this.turns[0]
			}
			this.best = turns.best
			this.filteredTurns = this.turns.filter(turns => turns.id_competidor==this.competitor_id);
			console.log('this.filteredTurns ', this.filteredTurns)
		}).catch((error) => {
			console.log(error);
		}).finally(() => {
			this.loading = false;
		});
	}

	public sortTurns(turns: TurnModel[], field = "pos", order = 1): TurnModel[] {
		let result = turns;
		if (turns.length > 0) {
			let noTotalTurns = [];

			noTotalTurns = result.filter(turn => isNaN(turn["total"]) || !turn[field] || turn.sat == true || turn.nc == true);
			result = result.filter(turn => !(isNaN(turn["total"]) || !turn[field] || turn.sat == true || turn.nc == true));

			result = result.sort((turnA: TurnModel, turnB: TurnModel) => {
				if (turnA[field] < turnB[field]) {
					return (order >= 0) ? -1 : 1;
				} else if (turnA[field] > turnB[field]) {
					return (order >= 0) ? 1 : -1;
				}
				return 0;
			});
			result = result.concat(noTotalTurns);
		}
		return result;
	}

	public getPlacing(turn: TurnModel) {
		if (turn && turn.pos_txt !== null)
			return turn.pos_txt;

		return "--";
	}

	public getTotalTime(turn) {
		if (turn.nc)
			return this.translate.instant('general.nc_label')

		if (turn.sat)
			return this.translate.instant('general.sat_label')

		if (turn && turn.total)
			return this.toFixed(turn.total, 3);

		return "--";
	}
	public toFixed(value: number, places: number): string {
		if (!isNaN(value))
			return parseFloat(value.toString()).toFixed(places);
		return '--';
	}

	public buyAnalisys(run:TurnModel){
		this.localStorage.store("selectedCategory", run.categoria);
		this.localStorage.store("selectedEvent", run.evento);
		this.localStorage.store("eventId", run.id_evento);
		this.localStorage.store('seqPrincipal', run.seq);
		this.router.navigateByUrl('buy-analysis/' + run.id_evento);
	}

}
