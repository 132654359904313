import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-logos-list',
  templateUrl: './logos-list.component.html',
  styleUrls: ['./logos-list.component.scss']
})
export class LogosListComponent implements OnInit {
    @Input() event: any;
    logos: any[] = [];

  constructor() {

  }

  ngOnInit() {
      this.logos = this.event.logos.split(',');
  }

    public getPatrocinadoresHeight(){
        return 95;
        //return ((500/((this.patrocinadores.length >= 4)?this.patrocinadores.length:4))*(900/16))/100;
    }
    public getPatrocinadoresWidth(length){
        return (500/((length >= 4)?length:4));
    }

}
