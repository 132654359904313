<div id="page-content">
    <div class="container-fluid" style="background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);">
        <div class="row" style="padding: 0px 15px 10px;border-top: 1px solid rgb(255 255 255 / 25%);">
            <div class="col-12 p-0 d-flex flex-row justify-content-between">
                <span class="px-0 py-1" style="color: #fff;font-size: 1.2em;">{{'by_special_reports.title' | translate}}</span>
            </div>
            <div *ngIf="principal" class="col-12  p-0 d-flex  flex-column"
                 style="color: #fff;font-size: 1em; ">
                <div class="d-flex flex-column by-panel ">
                    <div class="title">{{'by_special_reports.principal_label' | translate}}
                        <i (click)="clearPrincipal()" button class="mdi mdi-close-outline cursor-pointer ml-auto delete" style="float: right;margin-right: 5px;margin-top: 5px; height: 100%;">
                        </i></div>
                    <div style="max-width: 100%">
                        <span class="horse mb-1">
                            <img src="../../../assets/images/icons/horse-icon.png">
                            <div>{{principal.cavalo}}</div>
                        </span>
                        <span class="competitor">
                            <img src="../../../assets/images/icons/cowboy-icon.png">
                            <div>{{principal.competidor}}</div>
                            <span class="placing">{{getPlacing(principal)}}</span>
                        </span>
                        <span *ngIf="categoryName=='*'" class="category-name">
                            {{principal.categoria}}
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!principal" class="col-12  p-0 d-flex  flex-column justify-content-end"
                 style="color: #fff;font-size: 1em; ">
                <div class="d-flex flex-column" >
                    <div style="width: 600px; max-width: 100%" class="title"> {{'by_special_reports.select_your_horse' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" style="background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);">
        <div class="row" style="padding: 0px 0px 10px;border-top: 1px solid rgb(255 255 255 / 25%);">
            <div class="col-12 p-0 d-flex flex-column justify-content-end">
                <span class="px-3 py-0" style="color: #fff;font-size: 1.2em;">{{eventName}}</span>
                <div class="row m-0 mt-3">
                    <div class="col-12 col-md-6 d-flex flex-column justify-content-end">
                        <div class="equsSelect mdi mdi-menu-down">
                            <span>{{'general.choose_class_label' | translate}}</span>
                            <select (change)="downloadTurns($event.target.value)">
                                <option *ngFor="let category of categories"
                                        [selected]="category.categoria==categoryName"
                                        [value]="category.categoria">{{category.categoria}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-11 col-md-5 d-flex flex-column justify-content-end">
                        <div class="equsSearchInput mdi mdi-magnify">
                            <input (input)="filterTurns($event.target.value)"
                                   placeholder="{{'general.label_search' | translate}}"
                                   type="text">
                        </div>
                    </div>
                    <div class="col-1 d-flex flex-row justify-content-center">
                        <button class="bt-print" (click)="generatePDF()" [disabled]="loading"
                                style="margin-right: 3px;margin-left: auto; margin-top: auto">
                            <i class="mdi mdi-printer"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="turns_header">
            <span (click)="sortBy($event,'competidor')">{{'general.label_competitor' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'pos')">{{'general.label_placement' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
            <span (click)="sortBy($event,'posd')">{{'general.label_placing' | translate}} <i
                    class="mdi mdi-arrow-down mdi-rotate-180"></i></span>
        </div>
    </div>
    <ul class="turns scrollbar">

        <mat-progress-bar *ngIf="loading" mode="indeterminate" style="height: 3px; margin-top: 1px"></mat-progress-bar>
        <div *ngFor="let turn of filteredTurns; let i = index" style="padding:0; margin: 0">
            <li (click)="setPrincipal(turn, i)" *ngIf="verifyIfNotSelected(turn)">

                <div class="item">
                    <span class="order" [attr.data-text]="turn.ord"></span>
                    <div style="text-overflow: ellipsis; overflow:hidden; white-space: nowrap; margin-left: 0; margin-right: auto;min-width: 240px;">
                            <span class="horse mb-1">
                                <img src="../../../assets/images/icons/horse-icon.png">
                                <div>{{turn.cavalo}}</div>
                            </span>
                        <span class="competitor">
                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                <div>{{turn.competidor}}</div>
                        </span>
                        <span *ngIf="categoryName=='*'" class="category-name">
                            {{turn.categoria}}
                        </span>
                    </div>
                    <div class="d-flex flex-row" style="width: 100px;">
                        <div class="positions">
                            <span class="placing">{{getPlacing(turn)}}</span>
                            <span class="total">
                            <i [attr.data-pas]="turn.pas" class="mdi relogio mdi-timer-outline" style="margin-top: -5px; font-size: 1.6em; position: relative; display: flex; justify-content: center; align-items: center; font-weight: 100;margin-right:3px;">

                            </i> {{getTotalTime(turn)}}</span>
                        </div>
                        <i *ngIf="!principal" class="mdi mdi-chevron-right" style="font-size: 2em;color: rgba(255,255,255,0.4); cursor: pointer"></i>
                    </div>
                </div>
            </li>
        </div>
    </ul>
    <div *ngIf="this.principal" class="pay-footer d-flex flex-row">
        <div class="px-3 py-1 d-flex flex-column justify-content-end">
              <div class="equsSelect mdi mdi-menu-down">
                            <span>Choose Segment</span>
                            <select style="color:white" (change)="setSelectedReportType($event.target.value)">
                                <option *ngFor="let top of top5Types"
                                        [selected]="top.id==selectedReportType.id"
                                        [value]="top.id">{{top.keylang | translate}}</option>
                            </select>
              </div>
        </div>
        <span class="px-3 py-2 m-auto title" style="color: #fff;font-size: 1.2em;">
            {{getTotal() | currency:'USD'}}
        </span>
        <button (click)="byAnalysis()"
                [disabled]="!principal || loading || loadingPurchase"
                class="bt-buy btn btn-default my-1" matTooltipPosition="above" style="font-size: 1rem">
            <i *ngIf="!loadingPurchase" class="mdi mdi-currency-usd"></i>
            <i *ngIf="loadingPurchase" class="mdi mdi-spin mdi-loading"> </i>
            Checkout
        </button>


    </div>

</div>
