import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { EventModel } from 'src/app/models/event.model';
import { AwsService } from 'src/app/services/aws.service';
import { LocalStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  tabs:any = { "prev":0,"vivo":1,"next":2 };
  events:any[] = [];
  filteredEvents:any[] = new Array(Object.keys(this.tabs).length).fill([]);
  activeTab:number = null;

  constructor(
    private awsService:AwsService,
    private localStorage:LocalStorageService,
    private router:Router,
    private translate: TranslateService,
  ){}

  ngOnInit() {
    this.downloadEvents();
  }

  private downloadEvents(){
    this.awsService.getEvents({best:true}).then((events:EventModel[]) => {
      this.events = events.filter(event => event.publico == true );
      this.filterEvents();
    }).catch(error => {
      console.log(error);
    });
  }

  public filterEvents(text=""){
    this.filteredEvents[this.tabs.next] = [];
    this.filteredEvents[this.tabs.prev] = [];
    this.filteredEvents[this.tabs.vivo] = [];
    
    let filteredEvents = this.events;
    
    if(text.trim() != ""){
      filteredEvents = this.events.filter((event) => {
        let name = event.evento.toLowerCase().includes(text.toLowerCase());
        let data = event.data.toLowerCase().includes(text.toLowerCase());
        let local = event.local.toLowerCase().includes(text.toLowerCase());
        return name || data || local;
      });
    }
    
    filteredEvents.forEach(event => {

      let data_ini = null;
      if(event.dtinicio != null && event.dtinicio.trim() != ""){
        data_ini = moment(event.dtinicio).toDate();
      }

      let data_fim = null;
      if(event.dttermino != null && event.dttermino.trim() != ""){
        data_fim = moment(event.dttermino).toDate();
      }

      if(data_fim != null && data_ini != null){
        let now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
      
        if(data_fim.getTime() >= now.getTime() && now.getTime() >= data_ini.getTime())
          this.filteredEvents[this.tabs.vivo].push(event);
        else if(data_fim.getTime() < now.getTime())
          this.filteredEvents[this.tabs.prev].push(event);
        else if(data_ini.getTime() > now.getTime())
          this.filteredEvents[this.tabs.next].push(event);
      }else{
        this.filteredEvents[this.tabs.prev].push(event);
      }
    });

    if(this.activeTab == null){
      if(this.filteredEvents[this.tabs.vivo].length>0){
        this.activeTab = this.tabs["vivo"];
      }else{
        this.activeTab = this.tabs["prev"];
      }
    }

    this.filteredEvents[this.tabs.next].sort((a, b) => {
      if ( a['dtinicio'] < b['dtinicio']){
        return 1;
      }else if( a['dtinicio'] > b['dtinicio'] ){
        return -1;
      }
      return 0;
    });

    this.filteredEvents[this.tabs.prev].sort((a,b) => {
      return moment(b.dtinicio).toDate().getTime() - moment(a.dtinicio).toDate().getTime();
    });
  }

  public goToLink(event, url: string){
    event.stopPropagation();
    window.open(url, "_blank");
  }

  openLink(event, path, params) {
    event.preventDefault();
    event.stopPropagation();
    const url = this.router.serializeUrl(
      this.router.createUrlTree([path],{queryParams:params})
    );

    window.open(url, '_blank');
  }

  public onEventClick(event:EventModel) {
    this.localStorage.store("selectedEvent",event.evento);
    this.localStorage.store("eventId",event.id);
    if (event.monetize){
      this.router.navigateByUrl('buy/' + event.id)

    }else {
      this.router.navigateByUrl('categories/' + event.id);
    }
  }

  toAnalysis(e, event:EventModel){
  if (event.monetize){
        this.localStorage.store("selectedEvent",event.evento);
        this.localStorage.store("eventId",event.id);
        this.router.navigateByUrl('categories/'+event.id);
  } else {
        e.stopPropagation();
        this.localStorage.store("selectedCategory", event.best.categoria);
        this.localStorage.store("atachedTurn", event.best);
        //this.router.navigateByUrl(`analysis?evento=${event.evento}&categoria=${event.best.categoria}&cavalo=${event.best.cavalo}&competidor=${event.best.competidor}`);
        const host: string = location.origin;
        const url: string = host + String(this.router.createUrlTree(['/analysis'], {
          queryParams: {
            evento: event.evento,
            categoria: event.best.categoria,
            cavalo: event.best.cavalo,
            competidor: event.best.competidor
          }
        }));
        let a: HTMLElement = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        //window.open(url, '_blank')
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.click();
        document.body.removeChild(a);
  }
  }

}
