import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {AwsService} from "../../services/aws.service";

@Component({
  selector: 'app-transmissao-best-tv-and-rf',
  templateUrl: './transmissao-best-tv-and-rf.component.html',
  styleUrls: ['./transmissao-best-tv-and-rf.component.scss']
})
export class TransmissaoBestTvAndRFComponent implements OnInit, AfterViewInit {

  logoBlackPath= "../../../assets/images/"+environment.logoBlack;

  id_evento:number;

  background:string = null;

  //Cavaleiro
  //Cavalo - traço categoria
  //Tempo
  bestTv = {
    'competidor':'-',
    'cavalo': '-',
    'tempo': 0,
    'type': 'tv',
  }

  bestRf = {
    'competidor':'-',
    'cavalo': '-',
    'tempo': 0,
    'type': 'rf',
  }

  constructor(
      private brokerService: BrokerService,
      private route:ActivatedRoute,
      private translate: TranslateService,
      private awsService: AwsService,
  ) {
    this.id_evento = Number(this.route.snapshot.queryParamMap.get("id_evento"));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.removeChat();
    },3000);
  }

  ngOnInit(){
    this.brokerService.startForComponent('',() => {
      this.brokerService.subscribe('equslive_'+this.id_evento,2,(message) => {
        this.onNewData(message);
      });
    });
    this.getBestTvAndRf();
  }

  removeChat(){
    let r = document.getElementById('LeadboosterContainer');
    if(r != null) r.parentElement.removeChild(r);
  }

  onNewData(message){
    this.handleNewdata(message);
  }

  getBestTvAndRf(){
    this.awsService.getBestTvAndRf({id_evento:this.id_evento}).then((result) => {
    if (result.data && result.data.length && result.data.length>0) {
      result.data.forEach(item => {
        // categoria
        // passada
        // cavalo
        // competidor
        // total
        // type
        // tempo
        if (item.type=="tv"){
          this.bestTv.competidor = item.competidor;
          this.bestTv.cavalo = item.cavalo  +' - '+item.categoria;
          this.bestTv.tempo = item.tempo;
        }
        if (item.type=="rf"){
          this.bestRf.competidor = item.competidor ;
          this.bestRf.cavalo = item.cavalo +' - '+item.categoria;
          this.bestRf.tempo = item.tempo;
        }

      })

    }
    else {
      this.bestTv = {
        'competidor':'-',
        'cavalo': '-',
        'tempo': 0,
        'type': 'tv',
      }

      this.bestRf = {
        'competidor':'-',
        'cavalo': '-',
        'tempo': 0,
        'type': 'rf',
      }
    }

      //console.log('data ->', result.data);
    });
  }

  handleNewdata(message) {
    this.removeChat();
   // console.log('chegou coisa...', message)
   this.getBestTvAndRf();
  }

  setBackground(event){
    this.background = event.target.value;
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

}
