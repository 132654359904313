import {Component} from '@angular/core';
import {TurnModel} from "../../../models/turn.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AwsService} from "../../../services/aws.service";
import {LocalStorageService} from "ngx-webstorage";
import {TranslateService} from "@ngx-translate/core";

export interface categoryTurns {
    category_id: number;
    category_name: string;
    completed:boolean;
    started:boolean;
    turns: TurnModel[];
}

@Component({
    selector: 'app-competitor-event-analysis',
    templateUrl: './competitor-event-analysis.component.html',
    styleUrls: ['./competitor-event-analysis.component.scss']
})
export class CompetitorEventAnalysisComponent {
    event_id: number = 0;
    competitor_id: number = 0;

    first: TurnModel = null;

    categories: categoryTurns[] = [];

    turns: TurnModel[] = [];

    filteredTurns: TurnModel[] = [];

    loading: boolean = false;

    eventName: string = null;

    constructor(
        private router: Router,
        private awsService: AwsService,
        private route: ActivatedRoute,
        private localStorage: LocalStorageService,
        private translate: TranslateService,
    ) {
        if (this.route.snapshot.paramMap.has("event")) {
            this.event_id = Number(this.route.snapshot.paramMap.get("event"));
        }

        if (this.route.snapshot.paramMap.has("competitor")) {
            this.competitor_id = Number(this.route.snapshot.paramMap.get("competitor"));
        }

        if (this.event_id && this.competitor_id) {
            this.localStorage.store("purchasedRedirectTo", `ev/${this.event_id}/${this.competitor_id}`);
            this.downloadRuns();
        } else {
            this.router.navigateByUrl('');
        }

    }

    downloadRuns() {
        this.loading = true;

        let params = {
            id_evento: this.event_id,
        };

        this.awsService.getTurns(params).then((turns: { passadas: TurnModel[] }) => {

            this.turns = this.sortTurns(turns.passadas);

            if (this.turns && this.turns.length) {
                this.eventName = this.turns[0].evento;
                this.first = this.turns[0]
            }

          //  console.log('this.filteredTurns ', this.filteredTurns)
            this.setCategoryTurns(this.turns);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
    }

    setCategoryTurns(turns: TurnModel[]){
        this.categories = [];
        turns.forEach(turn=>{
            const index = this.categories.findIndex(category=> category.category_id==turn.id_categoria);
            const turnCompleted = !!(turn.nc || turn.sat || turn.total);

            if (index>=0){
                this.categories[index].turns.push(turn);
                if (turnCompleted){
                    this.categories[index].started = true;
                }else{
                    this.categories[index].completed = false;
                }
            }else{
                this.categories.push({
                    category_id: turn.id_categoria,
                    category_name: turn.categoria,
                    turns: [turn],
                    completed: turnCompleted,
                    started: turnCompleted,
                })
            }

        });
        const result:categoryTurns[] = this.categories.map(category=>{
            category.turns = category.turns.filter(turns => turns.id_competidor == this.competitor_id);
            return category;
        });

        this.categories = result.filter(category=> category.turns && category.turns.length>0);
        console.log('this.categories ', this.categories)
    }

    getByToolTipError(category: categoryTurns, turn:TurnModel):string{
        let result: string = null;
        if (!category.completed){
            return this.translate.instant('general.class_not_completed');
        }else if (!turn.r1 || !turn.r2 || !turn.r3 || !turn.v1 || !turn.v2 || !turn.v3 || !turn.rf ||
            !turn.total || !turn.rv1 || !turn.rv2 || !turn.rv3 || !turn.rvr1 || !turn.rvr2 || !turn.rvr3){
            return this.translate.instant('purchased_analysis.msg_error_data_invalid');
        }
        return result;

    }
    public sortTurns(turns: TurnModel[], field = "pos", order = 1): TurnModel[] {
        let result = turns;
        if (turns.length > 0) {
            let noTotalTurns = [];

            noTotalTurns = result.filter(turn => isNaN(turn["total"]) || !turn[field] || turn.sat == true || turn.nc == true);
            result = result.filter(turn => !(isNaN(turn["total"]) || !turn[field] || turn.sat == true || turn.nc == true));

            result = result.sort((turnA: TurnModel, turnB: TurnModel) => {
                if (turnA[field] < turnB[field]) {
                    return (order >= 0) ? -1 : 1;
                } else if (turnA[field] > turnB[field]) {
                    return (order >= 0) ? 1 : -1;
                }
                return 0;
            });
            result = result.concat(noTotalTurns);
        }
        return result;
    }

    public getPlacing(turn: TurnModel) {
        if (turn && turn.pos_txt !== null)
            return turn.pos_txt;

        return "--";
    }

    public getTotalTime(turn) {
        if (turn.nc)
            return this.translate.instant('general.nc_label')

        if (turn.sat)
            return this.translate.instant('general.sat_label')

        if (turn && turn.total)
            return this.toFixed(turn.total, 3);

        return "--";
    }

    public toFixed(value: number, places: number): string {
        if (!isNaN(value))
            return parseFloat(value.toString()).toFixed(places);
        return '--';
    }

    public buyAnalisys(run: TurnModel) {
        this.localStorage.store("selectedCategory", run.categoria);
        this.localStorage.store("selectedEvent", run.evento);
        this.localStorage.store("eventId", run.id_evento);
        if (!run.r1 || !run.r2 || !run.r3 || !run.v1 || !run.v2 || !run.v3 || !run.rf ||
            !run.total || !run.rv1 || !run.rv2 || !run.rv3 || !run.rvr1 || !run.rvr2 || !run.rvr3) {
            // this.localStorage.store('seqPrincipal', run.seq);
            this.localStorage.clear('seqPrincipal');
        }else{
            this.localStorage.store('seqPrincipal', run.seq);
        }

        this.router.navigateByUrl('buy-analysis/' + run.id_evento);
    }


}
