import {Component, OnInit} from '@angular/core';
import {AwsService} from 'src/app/services/aws.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from "../../../environments/environment";
import {isTemplateDiagnostic} from "@angular/compiler-cli/src/ngtsc/typecheck/diagnostics";
import {keyframes} from "@angular/animations";

@Component({
	selector: 'app-insight',
	templateUrl: './insight.component.html',
	styleUrls: ['./insight.component.scss']
})
export class InsightComponent implements OnInit {

	id_evento: number;
	insights: any[] = [];
	evento: any = null;

	logoHeaderFile = 'assets/images/' + environment.logoHeaderFile;

	constructor(
		private awsService: AwsService,
		private route: ActivatedRoute
	) {
		this.id_evento = Number(this.route.snapshot.queryParamMap.get("id_evento"));
	}

	ngOnInit() {
		if (this.id_evento) {
			this.downloadEvent();
			this.downloadInsight();
		}
	}

	public getColor(row, field) {
		return {'color': row[field.n].cor};
	}

	public getValue(row, field) {
		if (row[field.n].vlr=='0.0%')
			return ''
		if (isNaN(row[field.n].vlr)) return row[field.n].vlr;
		if (row[field.n] == null || row[field.n].vlr == null)
			return "--";

		return row[field.n].vlr;
	}

	private downloadEvent() {
		this.awsService.getEvents({id_evento: this.id_evento}).then((data) => {
			this.evento = (data != null) ? data[0] : null;
		}).catch(error => {
			console.log(error);
		});
	}

	private downloadInsight() {
		this.awsService.getInsight({id_evento: this.id_evento}).then((data) => {
			this.insights = this.clearPercentagesInsight(data);
			}).catch(error => {
			console.log(error);
		});
	}

	private clearPercentagesInsight(insight) {

		const percentages = insight[5];

		const secondsToShow = {}

		percentages.dados.forEach(item => {
			Object.keys(item).forEach(key => {
					if (item[key].vlr != "0.0%") {
						secondsToShow[key] = true;
					}
				}
			)
		})

		const newHeaders = [];

		percentages.header.forEach(item => {
				if (secondsToShow[item.n]){
					newHeaders.push(item)
				}
			}
		);

		insight[5].header = newHeaders;

		return insight;

	}

}
