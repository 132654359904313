<header>
    <div class="container px-4">
        <div class="d-flex flex-row mx-0 align-items-center w-100 h-100">
            <img class="logo" src="{{logoHeaderFile}}" alt="">


            <ul id="sidebar_landing" class="ml-auto">
                <li>
                    <img class="logo" src="{{logoHeaderFile}}" alt="">

                </li>
                <li link="home" class="active" (click)="scrollTo('home')">HOME</li>
                <li *ngIf="brasil" link="howWorks" (click)="scrollTo('howWorks')">{{'landing.menu_how_it_works' | translate}}</li>
                <li *ngIf="!brasil" link="howWorks" [routerLink]="'/how-to'">{{'landing.menu_how_it_works' | translate}}</li>
                <li link="events" (click)="scrollTo('events')">{{'landing.menu_calendar' | translate}}</li>
                <li link="ranking" (click)="scrollTo('ranking')">{{'landing.menu_standings' | translate}}</li>
                <li link="contact" (click)="scrollTo('contact')">{{'landing.menu_contact' | translate}}</li>
                <li [routerLink]="'/events/analysis'">{{'landing.menu_split_timing' | translate}}</li>
<!--                estava sendo redicionado para /analysis/preconfig Ricardo solicitou alteração dia 14/11/2022          -->
            </ul>
            <i class="mdi mdi-menu toggle-sidebar" (click)="toggleMenu()"></i>
        </div>
    </div>
</header>
<div class="content">
    <div style="background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('assets/images/horse-background.jpg') no-repeat 30% center;background-size: cover;">


        <div class="banner pt-4" id="home" style="background:linear-gradient(180deg, #222222, #151515, #070707 69%, #15151500);">
            <div style="position:absolute;height:100%;width:100%;bottom:0px;background-image: url(assets/images/comp_bg.png);    background-size: cover;"></div>
            <img src="{{logoHomeHorseComp}}" class="cowboy">
        </div>

        <div class="container px-4" *ngIf="brasil" >
            <div class="row pb-4 mb-3 " id="howWorks">
                <div class="col-12 pb-5 pt-3 d-flex flex-column align-items-center">
<!--                   estava sendo redirecionado para /analysis/preconfig  (Ricardo solicitou alteração dia 14/11/2022            -->
                    <button class="button1" type="button" [routerLink]="'/events'" style="padding-left:90px;"><img src="assets/images/selo_opacity.png" style="width: 93px;position: absolute;left: -13px;top: 0px;"> {{'landing.button_your_analyses_label' | translate}}</button>
                </div>
                <div>
                    <h3 class="mb-4 d-flex justify-content-center" style="text-transform: uppercase;">{{'landing.menu_how_it_works' | translate}}</h3>
                    <p class="mb-4" style="text-align: justify;">{{'landing.how_it_works_text' | translate}}</p>
                </div>
                <div class="col-12 col-lg-6 pb-4">
                    <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px"
                    [src]="whatIsVideoUrl"
                     title="YouTube video player" frameborder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                    </iframe>

                </div>
                <div class="col-12 col-lg-6">

                    <iframe #video class="mb-3" width="100%" height="{{video.clientWidth / 1.7777}}px"
                    [src]="howToUseVideoUrl"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="w-100 py-3 d-flex flex-column align-items-center" style="min-height:80vh;background:#0009;border-top: #ffffff33 solid 1px;border-bottom: #ffffff33 solid 1px;">
            <div class="container px-4" >
                <div class="row" *ngIf="liveEvents.length">
                    <div class="col-12 pt-4" id="events">
                        <h3 style="text-transform: uppercase;">{{'landing.live_events_title'| translate}}</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 py-3 cursor-pointer" *ngFor="let event of liveEvents"
                         (click)="goToEvent(event.id, event.monetize)">
                        <div class="event_card h-100 d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div class="mr-3 d-flex flex-column calendar">
                                    <span>{{event.day}}</span>
                                    <span>{{event.month}}</span>
                                </div>
                                <div style="flex-grow:1; font-size: 0.9em;">
                                    <span style="font-weight:bold;font-size:1em;text-transform: uppercase;">{{event.evento}}</span>
                                    <!--div style="font-size: 0.8em;">
                                        De
                                        <span style="font-weight: 700;font-size: 1.2em;">{{event.de}}</span>
                                        à
                                        <span style="font-weight: 700;font-size: 1.2em;">{{event.ate}}</span>
                                    </div-->
                                    <div style="font-size: 0.8em;">
                                        <span style="font-size: 1.2em;">{{event.data}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row" style="margin-bottom: 8px;">
                                <span class="px-3 pt-2 flex-grow-1" style="color:#ffffffdd;font-size:0.9em;">
                                    <i  class="pr-2 mdi mdi-map-marker"></i>
                                    {{event.local}}
                                </span>
                                <button  style="margin-bottom: auto; height: 30px;" class="live-button" (click)="openLink($event,'/live',{'id_evento': event.id})">
                                    <i class="mdi mdi-circle mr-2" style="font-size: 6pt;"></i>
                                    {{'landing.button_live_label' | translate}}
                                </button>
                            </div>
                            <app-logos-list style="margin-top: auto" [event]="event"></app-logos-list>
<!--                            <div class="mt-4 d-flex justify-content-center" matTooltip="https://kinderbarrelrace.com/" style="margin-left: -20px; cursor: pointer">-->
<!--                                <img (click)="openLinkPatrocinador()" src="assets/images/logos/logo-kinder-barrel.png" alt="Kinder Barrel Race" height="80" width="100">-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div *ngIf="!brasil" class="row">
                    <div class="col-12 pb-5 pt-3 d-flex flex-column align-items-start">
                            <button class="button1" type="button" [routerLink]="'/events'" style="padding-left:90px;">
                            <img src="assets/images/selo_opacity.png" style="width: 93px;position: absolute;left: -13px;top: 0px;">
                            {{'landing.button_your_past_analyses_label' | translate}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-4" id="events">
                        <h3 style="text-transform: uppercase;">{{'landing.upcoming_events_title' | translate}}</h3>
                    </div>
                    <div class="col-12" *ngFor="let year of filteredEvents; let y = index" >
                        <h3 class="pt-4" style="text-transform: uppercase;"> <i class="mdi mdi-calendar"></i> {{year.year}}</h3>
                        <div class="row mx-0" [ngStyle]="(year.months.length-1 > i || filteredEvents.length-1 > y)?({'border-bottom': '#ffffff33 solid 1px'}):null" *ngFor="let month of year.months; let i = index;">
                            <div class="col-12 mt-3">
                                <h3 style="text-transform: uppercase;font-size: 1em;margin-bottom:0;">{{month.month}}</h3>
                            </div>
                            <ng-template  ngFor let-event [ngForOf]="month.events" >
                                <div *ngIf="event.publico"   (click)="goToEvent(event.id, event.monetize)"
                                     class="col-12 col-sm-6 col-md-6 col-lg-4 py-3 cursor-pointer">
                                    <div *ngIf="event.publico" class="event_card h-100 d-flex flex-column">
                                        <div class="d-flex flex-row">
                                            <div class="mr-3 d-flex flex-column calendar">
                                                <span>{{event.month}}</span>
                                                <span>{{event.day}}</span>
                                            </div>
                                            <div style="flex-grow:1; font-size: 0.9em;">
                                                <span style="font-weight:bold;font-size:1em;text-transform: uppercase;">{{event.evento}}</span>
                                                <!--div style="font-size: 0.8em;">
                                                    De
                                                    <span style="font-weight: 700;font-size: 1.2em;">{{event.de}}</span>
                                                    à
                                                    <span style="font-weight: 700;font-size: 1.2em;">{{event.ate}}</span>
                                                </div-->
                                                <div style="font-size: 0.8em;">
                                                    <span style="font-size: 1.2em;">{{event.data}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="px-3 pt-2 flex-grow-1" style="color:#ffffffdd;font-size:0.9em;">
                                            <i class="pr-2 mdi mdi-map-marker"></i>
                                            {{event.local}}
                                        </span>
                                        <app-logos-list [event]="event"></app-logos-list>
    <!--                                    <div class="mt-4 d-flex justify-content-center" matTooltip="https://kinderbarrelrace.com/" style="margin-left: -20px; cursor: pointer">-->
    <!--                                        <img (click)="openLinkPatrocinador()" src="assets/images/logos/logo-kinder-barrel.png" alt="Kinder Barrel Race" height="80" width="150">-->
    <!--                                    </div>-->
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-3 px-4" >
            <div class="row py-4 mt-3" *ngIf="rankings && rankings.length" id="ranking">
                <div class="col-12 col-md-12">
                    <div class="d-flex flex-row align-items-center h-100">
                        <h3 style="text-transform: uppercase;">{{rankings[0].titulo}}</h3>
                        <i button class="mdi mdi-content-copy cursor-pointer ml-auto text-white" (click)="copyRankingLink()"></i>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 py-3 d-inline-flex flex-column justify-content-end">
                    <div class="equsSelect">
                        <span>{{'landing.search_event_label' | translate}}</span>

                        <input type="text" #inputSearch
                            [formControl]="pastEventsMatControl"
                            (blur)="preventPushUpBug()"
                            aria-label="Number" matInput
                            [matAutocomplete]="autoEventoSearch"
                        >

                        <mat-autocomplete
                            #autoEventoSearch="matAutocomplete"
                            (optionSelected)="setEvent($event);inputSearch.blur(); "
                            [panelWidth]="'auto'"
                        >
                            <mat-option class="equsAutoComplete" *ngFor="let event of pastEventsMatFiltered  | async"   [value]="event.evento">
                                {{event.evento}}
                            </mat-option>
                        </mat-autocomplete>
                        <i  button class="mdi mdi-close-outline cursor-pointer ml-auto delete"
                            style="float: right;margin-right: 5px;margin-top: 5px; height: 100%;"
                            *ngIf="this.selectedEvent && this.selectedEvent.evento"
                            (click)="clearSelectedEvent()">
                        </i>
                    </div>
                    <mat-progress-bar *ngIf="loading"  mode="indeterminate"></mat-progress-bar>


                </div>
                <div class="col-12 col-sm-6 col-md-6 py-3 d-inline-flex flex-column justify-content-end" >
                    <div class="equsSelect mdi mdi-menu-down" *ngIf="categories.length">
                        <span>{{'landing.search_class_label' | translate}}</span>
                        <select (change)="setCategory($event.target.value)">
                            <option *ngFor="let category of categories" [selected]="category.id==selectedCategory.id" [value]="category.id">{{category.categoria}}</option>
                        </select>
                    </div>
                </div>
                <div #rank class="col-12 col-lg-6 mb-5" *ngFor="let ranking of rankings">
                    <div  class="d-flex flex-row">
                        <h3 class="title text-white d-inline-flex">{{ranking.subtitulo}}</h3>
                        <i button class="mdi mdi-content-copy cursor-pointer ml-auto text-white" (click)="copyRankingLink(ranking)"></i>
                        <i button class="mdi mdi-image cursor-pointer ml-3 text-white" (click)="generateImage($event,rank,ranking)"></i>
                    </div>
                    <div class="d-flex flex-column p-2 mb-1 text-white" *ngIf="(ranking.data.length>0 && selectedCategory.id != null)" style="background: rgba(0, 0, 0, 0.8);">
                        <div style="text-transform: uppercase;font-size: 1.3em; font-weight: bold;">{{ranking.data[0].evento}}</div><div style="font-size: 1.4em;">{{ranking.data[0].categoria}}</div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="turn d-flex flex-column" *ngFor="let turn of ranking.data">
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column" style="overflow:hidden;">
                                    <span class="horse">
                                        <img src="../../../assets/images/icons/horse-icon.png">
                                        <div>{{turn.cavalo}}</div>
                                    </span>
                                    <span class="competitor mt-2">
                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>
                                    </span>
                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1" style="font-size: 0.8em;"></i>{{toFixed(turn.tempo,3)}}</span>
                                    <span class="px-2 placing">{{(turn != null && turn.pos_txt != null) ? turn.pos_txt : turn.pos+"º"}}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between pt-2" style="font-size: 0.9em;">
                                <span style="text-transform: uppercase;" style="">{{turn.evento}}</span>
                                <span class="px-2" style="">{{turn.categoria}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid px-4 py-5"  style="min-height:80vh;background:#0009;border-top: #ffffff33 solid 1px;border-bottom: #ffffff33 solid 1px;">
            <div class="row pb-5" id="contact">

                <div class="col-12 col-md-10 col-lg-6 mx-auto ">
                    <h3 class="mb-4" style="text-transform: uppercase;">{{'landing.contact_us_title' | translate}}</h3>
                    <p>{{'landing.contact_us_text' | translate}}</p>
                    <div class="box">
                        <h3 class="heading">{{'landing.send_us_a_message' | translate}}</h3>
                        <form class="mb-3" [formGroup]="form" >
                            <div class="row">
                                <div class="col-md-12 form-group mb-3">
                                    <label for="name" class="col-form-label">{{'landing.form_control_your_name_label' | translate}}</label>
                                    <input formControlName="name" type="text" class="form-control" id="name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group mb-3">
                                    <label for="email" class="col-form-label">{{'landing.form_control_your_email_label' | translate}}</label>
                                    <input formControlName="email" type="text" class="form-control" id="email">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group mb-3">
                                    <label for="phone" class="col-form-label">{{'landing.form_control_your_phone_label' | translate}}</label>
                                    <input formControlName="phone" (input)="maskPhone($event.target.value)" type="text" class="form-control" id="phone">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-12 form-group mb-0">
                                    <label for="message" class="col-form-label">{{'landing.form_control_your_message_label' | translate}}</label>
                                    <textarea formControlName="message" style="height: 87px;resize:none;" class="form-control" id="message" cols="30" rows="7"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-8 mx-auto">
                                    <button id="contact-button" type="button" value="Send Message" (click)="sendContactMessage()" class="w-100 py-2 px-4">{{'landing.button_send_message_label' | translate}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="text-white text-center" style="background-color: #333;">
        <div class="container py-4 px-4">
            <div class="row mt-2">
                <div class="col-12 col-md-4 mb-3 logo_footer">
                    <img  src="{{logoHeaderFile}}" alt="Split Timing" style="width: 115px;">
                </div>
                <div class="col-12 col-md-8">
                    <div *ngIf="brasil" class="apoio_label">Apoio</div>
                    <div *ngIf="brasil" class="row justify-content-end apoio_logo">
                        <div  class="col-12 col-md-4 mb-3">
                            <a href="https://www.organnact.com.br/" style="text-decoration:none;" target="_blank">
                                <img  src="assets/images/logos/organnact.png" alt="Organnact" style="width: 175px;">
                            </a>
                        </div>
                        <div  class="col-12 col-md-4 mb-3 apoio_logo">
                            <a href="https://www.instagram.com/sportequus/" style="text-decoration:none;" target="_blank">
                                <img  src="assets/images/logos/equus.jpg" alt="Organnact" style="width: 175px;">
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="w-100 text-center p-3" style="background-color: #222 !important;" >
            <p class="copyright" style="padding: 0 !important; margin: 0 !important; font-size: 13px;">{{'landing.copyright_message' | translate}}</p>
           <p class="copyright" style="padding: 0 !important; margin: 0 !important; font-size: 10px;">{{'general.version' | translate}} 4.8.1</p>
        </div>
    </footer>

    <div style="width:0px;height:0px;overflow:hidden;">
        <div id="img-model" style="width:500px;height:600px;background: linear-gradient(to top, black,rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('/assets/images/horse-background.jpg') no-repeat 30% center;background-size: cover;">
            <div style="height:100px;width:100%;background-color:rgba(0,0,0,0.5);padding: 16px;border-bottom:#d5aa4b solid 3px;">
                <img style="width:65px;float: left;" src="{{logoHeaderFile}}" alt="">
                <div style="display: inline-block; width: 360px;float: right; margin-right: 30px;">
                    <h3 style="font-family:roboto;color:#fff;font-size:18pt;text-align:right;margin:0px;">RANKING {{(selectedCategory.id != null)?'SPLIT TIMING':'GERAL'}}</h3>
                    <h3 subtitle style="font-family:roboto;color:#fff;font-size:14pt;text-align:right;margin:0px;"></h3>
                </div>
            </div>
            <div [ngStyle]="{'height':(625 - 94 + (getPatrocinadoresHeight() * ((this.patrocinadores.length > 0)?1:-1)))+'px'}" style="width:100%;padding:10px 0;" class="cont">
            </div>
            <div *ngIf="patrocinadores.length > 0" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                <div *ngFor="let patrocinador of patrocinadores" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth()+'px'}">
                    <img [src]="patrocinador" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-cookie-notice *ngIf="showNotice==true"
        (buttonClicked)="onCookieButton($event)">

</app-cookie-notice>