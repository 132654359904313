import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-image-all-dialog',
  templateUrl: './image-all-dialog.component.html',
  styleUrls: ['./image-all-dialog.component.scss']
})
export class ImageAllDialogComponent {
  image:string = "";
  instagrams: string[]=[];
  constructor(
      @Inject(MAT_DIALOG_DATA) public data:any,
      public dialogRef: MatDialogRef<ImageAllDialogComponent>,
      private _snackBar:MatSnackBar,
      private translate: TranslateService,
  ) {
    this.image = this.data.image;
    this.instagrams = this.data.instagrams
  }

  close(){
    this.dialogRef.close();
  }

  public openSnackBar(message: string, type: string) {
    let config: MatSnackBarConfig = {
      panelClass:'snack_' + type,
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    };
    this._snackBar.open(message, 'Ok', config);
  }

  copyInstagram(instagram){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = instagram;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar(this.translate.instant('ranking_share_img.instagram_copied_message'), 'success');
  }

}
