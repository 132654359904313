<div id="page-content" [ngStyle]="(background != null)?{'background-color':background}:null">
    <div class="container-fluid px-0 d-flex flex-column" style="height:100vh;justify-content: center;align-items: center;">

        <label for="color" class="mdi mdi-eyedropper d-flex align-items-center justify-content-center cursor-pointer" style="    color: #ccc;position:absolute;top:0px;right:0px;width:40px;height:40px;border-radius:20px;font-size: 22pt;"></label>
        <input id="color" type="color" hidden style="position:absolute;right:0px;top:0px;" (change)="setBackground($event)">

       <div style="background: -webkit-linear-gradient(63deg, #a37841 0, #fbd985 30%, #fbd985 49%, #a37841 100%); width: 70%; height: 75px">
           <img src="{{logoBlackPath}}" style="height: 65px; position: relative; left: 10px; top: 4px;">
           <div class="col-12">
               <div class="d-flex align-items-center justify-content-center">
                   <h4 style="padding-top: 10px; position:relative; bottom: 65px">{{(event != null)?event:''}}</h4>
               </div>
               <div class="d-flex align-items-center justify-content-center">
                   <h6 style="font-size: 12pt; position: relative; bottom: 68px;">{{(category != null)?category:''}}</h6>
               </div>
           </div>

       </div>

        <div class="d-flex flex-row align-items-center px-2" style="background: -webkit-linear-gradient(63deg, #a37841 0, #fbd985 30%, #fbd985 49%, #a37841 100%); width: 70%; height: 75px">

            <!--TEAM 2-->
            <div class="align-items-center d-flex flex-row w-100 h-100">
                <div><img src="../../../assets/images/bandeiras-paises/european-flag.png" style="height: 55px;width: auto"></div>
                <div class="d-flex flex-row ml-2 align-items-center w-100">
                    <div class="mb-0" style="font-size: 20pt;text-overflow:ellipses;">{{'general.europe_label' | translate}}</div>
                    <span class="mb-0 ml-auto"
                          [ngClass]=" (rank1Total != null && rank2Total != null && rank1Total > rank2Total) ? 'winning_time' : '' "
                    style="font-size: 20pt;">
                    {{ rank2Total != null ? rank2Total.toFixed(3) : '--' }}</span>
                </div>
            </div>



            <div class="d-flex align-items-center justify-content-center mx-3" style="width:74px; font-size: 20pt; font-weight: bold;">VS</div>

            <!--TEAM 1-->
            <div class=" align-items-center d-flex flex-row w-100 h-100" >

                <div class="d-flex flex-row align-items-center w-100">
                    <span class="mb-0"

                          [ngClass]=" (rank1Total != null && rank2Total != null && rank1Total < rank2Total) ? 'winning_time' : '' "
                    style="font-size: 20pt;">
                    {{ rank1Total != null ? rank1Total.toFixed(3) : '--' }}</span>
                    <div class="mb-0 ml-auto mr-2" style="font-size: 20pt;text-overflow:ellipses;">USA</div>
                </div>
                <div class="ml-auto"><img src="../../../assets/images/bandeiras-paises/usa-flag.png" style="height: 55px;width: 104px;"></div>

            </div>


        </div>


        <div class="best_area d-flex flex-row" style="width: 70%;">
            <!--TEAM 2-->
            <div *ngIf="rank2.length" class="d-flex flex-column w-50">
                <div class="w-100 ranking">
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column" *ngFor="let turn of rank2">
                            <div class="d-flex flex-row">
                                <div class="d-flex" style="overflow:hidden;"> <!--Tirei o flex-column para centralizar somente o competidor-->
                                    <span class="horse">
                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>

                                    </span>
<!--                                    <span class="competitor">-->
<!--                                        <img src="../../../assets/images/icons/horse-icon.png">-->
<!--                                        <div>{{turn.cavalo}}</div>-->
<!--                                    </span>-->
                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1" style="font-size: 0.8em;"></i>{{toFixed(turn.total,3)}}</span>
                                    <span class="px-2 placing">{{(turn != null && turn.pos_txt != null)? turn.pos_txt:"--"}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rankingLogos.length" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                        <div *ngFor="let patrocinador of rankingLogos" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth(rankingLogos.length)+'px'}">
                            <img [src]="patrocinador+'?logocache'" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!--TEAM 1-->
            <div *ngIf="rank1.length" class="d-flex flex-column w-50 ranking">
                <div class="w-100 ranking">
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column" *ngFor="let turn of rank1">
                            <div class="d-flex flex-row">
                                <div class="d-flex" style="overflow:hidden;"> <!--Tirei o flex-column para centralizar somente o competidor-->
                                    <span class="horse">
                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                        <div>{{turn.competidor}}</div>
                                    </span>
                                    <!--                                    <span class="competitor">-->
                                    <!--                                        <img src="../../../assets/images/icons/horse-icon.png">-->
                                    <!--                                        <div>{{turn.cavalo}}</div>-->
                                    <!--                                    </span>-->
                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1" style="font-size: 0.8em;"></i>{{toFixed(turn.total,3)}}</span>
                                    <span class="px-2 placing">{{(turn != null && turn.pos != null) ? turn.pos : "--"}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rankingLogos.length" [ngStyle]="{'height':getPatrocinadoresHeight()+'px'}" style="height:92px;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                        <div *ngFor="let patrocinador of rankingLogos" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth(rankingLogos.length)+'px'}">
                            <img [src]="patrocinador+'?logocache'" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
