import {BaseModel} from '../models/base.model';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injector} from '@angular/core';
import {environment} from '../../environments/environment';
import { LocalStorageService} from 'ngx-webstorage';

export class BaseService<T extends BaseModel> {

    http: HttpClient;
    apiPath: string;
    localStorage: LocalStorageService;

    localStorageService: LocalStorageService;

    constructor(
        protected injector: Injector,
        protected specificUrl: string,
        protected jsonDataToResourceFn: (jsonData: any) => T
    ) {
        this.apiPath = environment.apiFlespi + specificUrl;
        this.http = injector.get(HttpClient);
        this.localStorage = injector.get(LocalStorageService);
    }

    async buildHeader(custom=null): Promise<HttpHeaders> {
      let user = this.localStorage.retrieve("user");
      // let business = this.localStorage.store("business", 2);
      let token = (user != null)?user.token:null;
      let uid = (user != null)?user.firebase_uid:null;
      let obj = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
        'Access-Control-Allow-Credentials': 'true'
      };
      if(token != null) obj['token'] = token;
      if(uid != null) obj['uid'] = uid;
      // if(business != null) obj['business'] = business;
      if(custom != null) obj = {...obj, ...custom};
      let headers = new HttpHeaders(obj);
      return headers;
    }

    static buildQueryParams(params: any): HttpParams {
        return Object.getOwnPropertyNames(params).reduce((p, key) => p.set(key, params[key]), new HttpParams());
    }


    async getResource(id: any): Promise<T> {

      let header = await this.buildHeader();

      return this.http.get(this.apiPath + '/' + id, {headers: header,observe:'response'}).toPromise()
      .then((response: HttpResponse<{data:T}>) => {
        return this.jsonDataToModel(response.body.data);
      }).catch((error) => {
        throw error;
      })
    }

    async customPost(body: any,customUrl=null,customParams=null) : Promise<any> {

    let url = this.apiPath;
    let params = {};

    if (customUrl)
      url = customUrl;

    if(customParams)
      params = customParams;

    let header = await this.buildHeader();
    return this.http.post(url,body,{headers:header,observe: 'response',params:params}).toPromise()
    .then((response: HttpResponse<any>) => {
        return response.body.data;
    }).catch( reason => {
      throw reason;
    });

  }

  async customPut(body:any, id:number=null, customUrl=null) : Promise<any> {

    let url = this.apiPath;
    if (customUrl){
      url = customUrl;
      if(id != null)
        url += id;
    }

    let header = await this.buildHeader();
    return this.http.put(url,body,{headers:header,observe: 'response'}).toPromise()
    .then((response: HttpResponse<any>) => {
        return response.body.data;
    }).catch( reason => {
      throw reason;
    });

  }

  async customGet(queryParams?: any,customUrl?:string, customHeaders?:any) : Promise<any> {

    let url = this.apiPath;
    let headers = customHeaders;
    if(headers == null)
      headers = await this.buildHeader();

    if(customUrl != null)
      url = customUrl;

    if(queryParams)
      queryParams = BaseService.buildQueryParams(queryParams);
    else
      queryParams = {};

    return this.http.get(url, {headers: headers,params:queryParams,observe: 'response'}).toPromise()
    .then((response: HttpResponse<any>) => {
      return response.body;
    }).catch(reason => {
      throw reason;
    });
  }

  async customDelete(id:number, customUrl=null) : Promise<any> {
    let url = this.apiPath +'/'+ id;
    if (customUrl)
      url = customUrl+id;

    let header = await this.buildHeader();
    return this.http.delete(url,{headers:header,observe: 'response'}).toPromise()
    .then((response: HttpResponse<any>) => {
        return response.body.data;
    }).catch( reason => {
      throw reason;
    });
  }

  async getCustomResource(queryParams?: any): Promise<any> {
    let header = await this.buildHeader();

    if (queryParams)
      queryParams = BaseService.buildQueryParams(queryParams);

    return this.http.get(this.apiPath, {headers: header, params: queryParams,observe:'response'}).toPromise()
    .then((response: HttpResponse<{ data: any }>) => {
      return response.body.data;
    })
    .catch((error) => {
      throw error;
    });
  }


  async getResources(queryParams?: any): Promise<T[]> {
    let header = await this.buildHeader();

    if (queryParams)
        queryParams = BaseService.buildQueryParams(queryParams);

    return this.http.get(this.apiPath, {headers: header, params: queryParams,observe:'response'}).toPromise()
    .then((response: HttpResponse<{ data: any[] }>) => {
      return this.jsonDataToModels(response.body.data);
    })
    .catch((error) => {
      throw error;
    });
  }


  async create(resource: T, queryParams?: any): Promise<T> {
    let header = await this.buildHeader();

    if (queryParams)
      queryParams = BaseService.buildQueryParams(queryParams);

    return this.http.post(this.apiPath, resource, {headers: header, observe: 'response', params: queryParams}).toPromise()
    .then((response: HttpResponse<{ data: T }>) => {
      return this.jsonDataToModel(response.body.data);
    })
    .catch((error) => {
      throw error;
    });
  }

  async update(resource: T, id: number, queryParams?: any): Promise<T> {
    let header = await this.buildHeader();

    if (queryParams)
      queryParams = BaseService.buildQueryParams(queryParams);

    return this.http.put(this.apiPath + '/' + id, resource, {headers: header, observe: 'response', params: queryParams}).toPromise()
    .then((response:HttpResponse<{ data: T }>) => {
      return this.jsonDataToModel(response.body.data);
    })
    .catch((error) => {
      throw error;
    })
  }

  async delete(id:any,customUrl=null,queryParams?: any): Promise<boolean> {
    let header = await this.buildHeader();

    let url = this.apiPath + '/' + id;

    if (customUrl){
      url = customUrl;
    }

    if(queryParams){
      queryParams = BaseService.buildQueryParams(queryParams);
    }else{
      queryParams = {};
    }

    return this.http.delete(url,{headers:header,observe: 'response',params:queryParams}).toPromise()
    .then((res:HttpResponse<{data:boolean}>) => {
        return true;
    }).catch( reason => {
      throw reason;
    });
  }

  protected jsonDataToModel(jsonData: any): T {
      return this.jsonDataToResourceFn(jsonData);
  }

  protected jsonDataToModels(jsonData: any[]): T[] {
    let resources: T[] = [];
    jsonData.forEach((data: any) => {
        resources.push(this.jsonDataToResourceFn(data));
    });

    return resources;
  }
}
