import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AwsService } from 'src/app/services/aws.service';
import { TurnModel } from 'src/app/models/turn.model';
import {ActivatedRoute, Router} from '@angular/router';
import domtoimage from 'dom-to-image';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../ranking/image-dialog/image-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-analysis-share',
  templateUrl: './analysis-share.component.html',
  styleUrls: ['./analysis-share.component.scss']
})
export class AnalysisShareComponent implements OnInit {

  logoHeaderFile = 'assets/images/'+environment.logoHeaderFile;

  @ViewChild('laco',{static:true}) image:ElementRef<HTMLImageElement>;
  @ViewChild('difs',{static:true}) difs:ElementRef<HTMLElement>;
  best:boolean = false;
  selfTurn:any = null;
  turn:any = null;
  categoryName:string="";
  eventName:string="";
  eventId:number = null;
  id_analise:number = null;
  uuid:string = null;

  maxRulerTRV = null;
  maxRulerRVR = null;
  maxRulerRV = null;
  maxRuler = null;

  colors = {
    'r1':'#9b1111',
    'v1':'#d6710a',
    'r2':'#3b9116',
    'v2':'#1e859e',
    'r3':'#50368a',
    'v3':'#90207a',
    'rf':'#ffffff',
    'pen':'#f00'
  };

  constructor(
    private awsService:AwsService,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.id_analise = Number(this.route.snapshot.queryParamMap.get("id"));
    this.uuid = this.route.snapshot.queryParamMap.get("uuid");
  }

  ngOnInit() {
    if(this.id_analise || this.uuid)
      this.downloadAnalysis();
  }


  getTurnPos(turn){
    if (turn && turn.pos_txt)
      return turn.pos_txt
    if (turn && turn.pos)
      return turn.pos+"º"
    return '--';
  }
  private downloadAnalysis(){

    const params = {};
    if (this.uuid){
      params['uuid'] = this.uuid
    }else{
      params['id_analise']= this.id_analise;
    }
    this.awsService.getAnalysis(params).then((data) => {


      data = JSON.parse(data["data"]["obj"]);
      console.log('data ->', data)
      this.eventName = data["evento"];
      this.eventId = data['analise'][0]['id_evento'];
      console.log('this.eventId ',  this.eventId);

      data.rvrt = (data.rvr1 != null && data.rvr2 != null && data.rvr3 != null) ? (data.rvr1 + data.rvr2 + data.rvr3) : null;
      this.selfTurn = data["analise"][1];
      if (!this.selfTurn.rvrt) this.selfTurn.rvrt = (this.selfTurn.rvr1 != null && this.selfTurn.rvr2 != null && this.selfTurn.rvr3 != null) ? (this.selfTurn.rvr1 + this.selfTurn.rvr2 + this.selfTurn.rvr3) : null;
      this.turn = data["analise"][0];
      if (!this.turn.rvrt) this.turn.rvrt = (this.turn.rvr1 != null && this.turn.rvr2 != null && this.turn.rvr3 != null) ? (this.turn.rvr1 + this.turn.rvr2 + this.turn.rvr3) : null;
      this.best = data["best"];
      if (!this.best['rvrt']) this.best['rvrt']= (this.best['rvr1']!= null && this.best['rvr2']!= null && this.best['rvr3']!= null) ? (this.best['rvr1']+ this.best['rvr2']+ this.best['rvr3']) : null;
      this.categoryName = data["categoria"];


      let totTRV = this.turn.tr + this.turn.tv;
      let totSTRV = this.selfTurn.tr + this.selfTurn.tv;
      this.maxRulerTRV = (totTRV > totSTRV)?totTRV:totSTRV;
      
      let totRVR = this.turn.rvr1+this.turn.rvr2+this.turn.rvr3;
      let totSRVR = this.selfTurn.rvr1+this.selfTurn.rvr2+this.selfTurn.rvr3;
      this.maxRulerRVR = (totRVR > totSRVR)?totRVR:totSRVR;

      let totRV = this.turn.rv1+this.turn.rv2+this.turn.rv3 + this.turn.rf;
      let totSRV = this.selfTurn.rv1+this.selfTurn.rv2+this.selfTurn.rv3 + this.selfTurn.rf;
      this.maxRulerRV = (totRV > totSRV)?totRV:totSRV;

      let tot = this.turn.total;
      let totS = this.selfTurn.total;
      this.maxRuler = (tot > totS)?tot:totS;
    }).catch(error => {
      console.log(error);
    });
  }

  public toFixed(value:number, places:number):string{
    if(!isNaN(value))
      return parseFloat(value.toString()).toFixed(places);
    return '--';
  }

  public getRulerSectionWidth(field, turn:TurnModel){
    let width = 0;
    let total = turn.total;
    if(total != null){
      width = (turn[field]*100)/total;
    }
    return width;
  }
  public getRulerSectionValueWidth(value, turn:TurnModel){
    let width = 0;
    let total = turn.total;
    if(total != null){
      width = (value*100)/total;
    }
    return width;
  }

  public generateImage(event, element){
    event.target.classList.add("mdi-loading","mdi-spin");
    event.target.classList.remove("mdi-image");
    setTimeout(()=>{
      domtoimage.toPng(element).then((dataUrl) => {
        var img = document.createElement("img");
        img.src = dataUrl;
        //let jan = window.open('','_blank');
        //jan.document.body.appendChild(img);
        this.openImage(dataUrl);
        event.target.classList.remove("mdi-loading");
        event.target.classList.remove("mdi-spin");
        event.target.classList.add("mdi-image");
      });
    },1000);
  }

  public openImage(image){
    this.dialog.open(ImageDialogComponent,{
      width:'500px',
      maxWidth:'100%',
      data:image,
      panelClass:"dialog"
    });
  }

  public convertBase64ToFile(image){
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/png',
    });
    return newBlob;
  };


  public getDif(field){
    let label = '';
    
    if(this.selfTurn[field]!=null && this.turn[field] != null){
      let dif = this.turn[field] - this.selfTurn[field];
      label = (dif > 0?'+':'') + (dif).toFixed(3);
    }
    return label;
  }

  public getTotals(turn){
    let reta = (turn != null && turn.rt)?turn.rt:0;
    let curva = (turn != null && turn.rv)?turn.rv:0;
    let tot = reta+curva;
    let perc_reta = 100*reta/tot;
    let perc_curva = 100*curva/tot;
    let total = {
      perc_reta:perc_reta.toFixed(3),
      perc_curva:perc_curva.toFixed(3)
    }
    return total;
  }

  public getTotalsDifferences(){
    let dif = this.turn.tr - this.selfTurn.tr;
    let reta = (dif>0?'+':'')+this.toFixed(dif,3);

    dif = this.turn.tv - this.selfTurn.tv;
    let curva = (dif>0?'+':'')+this.toFixed(dif,3);

    let difs ={
      reta: reta,
      curva: curva
    } 
    return difs;
  }

  public getRVtotals(turn){
    let rv1 = ((turn != null && turn.rv1)?turn.rv1:0);
    let rv2 = ((turn != null && turn.rv2)?turn.rv2:0);
    let rv3 = ((turn != null && turn.rv3)?turn.rv3:0);
    let rf = ((turn != null && turn.rf)?turn.rf:0);
    let tot = rv1+rv2+rv3+rf;
    let perc_rv1 = 100*rv1/tot;
    let perc_rv2 = 100*rv2/tot;
    let perc_rv3 = 100*rv3/tot;
    let perc_rf = 100*rf/tot;

    let total = {
      perc_rv1:perc_rv1.toFixed(3),
      perc_rv2:perc_rv2.toFixed(3),
      perc_rv3:perc_rv3.toFixed(3),
      perc_rf:perc_rf.toFixed(3)
    }
    return total;
  }

  getTotal(array_values){
    let tot = 0;
    for(let val of array_values){
      if(val!=null && isNaN(val)==false) tot += parseFloat(val);
    }
    return tot.toFixed(3)
  }

  buyMore(){
    this.router.navigateByUrl('categories/' + this.eventId);
  }

}
