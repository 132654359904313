<div id="page-content">
    <div class="header">
        <input #file type="file" id="file" class="d-none" (change)="handleFileInput($event.target.files)">
        <button type="button" class="add_button ml-auto d-flex align-items-center" style="height:38px;" (click)="file.click()">
            <i class="mdi mdi-folder-upload-outline mr-3"></i> <span style="font-size: 9pt;text-transform: inherit;">{{'upload_dialog.find_in_folder' | translate}}</span>
        </button>
    </div>

    <div class="flex-grow-1 d-flex flex-column" style="background: #252525;overflow: hidden;width:600px;padding:16px;">
        <div id="drop-area" >
            <span>{{'upload_dialog.drag_drop_here' | translate}}:</span>
        </div>
    </div>
    <div class="d-flex flex-row flex-wrap p-3 justify-content-end">
        <button type="button" (click)="close()" class="py-2 px-4">{{'general.label_cancel' | translate}}</button>
    </div>
</div>

