import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {
  image:string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:string,
    public dialogRef: MatDialogRef<ImageDialogComponent>
  ) {
    this.image = this.data;
  }

  ngOnInit() {
  }

  share(){
    var arr = this.image.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let filename = 'lalala';
    let filesArray = [new File([u8arr], filename, {type:mime})];

    if (typeof window.navigator["share"] != undefined && window.navigator["canShare"] && window.navigator["canShare"]({ files: filesArray })) {
      try {
        window.navigator["share"]({
          files: filesArray,
          title: filename,
          text: "",
        } as ShareData);
      }
      catch (error) {
        console.log("Sharing failed", error);
      }
    }
    else {
      console.error("Cannot use Web Share API: API unavailable.");
    }
  }

  close(){
    this.dialogRef.close();
  }

}
