<div id="page-content"  style="overflow-y: auto;flex-direction: column;">
    <div #element class="d-flex flex-column">
        <!--i class="mdi mdi-image" (click)="generateImage($event, element)"></i-->

        <div class="px-1">
            <div class="row" style="margin-top: -65px">
                <div class="col-4 col-sm-4 col-lg-4 col-xl-4 pt-1 logo_title mt-3">
                    <img class="cursor-pointer" style="width:100px;" src="{{logoHeaderFile}}" routerLink="/events">
                </div>
                <div class="col-8 col-sm-8 col-lg-8 col-xl-8 text-white pt-3 page_title">
                    <h5 style="text-transform: uppercase;font-weight: 600;margin-bottom: 0;">
                        {{'by_special_reports.title' | translate}}<br /> Top 5</h5>
                    <h6 style="font-weight: 400;text-transform: uppercase;margin-bottom: 8px;">{{report_type_label | translate}}</h6>
                </div>
            </div>
            <mat-progress-bar *ngIf="loading" mode="indeterminate" style="height: 3px; margin-top: 1px"></mat-progress-bar>
            <div class="col-12 my-0 py-1" *ngIf="principal" >
                <h4 style="color:white">Your Time</h4>
                <div class="d-flex flex-column  mb-1 px-1" style="background:#2a2a2a;flex-grow: 1;box-shadow: 1px 1px 2px 0px var(--turn-color);border-radius: 15px;">
                        <div class="d-flex flex-column" style="padding: 5px;">
                            <div class="d-flex flex-column flex-grow-1 justify-content-around" style="overflow: auto;">
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img style="height:15px;" class="mr-3" src="../../../assets/images/icons/horse-icon.png">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(principal && principal['cavalo'])?principal['cavalo']:'--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img style="height:15px;" class="mr-3" src="../../../assets/images/icons/cowboy-icon.png">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(principal && principal.competidor)? principal.competidor:'--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="font-size: 0.9em;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">
                                        {{(principal && principal.evento) ? principal.evento : '--'}}
                                    </div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="font-size: 0.9em;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">
                                        {{(principal && principal.categoria) ? principal.categoria : '--'}}
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-end" style="min-width: 92px;">
                                    <span style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;font-size: 2em;line-height: 1em;">
<!--                                        {{getTurnPos(principal)}}-->
                                    </span>
                                <span style="font-size: 14pt;" class="turn-color">
                                        <i class="mdi mdi-timer-outline"></i>
                                    {{(principal && principal[report_type_field])? toFixed(principal[report_type_field],3):'--'}}
                                    </span>
                            </div>
                        </div>
                </div>
                <h4 style="color:white">Top 5</h4>
                <div *ngFor="let turn of top_5_data">
                    <div class="d-flex flex-column  mb-2 px-1" style="background:#2a2a2a;flex-grow: 1;box-shadow: 1px 1px 2px 0px var(--turn-color);border-radius: 15px;">
                      <div  class="d-flex flex-column" style="padding: 5px;;">

                        <div class="d-flex flex-column flex-grow-1 justify-content-around" style="overflow: auto;">
                            <div class="d-flex flex-row align-items-center text-white">
                                <img style="height:15px;" class="mr-3" src="../../../assets/images/icons/horse-icon.png">
                                <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn['cavalo'])?turn['cavalo']:'--'}}</div>
                            </div>
                            <div class="d-flex flex-row align-items-center text-white">
                                <img style="height:15px;" class="mr-3" src="../../../assets/images/icons/cowboy-icon.png">
                                <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.competidor)? turn.competidor:'--'}}</div>
                            </div>
                            <div *ngIf="!categoria_id" class="d-flex flex-row align-items-center text-white">
                                <div style="font-size: 0.9em;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-end" style="min-width: 92px;">
                                <span style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;font-size: 2em;line-height: 1em;">
<!--                                        {{getTurnPos(turn)}}-->
                                </span>
                            <span style="font-size: 14pt;" class="turn-color">
                                    <i class="mdi mdi-timer-outline"></i>
                                {{(turn && turn[report_type_field])? toFixed(turn[report_type_field],3):'--'}}
                                </span>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
    </div>
</div>
