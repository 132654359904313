<div [ngStyle]="(background != null)?{'background-color':background}:null" id="page-content">
    <div class="container-fluid px-0 d-flex flex-column"
         style="height:100vh;justify-content: center;align-items: center;">

        <label class="mdi mdi-eyedropper d-flex align-items-center justify-content-center cursor-pointer" for="color"
               style="    color: #ccc;position:absolute;top:0px;right:0px;width:40px;height:40px;border-radius:20px;font-size: 22pt;"></label>
        <input (change)="setBackground($event)" hidden id="color" style="position:absolute;right:0px;top:0px;"
               type="color">
        <div class="best_area d-flex flex-column" style="width: 90%;">
            <div class="d-flex flex-column w-100 ranking" style="margin-right: 20px;">
                <div class="w-100 ranking">
                    <div class=" d-flex flex-row"
                         style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #fbd985 30%, #e3c97c 49%, #a37841 100%);margin: 10px 0;">
                        <div><img src="{{logoBlackPath}}" style="height: 100px;margin: 5px 10px;"></div>
                        <div class="d-flex flex-column flex-grow-1 w-100 px-3 py-1 pt-2">
                            <div style="line-height: 15px;;font-weight: bolder;text-transform: uppercase;font-size: 4em;text-align:center;padding-top: 36px;">
                                SIDE POT Split Timing
                            </div>
                        </div>
                        <div style="width: 206px"></div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column w-100 ranking" style="margin-top: 10px">
                <div class="w-100 ranking">
<!--                    <div class=" d-flex flex-row"-->
<!--                         style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #fbd985 30%, #e3c97c 49%, #a37841 100%);">-->

<!--                        <div class="d-flex flex-column flex-grow-1 w-100 px-3 py-1 pt-2">-->
<!--                            <div style="line-height: 15px;text-align: center; font-size: 1.5em; padding: 3px;">{{'general.best_tv' | translate}}</div>-->

<!--                        </div>-->
<!--                    </div>-->
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column">
                        <span class="horse"
                              style="border-bottom: 1px solid white; text-align: center; padding-bottom: 10px; font-size: 4em;">
                                    <div style="font-weight: 400;padding: 10px;">{{'general.best_tv' | translate}}</div>
                         </span>
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column" style="overflow:hidden;">
                                <span class="competitor">
                                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                                    <div>{{bestTv.competidor}}</div>
                                        </span>
                                    <span class="horse">
                                            <img src="../../../assets/images/icons/horse-icon.png">
                                            <div style="font-size: 2.5em;">{{bestTv.cavalo}}</div>
                                        </span>

                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1"
                                                                style="font-size: 1.8em;"></i>{{toFixed(bestTv.tempo, 3)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column w-100" style="margin-top: 10px">
                <div class="w-100 ranking">
                    <!--                    <div class=" d-flex flex-row"-->
                    <!--                         style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #fbd985 30%, #e3c97c 49%, #a37841 100%);margin-top: 8px;">-->

                    <!--                        <div class="d-flex flex-column flex-grow-1 w-100 px-3 py-1 pt-2">-->
                    <!--                            <div style="line-height: 15px;text-align: center;font-size: 1.5em; padding: 3px;">{{'general.best_rf' | translate}}</div>-->

                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="d-flex flex-column flex-grow-1 " style="overflow: auto;">
                        <div class="turn d-flex flex-column">
                         <span class="horse"
                               style="border-bottom: 1px solid white; text-align: center; padding-bottom: 10px; font-size: 4em;">
                                    <div style="font-weight: 400;padding: 10px;">{{'general.best_rf' | translate}}</div>
                         </span>
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column" style="overflow:hidden;">

                                    <span class="competitor">
                                                        <img src="../../../assets/images/icons/cowboy-icon.png">
                                                        <div>{{bestRf.competidor}}</div>
                                    </span>
                                    <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div style="font-size: 2.5em;">{{bestRf.cavalo}}</div>
                                    </span>

                                </div>
                                <div class="d-flex flex-row align-items-center ml-auto">
                                    <span class="px-2 value"><i class="mdi relogio mdi-timer-outline mr-1"
                                                                style="font-size: 1.8em;"></i>{{toFixed(bestRf.tempo, 3)}}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
