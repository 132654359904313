<div style="flex-grow:1;">
    <span style="font-weight:bold;font-size:1.1em;">{{cat.categoria}}</span><br>
    <div style="height: 4px" ></div>
    <span class="mt-2 mb-2" style="font-weight:400;font-size:0.9em;opacity: 0.9">
    {{'category_card.label_runs' | translate}}: {{(cat.stat != null && cat.stat.finalizadas != null) ? cat.stat.finalizadas : 0}} / {{(cat.stat != null && cat.stat.passadas != null)? cat.stat.passadas : 0}}</span><br>
    <div style="height: 6px" ></div>

    <div class="align-items-end d-flex flex-column p-1 mx-0 my-2"
    *ngIf="cat.best != null && (cat.best.cavalo||cat.best.competidor||cat.best.total)" style="border: #2c2c2c solid 1px;border-radius: 5px;font-size: .9em;">
        <div class="d-flex flex-row w-100 justify-content-between align-items-start">
            <div  style="display: flex;  flex-direction: column;  flex-grow: 1;  overflow: hidden;">
                <span *ngIf="cat.best != null && cat.best.cavalo" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;flex-grow: 1;overflow: hidden;">
                    <span style="font-weight:700">
                        <img class="horse" src="../../../assets/images/icons/horse-icon.png">
                    </span>
                    {{cat.best.cavalo}}
                </span>
                <span *ngIf="cat.best != null && cat.best.competidor" style="color: #ffffffdd;font-size: 0.9em;text-overflow: ellipsis;overflow-x: hidden;white-space: nowrap;display: block;margin: 0px;flex-grow: 1;overflow: hidden;">
                    <span style="font-weight:700">
                        <img class="competitor" src="../../../assets/images/icons/cowboy-icon.png">
                    </span>
                    {{cat.best.competidor}}
                </span>
            </div>
            <span *ngIf="cat.best != null && cat.best.total" style="color:#ffffffdd;font-size:0.9em;min-width: 80px;">
                <span style="font-weight:700">
                    <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                </span>
                <span style="font-size: 1.4em;"> {{(cat.best != null && cat.best.total)?cat.best.total.toFixed(3):'--'}}</span>
            </span>
        </div>
        <div class="d-flex flex-row" [ngClass]="isAdmin() && cat.monetize ? 'justify-content-between w-100 my-2':''">

            <button *ngIf="isAdmin() && cat.monetize" matTooltip="{{'general.links_to_competitors' | translate}} (.xlsx)" matTooltipPosition="above"

                    (click)="handleLinksToCompetitors($event, cat.id, 'xlsx')"
                    class="btn btn-default my-2 gold-btn">
                <i *ngIf="!loadingLink['xlsx-'+cat.id]" style="font-size: 20pt;line-height: 1em;" class="mdi mdi-microsoft-excel"></i>
                <i *ngIf="loadingLink['xlsx-'+cat.id]" style="font-size: 20pt;line-height: 1em;" class="mdi mdi-loading  mdi-spin"></i>
            </button>



            <button *ngIf="isAdmin() && cat.monetize" matTooltip="{{'general.links_to_competitors' | translate}} (.csv)" matTooltipPosition="above"
                    (click)="handleLinksToCompetitors($event, cat.id, 'csv')"
                    class="btn btn-default my-2 gold-btn">
                <i *ngIf="!loadingLink['csv-'+cat.id]" style="font-size: 20pt;line-height: 1em;" class="mdi mdi-file-delimited"></i>
                <i *ngIf="loadingLink['csv-'+cat.id]" style="font-size: 20pt;line-height: 1em;" class="mdi mdi-loading  mdi-spin"></i>
            </button>

            <button *ngIf="isAdmin()" matTooltip="{{'category_card.tooltip_performance' | translate}}" matTooltipPosition="above"
            (click)="openLink($event,'/admin/performance',{'id_evento': idEvento, 'id_categoria':cat.id})"
                    class="btn btn-default my-2 ml-auto gold-btn">
                <i style="font-size: 20pt;line-height: 1em;"
                   class="mdi mdi-compare-horizontal"></i>
            </button>
            <button *ngIf="isAdmin() || !cat.monetize" matTooltip="{{'category_card.tooltip_analysis' | translate}}" matTooltipPosition="above"
              (click)="toAnalysis($event)"
                    class="btn btn-default my-2 gold-btn">
                <i class="mdi mdi-chart-line" style="font-size: 20pt;line-height: 1em;"></i>
            </button>
        </div>
    </div>
    <span class="status"  *ngIf="cat.stat != null && cat.stat.status == 'Waiting'">
    <i class="mdi mdi-clock-outline mr-2"></i>{{'category_card.label_not_started' | translate}}</span>
    <span class="status"  *ngIf="cat.stat != null && cat.stat.status == 'End'">
    <i class="mdi mdi-check-all mr-2"></i>{{'category_card.label_completed' | translate}}</span>

    <div class="status aovivo" *ngIf="cat.stat != null && cat.stat.status == 'Live'"
    (click)="openLink($event,'/live',{'id_evento': idEvento})">
    <i class="mdi mdi-television mr-2"></i>{{'category_card.label_live' | translate}}</div>
</div>
