import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AwsService } from 'src/app/services/aws.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form:FormGroup;

  errorMessages = {
    "name":"",
    "email":"",
    "password":"",
    "confirm":""
  };

  constructor(
    private formBuilder:FormBuilder,
    private awsService:AwsService,
    private router: Router,
    public toastr:ToastrService,
    private translate: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      name:[null,[Validators.required]],
      email:[null,[Validators.required, Validators.email]],
      password:[null,[Validators.required, Validators.minLength(8)]],
      confirm:[null,[Validators.required, Validators.minLength(8)]],
    },{validators:this.checkPasswords});
  }

  ngOnInit() {
  }

  register(){
    if(this.form.valid){
      let params = this.form.value;
      this.awsService.signup(params.name, params.email, params.password).then((response) => {
        if(response != null && response.email != null){
          this.router.navigateByUrl("/login").then(() => {
            this.toastr.success(this.translate.instant('register.account_created_message')+"!",this.translate.instant('register.welcome_message')+"!");
          });
        }else{
          let message = this.translate.instant('register.Unable_to_register_check_and_try')+'!';
          if(response.message != null && response.message.trim() != "")
            message = response.message;
          this.toastr.error(message,this.translate.instant('general.error_title'));
        }
      }).catch((error) => {
        this.toastr.error(this.translate.instant('register.Unable_to_register_try')+'!',this.translate.instant('general.error_title'));
      });
    }
  }

  verifyEmail(event){
    let emailControl = this.form.get("email");
    if(emailControl.errors == null){
      this.awsService.emailUnique(emailControl.value).then((response) => {
        if(response != null && !response.success)
          emailControl.setErrors({"exists":true});
        else if(emailControl.errors != null && emailControl.errors.hasOwnProperty("exists") != null)
          emailControl.setErrors({"exists":false});

        this.getError("email");
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  checkPasswords(group: FormGroup) {
    const password = group.get('password').value;
    const confirm = group.get('confirm').value;
    let retorno = null;
    if(password!=null && password.trim()!=''){
      if(password!=confirm) retorno = { notSame: true };
    }
    return retorno;
  }

  getError(contolName){
    let control = this.form.get("name");
    let errors = control.errors;
    this.errorMessages["name"] = "";
    if((control.touched || contolName == "name") && errors != null){
      if(errors.hasOwnProperty("required"))
        this.errorMessages["name"] = this.translate.instant('register.fill_this_field');
    }

    control = this.form.get("email");
    errors = control.errors;
    this.errorMessages["email"] = "";
    if((control.touched || contolName == "email") && errors != null){
      if(errors.hasOwnProperty("required"))
        this.errorMessages["email"] = this.translate.instant('register.fill_this_field');
      if(errors.hasOwnProperty("email"))
        this.errorMessages["email"] = this.translate.instant('register.invalid_email');
      if(errors.hasOwnProperty("exists"))
        this.errorMessages["email"] = this.translate.instant('register.email_already_exists');
    }

    control = this.form.get("password");
    errors = control.errors;
    this.errorMessages["password"] = "";
    if((control.touched || contolName == "password") && errors != null){
      if(errors.hasOwnProperty("required"))
        this.errorMessages["password"] = this.translate.instant('register.fill_this_field');
      if(errors.hasOwnProperty("minlength"))
        this.errorMessages["password"] = `${this.translate.instant('register.password_must_have')} ${errors["minlength"]["requiredLength"]} ${this.translate.instant('register.characters')}`;
    }

    control = this.form.get("confirm");
    errors = control.errors;
    this.errorMessages["confirm"] = "";
    if((control.touched || contolName == "confirm") && errors != null){
      if(errors.hasOwnProperty("required"))
        this.errorMessages["confirm"] = this.translate.instant('register.fill_this_field');
      if(errors.hasOwnProperty("minlength"))
        this.errorMessages["confirm"] = `${this.translate.instant('register.password_must_have')} ${errors["minlength"]["requiredLength"]} ${this.translate.instant('register.characters')}`;
    }
    if(this.form.get("password").value != control.value)
        this.errorMessages["confirm"] = this.translate.instant('register.password_not_match');

  }

}
