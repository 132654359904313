<div id="page-content">
    <div class="container-fluid py-2 px-0" style="height:100vh;background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));border-top: 1px solid rgb(255 255 255 / 25%);">
        <span style="margin-left: 24px;font-size: 1.1em;margin-bottom: 12px;color: #fff;font-size: 1.6em; text-transform: uppercase; font-weight: 600;">{{'purchased_analysis.title' | translate}}</span>

    <div class="container-fluid d-flex flex-column flex-grow-1 scrollbar pb-3" style="overflow-y: auto;padding-top: 8px;">
        <p>
            {{'purchased_analysis.message1' | translate}}
        </p>
        <p>
            {{'purchased_analysis.message2' | translate}}
        </p>
        <p>
            {{'purchased_analysis.message_please_contact' | translate}} <a href="mailto:contact@splittiming.com">contact@splittiming.com</a>
        </p>
    </div>
    </div>

    <div class="pay-footer d-flex flex-row justify-content-between">

        <button (click)="backToHome()"
                class="bt-buy btn btn-default my-1" matTooltipPosition="above" style="font-size: 1rem">
            <i class="mdi mdi-home"></i>
            {{'purchased_analysis.back_to_home' | translate}}
        </button>

        <button (click)="byMore()"
                class="bt-buy btn btn-default my-1" matTooltipPosition="above" style="font-size: 1rem">
            <i class="mdi mdi-currency-usd"></i>
            {{'purchased_analysis.btn_by_more_label' | translate}}
        </button>


    </div>
</div>
