import {BaseModel} from './base.model';

export class EventModel extends BaseModel {
    constructor(
        public evento?:string,
        public categorias?:any,
        public categoria?:any,
        public best?:any,
        public data?:string,
        public local?:string,
        public maplink?:string,
        public publico?:boolean,
        public folderlink?:string,
        public dtinicio?:string,
        public dttermino?:string,
        public locutor?:string,
        public logos?:string,
        public observacoes?:string,
        public stream?:string,
        public usuarios?:any[],

        public monetize?:boolean,

    ){
        super();
    }

    static fromJson(data:any) : EventModel {
        return Object.assign(new EventModel(),data);
    }
}