<div id="page-content">
    <div class="header">
        <h3 class="title">
            {{'general.title_timing_analysis' | translate}}
        </h3>
        <i (click)="share()" class="mdi mdi-share-variant ml-3 text-white cursor-pointer" matTooltip="Share"></i>
        <i (click)="close()" class="mdi mdi-close ml-auto text-white"></i>
    </div>
    <div class="scrolling">
        <div class="row" style="background:#2a2a2a;">

            <!--Analysis (Compare Colors)-->
            <div class="col-12 py-3">
                <div class="d-flex flex-column rounded h-100" style="background:#2a2a2a;flex-grow: 1;">
                    <div class="d-flex flex-column p-3">
                        <div class="d-flex flex-row" style="border-left: 2px solid var(--turn-color);padding-left: 8px;">
                            <div class="d-flex flex-column flex-grow-1 justify-content-around" style="overflow: auto;">
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img class="mr-3" src="../../../assets/images/icons/horse-icon.png"
                                         style="height:15px;">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.cavalo) ? turn.cavalo : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img class="mr-3" src="../../../assets/images/icons/cowboy-icon.png"
                                         style="height:15px;">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.competidor) ? turn.competidor : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.evento) ? turn.evento : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-end" style="min-width: 92px;">
                                        <span style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;font-size: 2em;line-height: 1em;">
                                            {{getTurnPos(turn)}}
                                        </span>
                                <span class="turn-color" style="font-size: 14pt;">
                                            <i class="mdi mdi-timer-outline"></i>
                                    {{(turn && turn.total) ? toFixed(turn.total, 3) : '--'}}
                                        </span>
                            </div>
                        </div>
                        <hr class="w-100">
                        <div class="d-flex flex-row"
                             style="border-left: 2px solid var(--selfturn-color);padding-left: 8px;">
                            <div class="d-flex flex-column flex-grow-1 justify-content-around" style="overflow: auto;">
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img class="mr-3" src="../../../assets/images/icons/horse-icon.png"
                                         style="height:15px;">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.cavalo) ? selfTurn.cavalo : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <img class="mr-3" src="../../../assets/images/icons/cowboy-icon.png"
                                         style="height:15px;">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.competidor) ? selfTurn.competidor : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.evento) ? selfTurn.evento : '--'}}</div>
                                </div>
                                <div class="d-flex flex-row align-items-center text-white">
                                    <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.categoria) ? selfTurn.categoria : '--'}}</div>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-end" style="min-width: 92px;">
                                        <span style="background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;font-size: 2em;line-height: 1em;">
                                            {{(selfTurn && selfTurn.pos_txt) ? selfTurn.pos_txt : '--'}}
                                        </span>
                                <span class="selfturn-color" style="font-size: 14pt;">
                                            <i class="mdi mdi-timer-outline"></i> {{(selfTurn && selfTurn.total) ? toFixed(selfTurn.total, 3) : '--'}}
                                        </span>
                            </div>
                        </div>
                        <div class="d-flex flex-column text-white pt-3">
                            <h3 style="font-size: 1.2em;">{{'general.title_analysis' | translate}}</h3>
                            <table>
                                <tbody style="font-size:20px;">
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_1st_straight' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.r1 != null) ? toFixed(turn.r1, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.r1 != null) ? toFixed(selfTurn.r1, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_1st_turn' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.v1 != null) ? toFixed(turn.v1, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.v1 != null) ? toFixed(selfTurn.v1, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_2nd_straight' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.r2 != null) ? toFixed(turn.r2, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.r2 != null) ? toFixed(selfTurn.r2, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_2nd_turn' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.v2 != null) ? toFixed(turn.v2, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.v2 != null) ? toFixed(selfTurn.v2, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_3rd_straight' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.r3 != null) ? toFixed(turn.r3, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.r3 != null) ? toFixed(selfTurn.r3, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_3rd_turn' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.v3 != null) ? toFixed(turn.v3, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.v3 != null) ? toFixed(selfTurn.v3, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #ffffff1a;">{{'general.label_final_straight' | translate}}</td>
                                    <td class="turn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(turn && turn.rf != null) ? toFixed(turn.rf, 3) : '--'}}</span></td>
                                    <td class="selfturn-color"
                                        style="height:1.6em;border-bottom:1px solid #ffffff1a;text-align:right; padding-right:10px;">
                                        <span>{{(selfTurn && selfTurn.rf != null) ? toFixed(selfTurn.rf, 3) : '--'}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="background:#2a2a2a; border-top: 1px solid whitesmoke">

            <!--Total Straight / Total Turn-->
            <div class="col-12 py-3">
                <div class="d-flex flex-column rounded" style="background:#2a2a2a;flex-grow: 1;">
                    <h3 style="padding: 16px 16px 0px 16px;margin: 0px;color: #fff;font-size: 14pt;text-transform: uppercase;">{{'general.label_total_straight' | translate}}
                        / {{'general.label_total_turn' | translate}}</h3>
                    <div class="d-flex flex-column w-100 p-3" style="min-height:200px;">
                        <div class="turn">
                            <div class="turn_header">
                                <div *ngIf="!best" class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(turn && turn.cavalo) ? turn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(turn && turn.competidor) ? turn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.evento) ? turn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div *ngIf="best" class="d-flex flex-row mr-auto ">
                                            <span class="placing">
                                                <i class="mdi mdi-star mr-1"></i> {{'general.label_best_tot_str_tot_turn' | translate}}
                                            </span>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items:center;">
                                            <span *ngIf="!best" class="placing">
                                                {{(turn && turn.pos_txt) ? turn.pos_txt : turn.pos + "º"}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                        {{(turn && turn.tot_trtv) ? toFixed(turn.tot_trtv, 3) : '--'}}
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row pb-2" style="margin-top:20px;">
                            <div *ngIf="turn.tr != null && turn.tv != null" class="w-100 d-flex flex-column pr-2">
                                <div [ngStyle]="{'width':(((turn.tr + turn.tv) * 100) / maxRulerTRV)+'%'}" class="ruler">
                                    <div [ngStyle]="{'min-width':getTotals(turn).perc_reta+'%','max-width':getTotals(turn).perc_reta+'%','background-color':colors['v1']}"
                                         class="w-100"
                                         style="position:relative">
                                        <div [ngStyle]="{'color':colors['v1']}"
                                             style="position: absolute;top: -23px;font-size: .8em;">{{turn.tr.toFixed(3)}}</div>
                                    </div>
                                    <div [ngStyle]="{'min-width':getTotals(turn).perc_curva+'%','max-width':getTotals(turn).perc_curva+'%','background-color':colors['v2']}"
                                         class="w-100"
                                         style="position:relative">
                                        <div [ngStyle]="{'color':colors['v2']}"
                                             style="position: absolute;top: -23px;font-size: .8em;">{{turn.tv.toFixed(3)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="turn">
                            <div class="turn_header">
                                <div class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(selfTurn && selfTurn.cavalo) ? selfTurn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(selfTurn && selfTurn.competidor) ? selfTurn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.evento) ? selfTurn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.categoria) ? selfTurn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items:center;">
                                            <span class="placing">
                                                {{(selfTurn && selfTurn.pos_txt) ? selfTurn.pos_txt : turn.pos + "º"}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                        {{(selfTurn && selfTurn.tot_trtv) ? toFixed(selfTurn.tot_trtv, 3) : '--'}}
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row pb-2" style="margin-top:20px;">
                            <div *ngIf="selfTurn.tr != null && selfTurn.tv != null" class="w-100 d-flex flex-column pr-2">
                                <div [ngStyle]="{'width':(((selfTurn.tr + selfTurn.tv) * 100) / maxRulerTRV)+'%'}"
                                     class="ruler">
                                    <div [ngStyle]="{'min-width':getTotals(selfTurn).perc_reta+'%','max-width':getTotals(selfTurn).perc_reta+'%','background-color':colors['v1']}"
                                         class="w-100"
                                         style="position:relative">
                                        <div [ngStyle]="{'color':colors['v1']}"
                                             style="position: absolute;top: -23px;font-size: .8em;">{{selfTurn.tr.toFixed(3)}}</div>
                                    </div>
                                    <div [ngStyle]="{'min-width':getTotals(selfTurn).perc_curva+'%','max-width':getTotals(selfTurn).perc_curva+'%','background-color':colors['v2']}"
                                         class="w-100"
                                         style="position:relative">
                                        <div [ngStyle]="{'color':colors['v2']}"
                                             style="position: absolute;top: -23px;font-size: .8em;">{{selfTurn.tv.toFixed(3)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chart d-flex flex-column">
                        <div class="chart-inner w-100 position-relative d-flex align-items-center justify-content-center">
                            <img class="image" src="../../../../assets/images/laco_reta_virador.png">
                            <div class="difs">
                                <span class="dif"></span>
                                <span class="dif"></span>
                                <span class="dif"></span>
                                <span [ngStyle]="{'color':colors['v2']}" class="dif">{{getTotalsDifferences().curva}}</span>
                                <span class="dif"></span>
                                <span class="dif"></span>
                                <span [ngStyle]="{'color':colors['v1']}" class="dif">{{getTotalsDifferences().reta}}</span>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T1 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T2 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T3 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="background:#2a2a2a; border-top: 1px solid whitesmoke">
            <!--Straight(s) + Turn(s)-->
            <div class="col-12 py-3">
                <div class="d-flex flex-column rounded" style="background:#2a2a2a;flex-grow: 1;">
                    <h3 style="padding: 16px 16px 0px 16px;margin: 0px;color: #fff;font-size: 14pt;text-transform: uppercase;">
                        {{'general.comparation_straights_turns' | translate}}</h3>
                    <div class="d-flex flex-column w-100 p-3" style="min-height:200px;">
                        <div class="turn">
                            <div class="turn_header">
                                <div *ngIf="!best" class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(turn && turn.cavalo) ? turn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(turn && turn.competidor) ? turn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.evento) ? turn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div *ngIf="best" class="d-flex flex-row mr-auto">
                                            <span class="placing">
                                                <i class="mdi mdi-star mr-1"></i> {{'general.label_best_straight_turn' | translate}}
                                            </span>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items:center;">
                                            <span *ngIf="!best" class="placing">
                                                {{(turn && turn.pos_txt) ? turn.pos_txt : turn.pos + "º"}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline"></i>
                                        {{(turn && turn.tot_rv) ? toFixed(turn.tot_rv, 3) : '--'}}

                                            </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="turn != null && turn.total != null" class="d-flex flex-row pb-2"
                             style="margin-top:20px;">
                            <div [ngStyle]="{'width':(turn.total * 100 / maxRulerRV)+'%'}" class="ruler">
                                <div *ngIf="turn != null && turn.v1 != null"
                                     [ngStyle]="{'min-width':getRVtotals(turn).perc_rv1+'%','max-width':getRVtotals(turn).perc_rv1+'%', 'background-color':colors['v1']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#ff952f;">{{turn.rv1.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="turn != null && turn.v2 != null"
                                     [ngStyle]="{'min-width':getRVtotals(turn).perc_rv2+'%','max-width':getRVtotals(turn).perc_rv2+'%', 'background-color':colors['v2']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#39d8ff;">{{turn.rv2.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="turn != null && turn.v3 != null"
                                     [ngStyle]="{'min-width':getRVtotals(turn).perc_rv3+'%','max-width':getRVtotals(turn).perc_rv3+'%', 'background-color':colors['v3']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#ff5bdf;">{{turn.rv3.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="turn != null && turn.rf != null"
                                     [ngStyle]="{'min-width':getRVtotals(turn).perc_rf+'%','max-width':getRVtotals(turn).perc_rf+'%', 'background-color':colors['rf']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#fff;">{{turn.rf.toFixed(3)}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="turn">
                            <div class="turn_header">
                                <div class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(selfTurn && selfTurn.cavalo) ? selfTurn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(selfTurn && selfTurn.competidor) ? selfTurn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.evento) ? selfTurn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.categoria) ? selfTurn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div style="display: flex;flex-direction: row; align-items:center;">
                                            <span class="placing">
                                                {{(selfTurn && selfTurn.pos_txt) ? selfTurn.pos_txt : turn.pos + "º"}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                        {{(selfTurn && selfTurn.tot_parciais) ? toFixed(selfTurn.tot_parciais, 3) : '--'}}
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selfTurn != null && selfTurn.total != null" class="d-flex flex-row pb-2"
                             style="margin-top:20px;">
                            <div [ngStyle]="{'width':(selfTurn.total * 100 / maxRulerRV)+'%'}" class="ruler">
                                <div *ngIf="selfTurn != null && selfTurn.v1 != null"
                                     [ngStyle]="{'min-width':getRVtotals(selfTurn).perc_rv1+'%','max-width':getRVtotals(selfTurn).perc_rv1+'%', 'background-color':colors['v1']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#ff952f;">{{selfTurn.rv1.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="selfTurn != null && selfTurn.v2 != null"
                                     [ngStyle]="{'min-width':getRVtotals(selfTurn).perc_rv2+'%','max-width':getRVtotals(selfTurn).perc_rv2+'%', 'background-color':colors['v2']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#39d8ff;">{{selfTurn.rv2.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="selfTurn != null && selfTurn.v3 != null"
                                     [ngStyle]="{'min-width':getRVtotals(selfTurn).perc_rv3+'%','max-width':getRVtotals(selfTurn).perc_rv3+'%', 'background-color':colors['v3']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#ff5bdf;">{{selfTurn.rv3.toFixed(3)}}</div>
                                </div>
                                <div *ngIf="selfTurn != null && selfTurn.rf != null"
                                     [ngStyle]="{'min-width':getRVtotals(selfTurn).perc_rf+'%','max-width':getRVtotals(selfTurn).perc_rf+'%', 'background-color':colors['rf']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#fff;">{{selfTurn.rf.toFixed(3)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chart d-flex flex-column">
                        <div class="chart-inner w-100 position-relative d-flex align-items-center justify-content-center">
                            <img class="image" src="../../../../assets/images/laco_rv.png">
                            <div class="difs">
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rv1')}}</span>
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rv2')}}</span>
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rv3')}}</span>
                                <span class="dif">{{getDif('rf')}}</span>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T1 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T2 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T3 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="background:#2a2a2a; border-top: 1px solid whitesmoke">
            <!--Segments-->
            <div class="col-12 py-3">
                <div class="d-flex flex-column rounded" style="background:#2a2a2a;flex-grow: 1;">
                    <h3 style="padding: 16px 16px 0px 16px;margin: 0px;color: #fff;font-size: 14pt; text-transform: uppercase">{{'general.label_segments' | translate}}</h3>
                    <div class="d-flex flex-column w-100 p-3" style="min-height:200px;">
                        <div class="turn">

                            <div class="turn_header">
                                <div *ngIf="!best" class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(turn && turn.cavalo) ? turn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(turn && turn.competidor) ? turn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.evento) ? turn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div *ngIf="best" class="d-flex flex-row mr-auto">
                                            <span class="placing">
                                                <i class="mdi mdi-star mr-1"></i> {{'general.label_best_partials' | translate}}
                                            </span>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items:center;">
                                            <span *ngIf="!best" class="placing">
                                                {{(turn && turn.pos_txt) ? turn.pos_txt : turn.pos + "º"}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                        {{(turn && turn.tot_parciais) ? toFixed(turn.tot_parciais, 3) : '--'}}
                                        <!--{{(turn)?getTotal([turn.r1,turn.v1,turn.r2,turn.v2,turn.r3,turn.v3,turn.rf]):'--'}}-->
                                            </span>
                                </div>
                            </div>

                            <div class="w-100 d-flex flex-column pb-3">
                                <div class="turn_totals_fields">
                                    <span>{{'general.label_1st_straight' | translate}}</span>
                                    <span>{{'general.label_1st_turn' | translate}}</span>
                                    <span>{{'general.label_2nd_straight' | translate}}</span>
                                    <span>{{'general.label_2nd_turn' | translate}}</span>
                                    <span>{{'general.label_3rd_straight' | translate}}</span>
                                    <span>{{'general.label_3rd_turn' | translate}}</span>
                                    <span>{{'general.label_final_straight' | translate}}</span>
                                </div>
                                <div class="turn_totals pt-0">
                                    <div>
                                        <span>{{(turn && turn.r1 != null) ? toFixed(turn.r1, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.v1 != null) ? toFixed(turn.v1, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.r2 != null) ? toFixed(turn.r2, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.v2 != null) ? toFixed(turn.v2, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.r3 != null) ? toFixed(turn.r3, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.v3 != null) ? toFixed(turn.v3, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(turn && turn.rf != null) ? toFixed(turn.rf, 3) : '--'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div [ngStyle]="{'width':((turn.total * 100) / maxRuler)+'%'}" class="ruler mb-2">
                            <div *ngIf="turn != null && turn.r1 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r1', turn)+'%','background-color':colors['r1']}"></div>
                            <div *ngIf="turn != null && turn.v1 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v1', turn)+'%','background-color':colors['v1']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="turn != null && turn.r2 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r2', turn)+'%','background-color':colors['r2']}"></div>
                            <div *ngIf="turn != null && turn.v2 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v2', turn)+'%','background-color':colors['v2']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="turn != null && turn.r3 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r3', turn)+'%','background-color':colors['r3']}"></div>
                            <div *ngIf="turn != null && turn.v3 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v3', turn)+'%','background-color':colors['v3']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="turn != null && turn.rf != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('rf', turn)+'%','background-color':colors['rf']}"></div>
                            <div *ngIf="turn != null && turn.T2 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, turn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                            <div *ngIf="turn != null && turn.T1 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, turn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                            <div *ngIf="turn != null && turn.T3 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, turn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                        </div>
                        <div class="turn">
                            <div class="turn_header">
                                <div class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(selfTurn && selfTurn.cavalo) ? selfTurn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(selfTurn && selfTurn.competidor) ? selfTurn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.evento) ? selfTurn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.categoria) ? selfTurn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items:center;">
                                            <span class="placing">
                                                {{(selfTurn && selfTurn.pos_txt) ? selfTurn.pos_txt : '--'}}
                                            </span>
                                    <span class="total">
                                                <i class="mdi mdi-timer-outline" style="margin-right:8px;"></i>
                                        {{(selfTurn && selfTurn.tot_parciais) ? toFixed(selfTurn.tot_parciais, 3) : '--'}}
                                        <!--{{(selfTurn)?getTotal([selfTurn.r1,selfTurn.v1,selfTurn.r2,selfTurn.v2,selfTurn.r3,selfTurn.v3,selfTurn.rf]):'--'}}-->
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div [ngStyle]="{'width':(selfTurn.total * 100 / maxRuler)+'%'}" class="ruler">
                            <div *ngIf="selfTurn != null && selfTurn.r1 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r1', selfTurn)+'%','background-color':colors['r1']}"></div>
                            <div *ngIf="selfTurn != null && selfTurn.v1 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v1', selfTurn)+'%','background-color':colors['v1']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="selfTurn != null && selfTurn.r2 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r2', selfTurn)+'%','background-color':colors['r2']}"></div>
                            <div *ngIf="selfTurn != null && selfTurn.v2 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v2', selfTurn)+'%','background-color':colors['v2']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="selfTurn != null && selfTurn.r3 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('r3', selfTurn)+'%','background-color':colors['r3']}"></div>
                            <div *ngIf="selfTurn != null && selfTurn.v3 != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('v3', selfTurn)+'%','background-color':colors['v3']}">
                                <i class="mdi mdi-circle-double"></i>
                            </div>
                            <div *ngIf="selfTurn != null && selfTurn.rf != null"
                                 [ngStyle]="{'width':getRulerSectionWidth('rf', selfTurn)+'%','background-color':colors['rf']}"></div>
                            <div *ngIf="selfTurn != null && selfTurn.T2 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, selfTurn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                            <div *ngIf="selfTurn != null && selfTurn.T1 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, selfTurn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                            <div *ngIf="selfTurn != null && selfTurn.T3 == 1" [ngStyle]="{'width':getRulerSectionValueWidth(5, selfTurn)+'%','background-color':colors['pen']}"
                                 class="pen"></div>
                        </div>
                        <div class="turn mt-2">
                            <div class="w-100 d-flex flex-column pb-2">
                                <div class="turn_totals_fields">
                                    <span>{{'general.label_1st_straight' | translate}}</span>
                                    <span>{{'general.label_1st_turn' | translate}}</span>
                                    <span>{{'general.label_2nd_straight' | translate}}</span>
                                    <span>{{'general.label_2nd_turn' | translate}}</span>
                                    <span>{{'general.label_3rd_straight' | translate}}</span>
                                    <span>{{'general.label_3rd_turn' | translate}}</span>
                                    <span>{{'general.label_final_straight' | translate}}</span>
                                </div>
                                <div class="turn_totals">
                                    <div>
                                        <span>{{(selfTurn && selfTurn.r1 != null) ? toFixed(selfTurn.r1, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.v1 != null) ? toFixed(selfTurn.v1, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.r2 != null) ? toFixed(selfTurn.r2, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.v2 != null) ? toFixed(selfTurn.v2, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.r3 != null) ? toFixed(selfTurn.r3, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.v3 != null) ? toFixed(selfTurn.v3, 3) : '--'}}</span>
                                    </div>
                                    <div>
                                        <span>{{(selfTurn && selfTurn.rf != null) ? toFixed(selfTurn.rf, 3) : '--'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chart d-flex flex-column">
                        <div class="chart-inner w-100 position-relative d-flex align-items-center justify-content-center">
                            <img class="image" src="../../../../assets/images/laco.png">
                            <div class="difs">
                                <span class="dif">{{getDif('r1')}}</span>
                                <span class="dif">{{getDif('v1')}}</span>
                                <span class="dif">{{getDif('r2')}}</span>
                                <span class="dif">{{getDif('v2')}}</span>
                                <span class="dif">{{getDif('r3')}}</span>
                                <span class="dif">{{getDif('v3')}}</span>
                                <span class="dif">{{getDif('rf')}}</span>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T1 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T2 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T3 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="background:#2a2a2a; border-top: 1px solid whitesmoke">
            <!--Straight + Turn + Straight (S/T/S)-->
            <div class="col-12 py-3">
                <div class="d-flex flex-column rounded" style="background:#2a2a2a;flex-grow: 1;">
                    <h3 style="padding: 16px 16px 0px 16px;margin: 0px;color: #fff;font-size: 14pt;text-transform: uppercase;">{{'general.label_straight_turn_straight' | translate}}</h3>
                    <div class="d-flex flex-column w-100 p-3" style="min-height:200px;">
                        <div class="turn">
                            <div class="turn_header">
                                <div *ngIf="!best" class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(turn && turn.cavalo) ? turn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(turn && turn.competidor) ? turn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.evento) ? turn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(turn && turn.categoria) ? turn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <div *ngIf="best" class="d-flex flex-row mr-auto">
                                            <span class="placing">
                                                <i class="mdi mdi-star mr-1"></i>{{'general.label_best_s_t_s' | translate}}
                                            </span>
                                </div>
                                <span *ngIf="!best" class="placing">
                                            {{(turn && turn.pos_txt) ? turn.pos_txt : turn.pos + "º"}}
                                        </span>
                            </div>
                        </div>
                        <div *ngIf="turn != null && turn.total != null" class="d-flex flex-row pb-2"
                             style="margin-top:20px;">
                            <div [ngStyle]="{'width':((turn.rvrt * 100) / maxRulerRVR)+'%'}" class="ruler">
                                <div *ngIf="turn != null && turn.v1 != null"
                                     [ngStyle]="{'min-width':((turn.rvr1 * 100) / turn.rvrt)+'%','max-width':((turn.rvr1 * 100) / turn.rvrt)+'%', 'background-color':colors['v1']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#ff952f;">{{(turn != null && turn.rvr1 != null) ? toFixed(turn.rvr1, 3) : '--'}}</div>
                                </div>
                                <div *ngIf="turn != null && turn.v3 != null"
                                     [ngStyle]="{'min-width':((turn.rvr2 * 100) / turn.rvrt)+'%','max-width':((turn.rvr2 * 100) / turn.rvrt)+'%', 'background-color':colors['v2']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#39d8ff;">{{(turn != null && turn.rvr2 != null) ? toFixed(turn.rvr2, 3) : '--'}}</div>
                                </div>
                                <div *ngIf="turn != null && turn.v2 != null"
                                     [ngStyle]="{'min-width':((turn.rvr3 * 100) / turn.rvrt)+'%','max-width':((turn.rvr3 * 100) / turn.rvrt)+'%', 'background-color':colors['v3']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#ff5bdf;">{{(turn != null && turn.rvr3 != null) ? toFixed(turn.rvr3, 3) : '--'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="turn">
                            <div class="turn_header">
                                <div class="participant">
                                            <span class="horse">
                                                <img src="../../../assets/images/icons/horse-icon.png">
                                                <div>{{(selfTurn && selfTurn.cavalo) ? selfTurn.cavalo : '--'}}</div>
                                            </span>
                                    <span class="competitor">
                                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                                <div>{{(selfTurn && selfTurn.competidor) ? selfTurn.competidor : '--'}}</div>
                                            </span>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.evento) ? selfTurn.evento : '--'}}</div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center text-white">
                                        <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex-grow: 1;">{{(selfTurn && selfTurn.categoria) ? selfTurn.categoria : '--'}}</div>
                                    </div>
                                </div>
                                <span class="placing">
                                            {{(selfTurn && selfTurn.pos_txt) ? selfTurn.pos_txt : turn.pos + "º"}}
                                        </span>
                            </div>
                        </div>
                        <div *ngIf="selfTurn != null && selfTurn.total != null" class="d-flex flex-row pb-2"
                             style="margin-top:20px;">
                            <div [ngStyle]="{'width':((selfTurn.rvrt * 100) / maxRulerRVR)+'%'}" class="ruler">
                                <div *ngIf="selfTurn != null && selfTurn.v1 != null"
                                     [ngStyle]="{'min-width':((selfTurn.rvr1 * 100) / selfTurn.rvrt)+'%','max-width':((selfTurn.rvr1 * 100) / selfTurn.rvrt)+'%', 'background-color':colors['v1']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#ff952f;">{{(selfTurn != null && selfTurn.rvr1 != null) ? toFixed(selfTurn.rvr1, 3) : '--'}}</div>
                                </div>
                                <div *ngIf="selfTurn != null && selfTurn.v3 != null"
                                     [ngStyle]="{'min-width':((selfTurn.rvr2 * 100) / selfTurn.rvrt)+'%','max-width':((selfTurn.rvr2 * 100) / selfTurn.rvrt)+'%', 'background-color':colors['v2']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em;color:#39d8ff;">{{(selfTurn != null && selfTurn.rvr2 != null) ? toFixed(selfTurn.rvr2, 3) : '--'}}</div>
                                </div>
                                <div *ngIf="selfTurn != null && selfTurn.v2 != null"
                                     [ngStyle]="{'min-width':((selfTurn.rvr3 * 100) / selfTurn.rvrt)+'%','max-width':((selfTurn.rvr3 * 100) / selfTurn.rvrt)+'%', 'background-color':colors['v3']}"
                                     style="position:relative">
                                    <div style="position: absolute;top: -23px;font-size: .8em; color:#ff5bdf;">{{(selfTurn != null && selfTurn.rvr3 != null) ? toFixed(selfTurn.rvr3, 3) : '--'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chart d-flex flex-column">
                        <div class="chart-inner w-100 position-relative d-flex align-items-center justify-content-center">
                            <img class="image" src="../../../../assets/images/laco_rvr2.png">
                            <div class="difs">
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rvr1')}}</span>
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rvr2')}}</span>
                                <span class="dif"></span>
                                <span class="dif">{{getDif('rvr3')}}</span>
                                <span class="dif"></span>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T1 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T2 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                                <label class="penalidade" style="color:#f00;"> <i *ngIf="selfTurn.T3 == 1"
                                                                                  class="mdi mdi-close"></i> </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
