<div class="w-100 h-100">
    <div *ngIf="publicity" class="w-100" style="border-bottom: #77777711 solid 1px;width:220px;height:127px;background:var(--bg-transmissao);background-size: cover;background-position: center;"
        [ngStyle]="{'background-image':'url('+((img)?img:logoTransmissaoPath)+')'}">
    </div>
    <div class="w-100 h-100 d-flex" [ngClass]="(type == 'live')?'flex-column-reverse':'flex-column'"  style="background:#00000066;">
        <div class="d-flex flex-column w-100" style="background: #222;">
            <div class="farol d-flex flex-row align-items-center justify-content-center">
                <div class="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img src="/assets/images/farol.png" style="  max-width: 100%;  max-height: 100%;" [ngStyle]="{'background-color':((t1)?t1:'transparent')}">
                </div>
                <div class="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img src="/assets/images/farol.png" style="  max-width: 100%;  max-height: 100%;" [ngStyle]="{'background-color':((t2)?t2:'transparent')}">
                </div>
                <div class="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img src="/assets/images/farol.png" style="  max-width: 100%;  max-height: 100%;" [ngStyle]="{'background-color':((t3)?t3:'transparent')}">
                </div>
                <div class="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img src="/assets/images/farol.png" style="  max-width: 100%;  max-height: 100%;" [ngStyle]="{'background-color':((rf)?rf:'transparent')}">
                </div>
            </div>
            <div class="text-center p-2 d-flex justify-content-center display" style="font-family:'ds-digi', sans-serif;background: linear-gradient(to top, #222, rgb(255 255 255 / 10%), rgb(255 255 255 / 30%));height: 54%;" [ngStyle]="{'color':((acc_color)?acc_color:'#fff')}">
                <div class="text-center py-2 d-flex justify-content-center align-items-center" style="
                width: 100%;font-family:'ds-digi', sans-serif;background: #000;font-size:4vw;line-height:4vw;font-weight: 700;" [ngStyle]="{'font-size':(proportional)?'4vw':'4em','line-height':(proportional)?'4vw':'4em','color':((acc_color)?acc_color:'#fff')}">{{((acc)?acc:'--')}}</div>
            </div>
        </div>
        <div #ch class="flex-column d-flex flex-row text-white w-100  my-auto" [ngStyle]="{'height':ch.clientWidth+'px'}">
            <div class="position-relative d-flex justify-content-center align-items-center h-100">
                <img src="/assets/images/laco_seq0.png" class="image">

                <img *ngIf="t1" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(t1)+'deg) brightness(2)'}" src="/assets/images/laco_seq1.png" class="image">

                <img *ngIf="t2" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(t2)+'deg) brightness(2)'}" src="/assets/images/laco_seq2.png" class="image">

                <img *ngIf="t3" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(t3)+'deg) brightness(2)'}" src="/assets/images/laco_seq3.png" class="image">

                <img *ngIf="rf" [ngStyle]="{'filter':'hue-rotate('+hexToHsl(rf)+'deg) brightness(2)'}" src="/assets/images/laco_seq4.png" class="image">
            </div>
        </div>
    </div>
</div>
