<div id="page-content">
    <div class="container-fluid" style="background:linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.3));overflow-y: auto;">
        <div class="row pt-4">
            <div class="col-12 col-md-8">
                <span class="pl-3" style="color: #fff;font-size: 1.5em; text-transform: uppercase; font-weight: 600;">{{eventName}}</span>
            </div>
        </div>
        <div class="row pt-4" *ngIf="step1Choice">
            <div class="title col-12">
                <span *ngIf="step1Choice==1">BUY YOUR ANALYSIS</span>
                <span *ngIf="step1Choice==2">SPECIAL REPORTS</span>
                <i (click)="setChoice(1, null, null, null, null)"
                   button class="mdi mdi-close-outline cursor-pointer ml-auto delete" style="float: right;margin-right: 5px; height: 100%;">
                </i>
            </div>


        </div>
        <div class="row pt-4" *ngIf="step2Choice">
            <div class="title col-12">{{ top5Types[step2Choice].keylang | translate}}
                <i (click)="setChoice(2, step1Choice, null, null, null)"
                   button class="mdi mdi-close-outline cursor-pointer ml-auto delete" style="float: right;margin-right: 5px; height: 100%;">
                </i>
            </div>

        </div>
        <div class="row pt-4" *ngIf="step3Choice">
            <div class="title col-12">
                <span *ngIf="step1Choice==1">Per Event </span>
                <span *ngIf="step1Choice==2">Per Class </span>
                <i (click)="setChoice(3, step1Choice, step2Choice, null, null)"
                   button class="mdi mdi-close-outline cursor-pointer ml-auto delete" style="float: right;margin-right: 5px; height: 100%;">
                </i>
            </div>

        </div>
        <!-- Purchase Type -->
        <div *ngIf="step==1" style="margin-top: 8px;padding-top: 5px; border-top: 1px solid white">
            <h5 style="color:white">Choose Purchase Type</h5>
            <div class="col-12 mx-0 px-0 py-3 d-flex flex-row justify-content-between">
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer"
                        (click)="setChoice(4, 1, null, null, null)">
                    BUY YOUR ANALYSIS
                </button>
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer"
                        (click)="setChoice(2, 2, null, null, null)">
                    SPECIAL REPORTS
                </button>
            </div>
        </div>
        <!-- Choose Segments -->
        <div *ngIf="step==2" style="margin-top: 8px;padding-top: 5px; border-top: 1px solid white">
            <h5 style="color:white">Choose Segment</h5>
            <div class="row">
                <div class="px-2 py-2 col-6 col-sm-3">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 0, null, null)">
                        {{top5Types[0].keylang | translate}}
                    </button>
                </div>
                <div class="px-2 py-2 col-6 col-sm-3">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 1, null, null)">
                        {{top5Types[1].keylang | translate}}
                    </button>
                </div>
                <div class="px-2 py-2 col-6 col-sm-3">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 2, null, null)">
                        {{top5Types[2].keylang | translate}}
                    </button>
                </div>
                <div class="px-2 py-2 col-6 col-sm-3">
                    <button class="button1  w-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 3, null, null)">
                        {{top5Types[3].keylang | translate}}
                    </button>
                </div>



            </div>
            <div class="row">
                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 4, null, null)">
                        {{top5Types[4].keylang | translate}}
                    </button>
                </div>

                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 5, null, null)">
                        {{top5Types[5].keylang | translate}}
                    </button>
                </div>

                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 6, null, null)">
                        {{top5Types[6].keylang | translate}}
                    </button>
                </div>

                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 7, null, null)">
                        {{top5Types[7].keylang | translate}}
                    </button>
                </div>

                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 8, null, null)">
                        {{top5Types[8].keylang | translate}}
                    </button>
                </div>

                <div class="px-2 py-2 col-6 col-sm-4">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer"
                            (click)="setChoice(3, 2, 9, null, null)">
                        {{top5Types[9].keylang | translate}}
                    </button>
                </div>
                <div class="px-2 py-2 col-12">
                    <button class="button1 w-100 h-100"
                            type="button" style="font-size: 0.8em; cursor: pointer; border: 3px solid white;"
                            (click)="setChoice(3, 2, 10, null, null)">
                        {{top5Types[10].keylang | translate}}
                    </button>
                </div>

            </div>

        </div>
        <div *ngIf="step==3" style="margin-top: 8px;padding-top: 5px; border-top: 1px solid white">
            <h5 style="color:white">Choose Purchase Type</h5>
            <div class="col-12 mx-0 px-0 py-3 d-flex flex-row justify-content-between">
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer"
                        (click)="clickEvent()">
                    Per Event {{ getTotalValueByEvent() | currency:'USD'}}
                </button>
                <button class="button1"  type="button" style="font-size: 0.8em; cursor: pointer"
                        (click)="setChoice(4, 2, step2Choice, 2, null)">
                    Per Class {{getTotalValueByClass() |  currency:'USD' }}
                </button>
            </div>

        </div>
        <div *ngIf="step==4" style="margin-top: 8px;padding-top: 5px; border-top: 1px solid white">
            <div class="my-3" *ngIf="live">
                <span style="color: #fff;font-size: 1.4em;">{{'general.label_live' | translate}}:</span>
                <div class="row mt-2">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 col col-sm-6 py-1" (click)="clickCategory(live)">
                        <div class="event_card h-100 d-flex flex-column live">
                            <app-category-card
                                    [cat]="live"
                            >
                            </app-category-card>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let dateCat of dateCategoryList" class="mt-2">
                <span style="color: #fff;font-size: 1.4em;"><i class="mdi mdi-calendar"></i> {{dateCat.date}}</span>
                <div class="row mt-2" style="border-left: 1px solid rgba(255,255,255,0.2);margin-left: 10px;">
                    <div *ngFor="let cat of dateCat.categories" class="col-12 col-md-6 col-lg-4 col-xl-3 col col-sm-6 py-1" (click)="clickCategory(cat)" >
                        <div class="event_card h-100 d-flex flex-column">
                            <app-category-card
                                    [cat]="cat"
                                    [idEvento]="eventId"
                            >
                            </app-category-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>