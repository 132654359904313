import {Paho} from 'src/app/libs/mqtt/mqttws31';
import { environment } from 'src/environments/environment';

export const FlespiConstants = {
    BASE_DEVICES_TOPIC: 'flespi/message/gw/devices/',
    BASE_CALCULATORS_TOPIC: 'flespi/interval/gw/calcs/',
};

const HOST = 'mqtt.flespi.io';
const PORT = 443;
const PATH = '/wss';


export class MqttController {

    public client: Paho.MQTT.Client;
    private refreshToken: boolean = false;
    private controllerDestroyed: boolean = false;
    private flespiToken: string = environment.flespiToken;
    private connecting: boolean = false;
    private userName: string = 'usr_'+(Math.random()*1000);
    private brokerConnection: boolean = false;
    private cleanSession: boolean = true;

    public onConnected() {
        console.log("conectou");
    };

    constructor(brokerConnection?: boolean, cleanSession?: boolean) {
        if (brokerConnection != null)
            this.brokerConnection = brokerConnection;
        if (cleanSession != null)
            this.cleanSession = cleanSession;
    }


    public connectToFlespi() {
        this.connecting = true;
        if (this.brokerConnection) {
            if (!this.controllerDestroyed){
                let clean: boolean = this.cleanSession != null ? this.cleanSession : true;
                let user: string = this.userName != null ? this.userName + '_' + this.flespiToken : this.flespiToken;

                this.client = new Paho.MQTT.Client(HOST, PORT, PATH, user);
                this.client.onMessageArrived = this.onNewMessage;
                this.client.onConnectionLost = this.onConnectionLost;

                this.client.connect({
                    useSSL: true,
                    userName: 'FlespiToken ' + this.flespiToken,
                    password: '',
                    onSuccess: this.onConnect,
                    cleanSession: clean,
                    onFailure: (error) => {
                        console.log('ERROR MQTT', error);
                        this.connecting = false;
                    }
                });
                this.refreshToken = true;
                console.log('cvonnectedddd')
            }
        }
    }

    public onMessageArrived(message: any, topic: string) {
        console.log(message);
    };

    private onConnect = () => {
        if (!this.controllerDestroyed)
            this.onConnected();
        else
            this.client.disconnect();
    };

    private onConnectionLost = (r) => {
        console.log('LOST CONNECTION', r);
        if (this.refreshToken)
            this.connectToFlespi();
    };

    disconnect() {
        this.refreshToken = false;
        this.controllerDestroyed = true;
        if (this.client && this.client.isConnected())
            this.client.disconnect();
    }


    private onNewMessage = (message: Paho.MQTT.Message) => {
        let messageObj = JSON.parse(message.payloadString);
        let topic = message.destinationName;
        //console.log(messageObj);
        this.onMessageArrived(messageObj, topic);
    };


    public isConnected(): boolean {
        return this.client && this.client.isConnected();
    }


    public subscribeTo(path: string, qos?: number) {
        if (!path)
            return;

        let qosParam = qos == null ? 0 : qos;
        this.client.subscribe(path, {qos: qosParam});
    }

    public unsubscribeTo(path) {
        if (!path)
            return;

        this.client.unsubscribe(path, {});
    }

    public isConnecting(): boolean {
        return this.connecting;
    }

    public refresh() {
        this.controllerDestroyed = false;
    }

    public setUserName(userName: string) {
        this.userName = userName;
    }

    public sendMessage(message){
        this.client.send(message);

    }
}
