import { Component, OnInit } from '@angular/core';
import {TurnModel} from '../../models/turn.model';
import {BrokerService} from '../../services/broker.service';
import {FlespiService} from '../../services/flespi.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-transmissao3',
  templateUrl: './transmissao3.component.html',
  styleUrls: ['./transmissao3.component.scss']
})
export class Transmissao3Component implements OnInit {

    id_evento: number;
    currentTurn: TurnModel = new TurnModel();
    bestTurn: TurnModel = new TurnModel();
    nextTurns: TurnModel[] = [];
    sample_data: any = {};
    background: string = null;

    banner_index: number = 4;
    ar_rotativo: string[] = [];

    farol: any = {
        'acc': 0.0,
        'T1': null,
        'T2': null,
        'T3': null,
        'rf': null,
        'acc_color': '#fff',
    };

    constructor(
        private brokerService: BrokerService,
        private flespiService: FlespiService,
        private route: ActivatedRoute
    ) {
        this.id_evento = Number(this.route.snapshot.queryParamMap.get("id_evento"));
    }

    ngOnInit() {
        this.brokerService.startForComponent('', () => {
            this.brokerService.subscribe('equslive_' + this.id_evento, 2, (message) => {
                this.onNewData(message);
            });
        });
        this.brokerService.startForComponent('', () => {
            this.brokerService.subscribe('transmissao_' + this.id_evento, 2, (message) => {
                this.onNewTransmissionData(message);
            });
        });

        this.downloadLastMessage();

        this.sample_data = {
            "best": {
                "T1": 0,
                "T2": 0,
                "T3": 0,
                "categoria": "GP ABQM",
                "cavalo": "JAY RIVER JCF",
                "competidor": "ALTAMIRO TEIXEIRA LAGES",
                "d": "3D",
                "evento": "IV ETAPA COPA WRANGLER HARAS NSG",
                "nc": 0,
                "ord": 1,
                "pas": 1,
                "pos": 15,
                "posd": 4,
                "pr": 0,
                "r1": 2.014,
                "r2": 2.744,
                "r3": 3.472,
                "rf": 4.575,
                "rv1": 4.043,
                "rv2": 4.786,
                "rv3": 4.872,
                "rvr1": 6.787,
                "rvr2": 8.258,
                "rvr3": 9.447,
                "sat": 0,
                "seq": 1,
                "total": 18.274,
                "tr": 12.805,
                "tv": 5.471,
                "type": 1,
                "v1": 2.029,
                "v2": 2.042,
                "v3": 1.4
            },
            "current": {
                "T1": 0,
                "T2": 0,
                "T3": 0,
                "categoria": "GP ABQM",
                "cavalo": "ZORRO MIRACLE IMP",
                "class": "2nd 2D",
                "competidor": "ANDERSON DA SILVA BEZERRA",
                "d": "2D",
                "evento": "IV ETAPA COPA WRANGLER HARAS NSG",
                "nc": 0,
                "ord": 10,
                "pas": 1,
                "pos": 4,
                "posd": 2,
                "pr": 0,
                "r1": 1.896,
                "r2": 2.728,
                "r3": 3.45,
                "rf": 4.28,
                "rv1": 3.797,
                "rv2": 4.543,
                "rv3": 4.823,
                "rvr1": 6.525,
                "rvr2": 7.993,
                "rvr3": 9.103,
                "sat": 0,
                "seq": 10,
                "total": 17.443,
                "tr": 12.354,
                "tv": 5.089,
                "type": 1,
                "v1": 1.901,
                "v2": 1.815,
                "v3": 1.373
            }
        };

    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            this.removeChat();
        }, 3000);

        setInterval(() => {
            this.banner_index++;
            if (this.banner_index >= this.ar_rotativo.length) this.banner_index = 0;
            this.show_banner();
        }, 10000);
    }

    show_banner() {
        if (this.ar_rotativo.length > 0) {
            this.farol.img = this.ar_rotativo.length > this.banner_index ? this.ar_rotativo[this.banner_index] : this.ar_rotativo[0];
        }
    }

    removeChat() {
        let r = document.getElementById('LeadboosterContainer');
        if (r != null) r.parentElement.removeChild(r);
    }

    setBackground(event) {
        this.background = event.target.value;
    }

    downloadLastMessage() {
        this.flespiService.getLastLiveMessage().then(message => {
            this.onNewData(message);
        }).catch(error => {
            console.log(error);
        })
    }

    onNewTransmissionData(message) {
        this.ar_rotativo = message.url_mkt ? message.url_mkt : [];
        delete message.img;
        this.farol = message;
    }

    onNewData(message) {
        //console.log(message);
        this.removeChat();
        //message = this.sample_data;
        if (message["best"] != null)
            this.bestTurn = this.adjustTurn(Object.assign(new TurnModel(), message["best"]));
        this.currentTurn = this.adjustTurn(Object.assign(new TurnModel(), message["current"]));
        let next = message["next"].map(turn => this.adjustTurn(Object.assign(new TurnModel(), turn))).splice(0, 5);
        this.nextTurns = next;
    }

    adjustTurn(turn) {
        turn.v1 = (turn.v1 != null) ? parseFloat(turn.v1).toFixed(3) : null;
        turn.v2 = (turn.v2 != null) ? parseFloat(turn.v2).toFixed(3) : null;
        turn.v3 = (turn.v3 != null) ? parseFloat(turn.v3).toFixed(3) : null;
        turn.r1 = (turn.r1 != null) ? parseFloat(turn.r1).toFixed(3) : null;
        turn.r2 = (turn.r2 != null) ? parseFloat(turn.r2).toFixed(3) : null;
        turn.r3 = (turn.r3 != null) ? parseFloat(turn.r3).toFixed(3) : null;
        turn.rf = (turn.rf != null) ? parseFloat(turn.rf).toFixed(3) : null;
        turn.tr = (turn.tr != null) ? parseFloat(turn.tr).toFixed(3) : null;
        turn.tv = (turn.tv != null) ? parseFloat(turn.tv).toFixed(3) : null;
        turn.rv1 = (turn.rv1 != null) ? parseFloat(turn.rv1).toFixed(3) : null;
        turn.rv2 = (turn.rv2 != null) ? parseFloat(turn.rv2).toFixed(3) : null;
        turn.rv3 = (turn.rv3 != null) ? parseFloat(turn.rv3).toFixed(3) : null;
        turn.rvr1 = (turn.rvr1 != null) ? parseFloat(turn.rvr1).toFixed(3) : null;
        turn.rvr2 = (turn.rvr2 != null) ? parseFloat(turn.rvr2).toFixed(3) : null;
        turn.rvr3 = (turn.rvr3 != null) ? parseFloat(turn.rvr3).toFixed(3) : null;

        turn.rvrt = (turn.rvr1 != null && turn.rvr2 != null && turn.rvr3 != null) ? (turn.rvr1 + turn.rvr2 + turn.rvr3) : null;

        if (turn.total != null && !isNaN(turn.total)) {
            turn.total = (turn.total != null) ? parseFloat(turn.total).toFixed(3) : null;
        } else {
            turn.total = "--";
        }

        turn.sat = turn.sat != true ? false : true;
        turn.nc = turn.nc != true ? false : true;
        turn.pos_txt = (turn.pos_txt != null) ? turn.pos_txt :  turn.pos+"º";

        return turn;
    }

    public getDif(field) {
        let label = '--';
        if (this.currentTurn[field] != null && !isNaN(this.currentTurn[field]) && !isNaN(this.bestTurn[field]) != null && this.bestTurn[field] != null) {
            let dif = this.currentTurn[field] - this.bestTurn[field];
            label = (dif == 0) ? '--' : (dif > 0 ? '+' : '') + (dif).toFixed(3);
        }
        return label;
    }

    public hexToHsl(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        s = s * 100;
        s = Math.round(s);
        l = l * 100;
        l = Math.round(l);
        h = Math.round(360 * h);

        return h;
    }

    public getClassColor(field) {
        let cls = '';
        if (this.currentTurn[field] != null && !isNaN(this.currentTurn[field]) && !isNaN(this.bestTurn[field]) != null && this.bestTurn[field] != null) {
            //((currentTurn.v3 - bestTurn.v3) > 0)?'diferences-pos':((currentTurn.v3 - bestTurn.v3) < 0)?'diferences-neg':''
            let dif = this.currentTurn[field] - this.bestTurn[field];
            cls = (dif == 0) ? '' : (dif > 0 ? 'diferences-pos' : 'diferences-neg');
        }
        return cls;
    }

}
