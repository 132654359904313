<header class="header_menu">
    <i class="mdi mdi-chevron-left toggler" (click)="goBack()"></i>
    <span id="title">{{'general.live_url_title' | translate}}</span>
    <img class="logo" src="{{logoHeaderFile}}" routerLink="/events">
</header>
<div id="page-content">
    <div *ngIf="currentTurn.competidor==null" class="w-100 container-fluid px-0 d-flex flex-column wait text-white">
        <h2>{{'general.waiting_for_the_next_run' | translate}}</h2>
<!--        <div *ngIf="video == null">-->
<!--            <div class="loader"></div>-->
<!--        </div>-->
<!--        <div *ngIf="video != null" class="video-container">-->
<!--            <iframe *ngIf="video_type=='youtube'" [src]="video|safe" title="Transmissão" frameborder="0" allow="autoplay" allowfullscreen></iframe>-->
<!--            &lt;!&ndash;iframe *ngIf="video_type=='twitch'" class="embed-responsive-item" src="channel=rodeioplay&amp;parent=localhost?autoplay=1&amp;vol=0&amp;title=0&amp;byline=0&amp;portrait=0?autoplay=1&amp;vol=0&amp;title=0&amp;byline=0&amp;portrait=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" height="auto" width="auto"></iframe&ndash;&gt;-->
<!--            <iframe *ngIf="video_type=='twitch'"-->
<!--                [src]="video|safe"-->
<!--                height="auto"-->
<!--                width="auto"-->
<!--                allowfullscreen="false">-->
<!--            </iframe>-->
<!--        </div>-->

    </div>
    <div *ngIf="currentTurn.competidor!=null" class="flex-row desktop" style="width:98vw; height:calc(98vw * 0.5625);border: #FFfffF22 solid 1px;background: #00000044;margin:0 auto;">
        <div  style="width:50%;" class="d-flex flex-column">
            <div class="d-flex flex-row px-3 pb-2 justify-content-between" style="padding-top:1vw;">
                <span style="color:#fff;font-size: 2.5vw;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{this.categoria}}</span>
                <span class="aovivo" style="height: 2.2vw;"> <i class="mdi mdi-television mr-2"></i><h2>{{'general.label_live' | translate}}</h2></span>
            </div>
            <div class="d-flex prova_atual pb-2">
                <div class="turno turn-resize" style="padding: 0px 0.6vw 0px 5vw;color:#fff;position: relative; align-items: center; display: flex; flex-direction: row; box-sizing: border-box;">
                    <span class="order order-resize" [attr.data-text]="currentTurn.ord"></span>
                    <div class="d-flex flex-column pb-0 flex-grow-1 w-100">
                        <span class="competitor">
                            <img src="../../../assets/images/icons/cowboy-icon.png">
                            <div style="font-size: 1.2vw;">{{currentTurn.competidor}}</div>
                        </span>
                        <span class="horse">
                            <img src="../../../assets/images/icons/horse-icon.png">
                            <div style="font-size: 2vw;">{{currentTurn.cavalo}}</div>
                        </span>
                    </div>
                </div>
                <div class="pl-2 px-3 d-flex flex-row align-items-center justify-content-between ">
                    <div class="d-flex flex-column">
                        <span class="total" style="font-size: 2.5vw;line-height: 2.5vw;margin-right: 15px;">
                            <i class="mdi mdi-timer-outline"></i>
                            {{currentTurn.total}}
                        </span>
                        <span class="total" [ngClass]="((currentTurn.total - bestTurn.total) >= 0) ? 'diferences-pos' : 'diferences-neg'" style="font-size: .9em;padding-right: 15px;text-align: end;">
                            {{getDif('total')}}
                        </span>
                    </div>
                    <span class="placing ml-auto" style="line-height: 1em;font-size: 3vw;flex-grow: 1;width: unset;">
                        {{currentTurn.pos}}
                    </span>
                </div>
            </div>
            <div class="mx-0 row d-flex flex-grow-1 w-100" style="border-top:#ffffff22 solid 1px;">
                <table class="dif_table h-100" style="width:60%;font-size: 2vw;">
                    <thead >
                        <tr>
                            <td style="font-size: 1.2vw;line-height: 1em;    text-align: left;">
                                <img class="logo" style="width:unset;height: 100%;" src="{{logoHeaderFile}}">
                            </td>
                            <td style="max-width: 70px;font-size: 1.2vw;line-height: 1em;font-weight: bold;">{{'general.current_best_time' | translate}}</td>
                            <td style="max-width: 70px;font-size: 1.2vw;line-height: 1em; font-weight: bold;">DIF.</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="r1" style="white-space:nowrap;font-size: 2vw;">{{'general.label_1st_straight' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.r1}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('r1')">{{getDif('r1')}}</td>
                        </tr>
                        <tr>
                            <td class="v1" style="white-space:nowrap;font-size: 2vw;">{{'general.label_1st_turn' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.v1}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('v1')">{{getDif('v1')}}</td>
                        </tr>
                        <tr>
                            <td class="r2" style="white-space:nowrap;font-size: 2vw;">{{'general.label_2nd_straight' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.r2}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('r2')">{{getDif('r2')}}</td>
                        </tr>
                        <tr>
                            <td class="v2" style="white-space:nowrap;font-size: 2vw;">{{'general.label_2nd_turn' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.v2}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('v2')">{{getDif('v2')}}</td>
                        </tr>
                        <tr>
                            <td class="r3" style="white-space:nowrap;font-size: 2vw;">{{'general.label_3rd_straight' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.r3}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('r3')">{{getDif('r3')}}</td>
                        </tr>
                        <tr>
                            <td class="v3" style="white-space:nowrap;font-size: 2vw;">{{'general.label_3rd_turn' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.v3}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('v3')">{{getDif('v3')}}</td>
                        </tr>
                        <tr>
                            <td class="rf" style="white-space:nowrap;font-size: 2vw;">{{'general.label_final_straight' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.rf}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('rf')">{{getDif('rf')}}</td>
                        </tr>
                        <tr>
                            <td class="rf" style="white-space:nowrap;font-size: 2vw;">{{'general.label_total_time' | translate}}</td>
                            <td style="font-size: 2vw;">{{bestTurn.total}}</td>
                            <td style="font-size: 2vw;" [ngClass]="getClassColor('total')">{{getDif('total')}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="h-100" style="width:40%;">

                    <app-farol [publicity]="false" [proportional]="true" [type]="'live'" [t1]="farol.T1" [t2]="farol.T2" [t3]="farol.T3" [rf]="farol.rf" [acc]="farol.acc" [acc-color]="farol.acc_color"></app-farol>
                </div>
            </div>
            <div class="best_area my-3">
                <div class="d-flex flex-column pt-auto best turn-resize">
                    <span class="w-100 px-4 d-flex" style="font-size:1.5em;background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;font-size: 1.2vw;">{{'general.current_best_time' | translate}}</span>
                    <div class="px-4 best_data" style="color:#fff;min-height: 40px; position: relative; box-sizing: border-box;">
                        <div class="d-flex flex-column pb-0 flex-grow-1" style="overflow: hidden;">
                            <span class="competitor">
                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                <div  style="font-size: 1.2vw;">{{bestTurn.competidor}}</div>
                            </span>
                            <span class="horse">
                                <img src="../../../assets/images/icons/horse-icon.png">
                                <div style="font-size: 2vw;">{{bestTurn.cavalo}}</div>
                            </span>
                        </div>
                        <span class="total" style="padding-left: 4px;line-height: 1em;font-size: 3vw;flex-grow: 1;width: unset; text-align: end;">
                            <i class="mdi mdi-timer-outline"></i>
                            {{bestTurn.total}}
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="d-flex flex-column w-50 h-100" style="    border-left: #ffffff33 solid 1px;">
            <div class="d-flex flex-row flex-grow-1 w-100" style="height:85%;">
                <div style="padding:1vw 0px;width: 50%;" class="px-2">
                    <span style="font-size: 18px;">{{bestTurn.categoria}}</span>
                    <h3 class="passadas text-white" style="font-size: 1.8vw;">{{'general.next_runs' | translate}}</h3>
                    <ul class="turns turns-resize">
                        <li *ngFor="let turn of nextTurns" class="turn turn-resize" style="padding: 0px 0.6vw 0px 4vw;min-height: 3vw;margin: .6vw 0;">

                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="order order-resize" [attr.data-text]="turn.ord"></span>
                            </div>
                            <div class="d-flex flex-column w-100">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
                <div style="padding:1vw 0px;width: 50%;border-left: #ffffff33 solid 1px;" class="px-2">
                    <h3  class="passadas text-white" style="font-size: 1.8vw;">{{'general.current_placing' | translate}}</h3>
                    <ul class="turns_top turns-resize" style="overflow:hidden;">
                        <li *ngFor="let turn of top10" class="turn turn-resize p-0" style="min-height: 3vw;margin: .6vw 0;">
                            <div class="d-flex flex-column flex-grow-1 " style="overflow-x:hidden;">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>
                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:flex-end;">
                                <span class="placing" style="width: unset;font-size: 2vw;line-height: 2vw;width: 5.5vw;">{{turn.pos}}</span>
                                <span class="total" style="font-size: 1.3vw;line-height: 1.3vw;">{{turn.total}} <i class="mdi mdi-timer-outline"></i> </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-100 d-flex flex-row bg-white" style="height:15%;">
                <div *ngFor="let patrocinador of patrocinadores" style="display:flex;align-items:center;justify-content:center;height:100%;padding: 0.5vw 0;" [ngStyle]="{'width':'calc(100% / '+patrocinadores.length+')'}">
                    <img [src]="patrocinador+'?nocache'" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                </div>
            </div>
        </div>

    </div>
    <div class="w-100 mobile overflow-auto" style="padding-bottom:130px;" *ngIf="currentTurn.competidor!=null">
        <div class="current-turn container-fluid px-0 d-flex flex-column" style="border-top: 1px solid rgb(255 255 255 / 25%); height: calc(100vh - var(--header-height));">
            <div class="d-flex flex-row px-3 pt-3 justify-content-between">
                <span style="color:#fff;font-size: 1.2em;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{currentTurn.categoria}}</span>
                <span class="aovivo"> <i class="mdi mdi-television mr-2"></i> {{'general.label_live' | translate}}</span>
            </div>
            <div class="d-flex flex-column">
                <div class="turno" style="    overflow: hidden;color:#fff;position: relative; align-items: center; display: flex; flex-direction: row; box-sizing: border-box;">
                    <span class="order" [attr.data-text]="currentTurn.ord"></span>
                    <div class="d-flex flex-column pb-0 flex-grow-1 w-100">
                        <span class="competitor">
                            <img src="../../../assets/images/icons/cowboy-icon.png">
                            <div>{{currentTurn.competidor}}</div>
                        </span>
                        <span class="horse">
                            <img src="../../../assets/images/icons/horse-icon.png">
                            <div>{{currentTurn.cavalo}}</div>
                        </span>
                    </div>
                </div>
                <div class="pl-2 px-3 d-flex flex-row align-items-center justify-content-between " style="height:72px;">
                    <div class="d-flex flex-column">
                        <span class="total">
                            <i class="mdi mdi-timer-outline"></i>
                            {{currentTurn.total}}
                        </span>
                        <span class="total" [ngClass]="((currentTurn.total - bestTurn.total) >= 0)?'diferences-pos':'diferences-neg'" style="font-size: .9em;margin-left: 52px;margin-top: -12px;">
                            {{getDif('total')}}
                        </span>
                    </div>
                    <span class="placing ml-auto">
                        {{currentTurn.pos}}
                    </span>
                </div>
            </div>
            <div class="best_area flex-grow-1 mt-3">
                <div class="mx-0 row d-flex flex-grow-1" style="border-top:#ffffff22 solid 1px;">
                    <div class="col-12 col-md-6 px-0">
                        <div id="dif-table" class="w-100">
                            <table class="dif_table w-100">
                                <thead >
                                    <tr>
                                        <td><img class="logo" src="{{logoHeaderFile}}"></td>
                                        <td style="max-width: 70px;font-size: 0.8em;line-height: 1em;font-weight: bold;">{{'general.current_best_time' | translate}}</td>
                                        <td style="max-width: 70px;font-size: 1em;line-height: 1em; font-weight: bold;">DIF.</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="r1" style="white-space:nowrap;">{{'general.label_1st_straight' | translate}}</td>
                                        <td>{{bestTurn.r1}}</td>
                                        <td [ngClass]="getClassColor('r1')">{{getDif('r1')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="v1" style="white-space:nowrap;">{{'general.label_1st_turn' | translate}}</td>
                                        <td>{{bestTurn.v1}}</td>
                                        <td [ngClass]="getClassColor('v1')">{{getDif('v1')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="r2" style="white-space:nowrap;">{{'general.label_2nd_straight' | translate}}</td>
                                        <td>{{bestTurn.r2}}</td>
                                        <td [ngClass]="getClassColor('r2')">{{getDif('r2')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="v2" style="white-space:nowrap;">{{'general.label_2nd_turn' | translate}}</td>
                                        <td>{{bestTurn.v2}}</td>
                                        <td [ngClass]="getClassColor('v2')">{{getDif('v2')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="r3" style="white-space:nowrap;">{{'general.label_3rd_straight' | translate}}</td>
                                        <td>{{bestTurn.r3}}</td>
                                        <td [ngClass]="getClassColor('r3')">{{getDif('r3')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="v3" style="white-space:nowrap;">{{'general.label_3rd_turn' | translate}}</td>
                                        <td>{{bestTurn.v3}}</td>
                                        <td [ngClass]="getClassColor('v3')">{{getDif('v3')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="rf" style="white-space:nowrap;">{{'general.label_final_straight' | translate}}</td>
                                        <td>{{bestTurn.rf}}</td>
                                        <td [ngClass]="getClassColor('rf')">{{getDif('rf')}}</td>
                                    </tr>
                                    <tr>
                                        <td class="rf" style="white-space:nowrap;">{{'general.label_total_time' | translate}}</td>
                                        <td>{{bestTurn.total}}</td>
                                        <td [ngClass]="getClassColor('total')">{{getDif('total')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 px-0">
                        <app-farol [publicity]="false" [type]="'live'" [t1]="farol.T1" [t2]="farol.T2" [t3]="farol.T3" [rf]="farol.rf" [acc]="farol.acc" [acc-color]="farol.acc_color"></app-farol>
                        <!--div class="chart d-flex flex-column d-flex flex-row text-white w-100" style="background:#00000066;border-bottom:#ffffff22 solid 1px;">
                            <div class="chart-inner position-relative d-flex align-items-center justify-content-center  flex-grow-1">
                                <img src="../../../../assets/images/laco.png" class="image">
                                <div class="difs">
                                    <span class="dif">{{getDif('r1')}}</span>
                                    <span class="dif">{{getDif('v1')}}</span>
                                    <span class="dif">{{getDif('r2')}}</span>
                                    <span class="dif">{{getDif('v2')}}</span>
                                    <span class="dif">{{getDif('r3')}}</span>
                                    <span class="dif">{{getDif('v3')}}</span>
                                    <span class="dif">{{getDif('rf')}}</span>
                                    <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T1 == 1" class="mdi mdi-close"></i> </label>
                                    <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T2 == 1" class="mdi mdi-close"></i> </label>
                                    <label class="penalidade" style="color:#f00;"> <i *ngIf="currentTurn.T3 == 1" class="mdi mdi-close"></i> </label>
                                </div>
                            </div>
                        </div-->
                    </div>
                </div>
                <div class="d-flex flex-column pt-auto best">
                    <span class="w-100 px-4 d-flex" style="font-size:1.5em;background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;">{{'general.current_best_time' | translate}}</span>
                    <div class="px-4 best_data" style="color:#fff;min-height: 40px; position: relative; box-sizing: border-box;">
                        <div class="d-flex flex-column pb-0 flex-grow-1" style="overflow: hidden;">
                            <span class="competitor">
                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                <div>{{bestTurn.competidor}}</div>
                            </span>
                            <span class="horse">
                                <img src="../../../assets/images/icons/horse-icon.png">
                                <div>{{bestTurn.cavalo}}</div>
                            </span>
                        </div>
                        <span class="total" style="padding-left: 4px;">
                            <i class="mdi mdi-timer-outline"></i>
                            {{bestTurn.total}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="lateral">
                <div style="margin:15px 0;">
                    <span style="font-size: 18px;">{{bestTurn.categoria}}</span>
                    <h3 *ngIf="nextTurns.length > 0" class="passadas pl-3 text-white">{{'general.next_runs' | translate}}</h3>
                    <ul class="turns ">
                        <li *ngFor="let turn of nextTurns" class="turn">

                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="order" [attr.data-text]="turn.ord"></span>
                            </div>
                            <div class="d-flex flex-column w-100">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
                <div style="margin:15px 0;padding-bottom:130px;">
                    <span style="font-size: 18px;">{{bestTurn.categoria}}</span>
                    <h3 *ngIf="top10.length > 0" class="passadas pl-3 text-white">{{'general.current_placing' | translate}}</h3>
                    <ul class="turns">
                        <li *ngFor="let turn of top10" class="no-before turn py-0 pr-0 pl-3">
                            <div class="d-flex flex-column flex-grow-1" style="overflow-x:hidden;">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>
                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="total" style="width: unset;font-size: 2.6vw;line-height: 2.6vw;width:50px;">{{turn.total}} <i class="mdi mdi-timer-outline"></i></span>
                                <span class="placing" style="width: unset;font-size: 1.2em;line-height: 1em;width:50px;text-align: left;">{{turn.pos}}</span>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

    </div>
</div>
