import {Component, Input, OnInit} from '@angular/core';
import {CategoryModel} from '../../models/category.model';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {TurnModel} from "../../models/turn.model";
import {ExcelService} from "../../services/excel.service";
import {AwsService} from "../../services/aws.service";

@Component({
    selector: 'app-category-card',
    templateUrl: './category-card.component.html',
    styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {

    @Input()
    public cat: CategoryModel;

    @Input()
    public idEvento: number;

    loadingLink = {};

    constructor(
        private localStorage: LocalStorageService,
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private excelService: ExcelService,
        private awsService: AwsService,
    ) {
    }

    ngOnInit() {
    }

    toAnalysis(event) {
        event.stopPropagation();
        this.localStorage.store("selectedCategory", this.cat.categoria);
        this.localStorage.store("atachedTurn", this.cat.best);

        const host: string = location.origin;
        const url: string = host + String(this.router.createUrlTree(['/analysis'], {
            queryParams: {
                evento: this.cat.evento,
                categoria: this.cat.categoria,

                // Alterado dia 09/11/2022 para não trazer o cavalo e o competidor no filtro das categorias na tela de Analises

                // cavalo:this.cat.best.cavalo,
                // competidor:this.cat.best.competidor
            }
        }));
        let a: HTMLElement = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.click();
        document.body.removeChild(a);
    }

    isLogged() {
        try {
            return this.authService.isAuthenticated();
        } catch (e) {
            return false;
        }
    }

    isAdmin() {
        try {
            return this.authService.isAdmin();
        } catch (e) {
            return false;
        }
    }

    openLink(event, path, params) {
        event.preventDefault();
        event.stopPropagation();
        const url = this.router.serializeUrl(
            this.router.createUrlTree([path], {queryParams: params})
        );

        window.open(url, '_blank');
    }

    handleLinksToCompetitors(event, id_categoria: number, type: string) {
        event.stopPropagation();

        let params = {
            id_categoria: id_categoria,
        };

        this.loadingLink[type + '-' + id_categoria] = true;

        const path: string = `${environment.urlFront}/ca/${id_categoria}/`;

        this.awsService.getTurns(params).then((turns: { passadas: TurnModel[] }) => {
            const competitors: { id: number, competitor: string, email: string, url: string }[] = [];
            turns.passadas.forEach(turn => {
                const index = competitors.findIndex(competitor => competitor.id == turn.id_competidor);
                if (index < 0) {
                    competitors.push({
                        id: turn.id_competidor,
                        competitor: turn.competidor,
                        email: turn.email,
                        url: `${path}${turn.id_competidor}`
                    })
                }

            })
            // console.log('competitors->', competitors);
            competitors.sort((a, b) => a.competitor.localeCompare(b.competitor))
            try {
                if (type == 'xlsx') {
                    this.excelService.exportArrayToExcel(competitors, 'class-competitors-url');
                } else if (type == 'csv') {
                    this.excelService.exportArrayToCsv(competitors, 'class-competitors-url');
                }


            } catch (e) {
                console.log("error: ", e);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loadingLink[type + '-' + id_categoria] = false;
        });
    }

}
