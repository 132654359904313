import {Component, ViewChild, ElementRef, OnInit, AfterViewInit} from '@angular/core';
import {Router, ResolveEnd, NavigationEnd} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {LocalStorageService} from 'ngx-webstorage';
import {AuthService} from './services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {environment} from 'src/environments/environment';

import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

	logoHeaderFile = "assets/images/" + environment.logoHeaderFile;

	@ViewChild('container', {static: true}) container: ElementRef<HTMLElement>;
	@ViewChild('btFullscreen', {static: true}) btFullscreen: ElementRef<HTMLElement>;
	hideMenu: boolean = true;
	title = '';
	hideBarPages: string[] = [
		"/tv",
		'/insight',
		"/totem",
		"/transmissao",
		"/transmissao1",
		"/live",
		"/buy-analysis",
		"/buy-special-reports",
		"/landing",
		"/how-to",
		"/ranking-share",
		"/analysis-share",
		"/special-report"
	]

	constructor(
		private router: Router,
		private gtmService: GoogleTagManagerService,
		public authService: AuthService,
		private dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private translate: TranslateService,

	) {
		this.translate.setDefaultLang('en');
		this.translate.use(environment.language);
	}

	ngOnInit(): void {


		/*document.ontouchmove = (e) => {
		  e.preventDefault();
		}*/
		this.router.events.forEach(item => {
			const canCookies = localStorage.getItem('cookieAccepted')
			if (String(canCookies)=='accept') {
				if (item instanceof NavigationEnd) {
					const gtmTag = {
						event: 'page',
						pageName: item.url
					};
					this.gtmService.pushTag(gtmTag);
				}
			}
		});

		this.router.events.subscribe((route: any) => {
			if (route instanceof ResolveEnd) {
				this.hideMenu = (this.hideBarPages.some(page => route.url.includes(page)) || route.url == "/");

				let sidebar = document.querySelector("#sidebar");
				if (sidebar != null) {
					sidebar.querySelectorAll("li").forEach(element => {
						element.classList.remove("active");
					});
					if (["/events", "/categories", "/home", "/compare"].some(page => route.url.includes(page))) {
						sidebar.querySelector('[link="events"]').classList.add("active");
					} else if (route.url.includes('/ranking')) {
						sidebar.querySelector('[link="ranking"]').classList.add("active");
					} else if (route.url.includes('/analysis')) {
						sidebar.querySelector('[link="analysis"]').classList.add("active");
					} else if (route.url == '' || route.url == '/') {
						document.querySelector("html").style.overflowY = "auto";
						sidebar.classList.remove("open");
					}
				}

			}
		});
	}

	ngAfterViewInit(): void {
		let sidebar = document.querySelector("#sidebar");

		window.addEventListener("click", (e: any) => {
			if (sidebar.classList.contains("open")) {
				if (!e.path.includes(sidebar) && e.path != null && !e.path.some(element => {
					return element.classList != null && element.classList.contains("toggle-sidebar");
				})) {
					e.preventDefault();
					sidebar.classList.remove("open");
					document.querySelector("html").style.overflowY = "auto";
				}
			}
		});

	}

	fulscreenMode(event) {
		let toggler = event.target.querySelector("i");
		if (toggler.classList.contains("mdi-fullscreen")) {
			toggler.classList.add("mdi-fullscreen-exit");
			toggler.classList.remove("mdi-fullscreen");
			document.body.requestFullscreen();
		} else if (toggler.classList.contains("mdi-fullscreen-exit")) {
			toggler.classList.remove("mdi-fullscreen-exit");
			toggler.classList.add("mdi-fullscreen");
			document.exitFullscreen();
		} else {
			toggler.classList.remove("mdi-fullscreen-exit");
			toggler.classList.add("mdi-fullscreen");
			document.exitFullscreen();
		}
	}

	toggleDrawer(force: boolean = false) {
		let container = this.container.nativeElement;
		let isOpen = container.hasAttribute("open");

		if (isOpen || !force)
			container.removeAttribute("open");
		else
			container.setAttribute("open", null);
	}

	isLogged() {
		try {
			return this.authService.isAuthenticated();
		} catch (e) {
			return false;
		}
	}

	isAdmin() {
		try {
			return this.authService.isAdmin();
		} catch (e) {
			return false;
		}
	}

	public logout(): void {
		const titleTranslated = this.translate.instant('split_timing.logout_dialog_confirmation_title')
		let dialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: titleTranslated,
				message: ""
			},
			panelClass: "dialog"
		});

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.localStorageService.clear('user');
				this.router.navigateByUrl('/login');
			}
		});
	}

	toggleMenu(route = null) {
		if (route != null) {
			window.location.href = "/" + route;
		} else {
			let sidebar = document.querySelector("#sidebar");
			let open = sidebar.classList.contains("open");
			if (open) {
				sidebar.classList.remove("open");
				document.querySelector("html").style.overflowY = "auto";
			} else {
				sidebar.classList.add("open");
				document.querySelector("html").style.overflowY = "hidden";
			}
		}
	}
}
