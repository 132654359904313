<header class="header_menu">
    <img class="h-100 cursor-pointer" src="{{logoHeaderFile}}" routerLink="/events">
    <span class="aovivo ml-auto"> <i class="mdi mdi-television mr-2"></i> {{'general.label_live' | translate}}</span>
</header>
<div id="page-content">
    <div *ngIf="currentTurn.competidor==null" div class="wait">
        <h2>{{'general.waiting_next_run' | translate}}</h2>
        <div *ngIf="video == null">
            <div class="loader"></div>
        </div>
        <div *ngIf="video != null" class="video-container">
            <iframe *ngIf="video_type=='youtube'"
                [src]="video|safe"
                title="Transmissão"
                frameborder="0"
                allow="autoplay;loop;"
                allowfullscreen>
            </iframe>
            <iframe *ngIf="video_type=='twitch'"
                [src]="video|safe"
                height="auto"
                width="auto"
                allowfullscreen="false">
            </iframe>
        </div>
    </div>
    <div *ngIf="currentTurn.competidor!=null" class="w-100 d-flex">
        <div class="current-turn container-fluid px-0 d-flex flex-column" style="border-top: 1px solid rgb(255 255 255 / 25%); height: calc(100vh - var(--header-height));">
            <div class="d-flex flex-row" style="border-bottom: 1px solid #ffffff33;">
                <div class="d-flex flex-column" style="width:70%;">
                    <div *ngIf="currentTurn.categoria" class="d-flex flex-row pl-4 pr-3 pt-3 justify-content-between">
                        <span style="color:#fff;font-size: 1.2em;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{currentTurn.categoria}}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="w-100 pl-4 pr-3 d-flex" style="font-size: 4vw;background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;">{{'general.current_competitor' | translate}}</span>
                        <div class="turno" style="overflow: hidden;color:#fff;position: relative; align-items: center; display: flex; flex-direction: row; box-sizing: border-box;">
                            <div class="d-flex flex-column pb-0 flex-grow-1 w-100 justify-content-center" style="padding-left: 12.5vw;">
                                <span class="order" style="left: 4vw;" [attr.data-text]="currentTurn.ord"></span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{currentTurn.competidor}}</div>
                                </span>
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{currentTurn.cavalo}}</div>
                                </span>
                            </div>

                            <div class="d-flex flex-row align-items-center justify-content-between w-100 pt-2 pl-4 pr-3">
                                <div class="d-flex flex-column">
                                    <span class="total">
                                        <i class="mdi mdi-timer-outline"></i>
                                        {{currentTurn.total}}
                                    </span>
                                    <span class="total" [ngClass]="((currentTurn.total - bestTurn.total) >= 0)?'diferences-pos':'diferences-neg'" style="font-size: .9em;margin-left: 52px;line-height: 1em;">
                                        {{getDif('total')}}
                                    </span>
                                </div>
                                <span class="placing">
                                    {{currentTurn.pos}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {{farol.acc}}
                <div class="d-flex flex-column" style="width:30%;padding: 2vw;">
                    <app-farol [publicity]="false" [type]="'live'" [t1]="farol.T1" [t2]="farol.T2" [t3]="farol.T3" [rf]="farol.rf" [acc]="farol.acc" [acc-color]="farol.acc_color"></app-farol>
                </div>
            </div>

            <div class="best_area justify-content-end py-3">
                <div class="d-flex flex-column pt-auto best">
                    <span class="w-100 pl-4 pr-3 d-flex" style="font-size: 4vw;background: -webkit-linear-gradient(63deg, #d5aa4b 0, #aa7c40 30%, #e3c97c 49%, #a37841 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight: 700;">{{'general.current_best_time' | translate}}</span>
                    <div class="px-4 best_data" style="color:#fff;min-height: 40px; position: relative; box-sizing: border-box;">
                        <div class="d-flex flex-column pb-0 flex-grow-1" style="overflow: hidden;">
                            <span class="competitor">
                                <img src="../../../assets/images/icons/cowboy-icon.png">
                                <div>{{bestTurn.competidor}}</div>
                            </span>
                            <span class="horse">
                                <img src="../../../assets/images/icons/horse-icon.png">
                                <div>{{bestTurn.cavalo}}</div>
                            </span>
                        </div>
                        <span class="total" style="padding-left: 4px;">
                            <i class="mdi mdi-timer-outline"></i>
                            {{bestTurn.total}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="pt-4 pb-3 px-3 d-flex flex-row flex-grow-1" style="border-top: 1px solid #ffffff33;">
                <div class="d-flex flex-column w-50 pr-2" *ngIf="nextTurns.length">
                    <h3 class="passadas text-white">Next runs</h3>
                    <ul class="turns ">
                        <li *ngFor="let turn of nextTurns" class="turn" style="padding-left: 10vw;">

                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="order" [attr.data-text]="turn.ord"></span>
                            </div>
                            <div class="d-flex flex-column w-100">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
                <div class="d-flex flex-column w-50 pl-2" *ngIf="top10.length">
                    <h3 class="passadas text-white">{{'general.current_placing' | translate}}</h3>
                    <ul class="turns">
                        <li *ngFor="let turn of top10" class="no-before turn py-0 pr-0">
                            <div class="d-flex flex-column flex-grow-1" style="overflow-x:hidden;">
                                <span class="horse">
                                    <img src="../../../assets/images/icons/horse-icon.png">
                                    <div>{{turn.cavalo}}</div>
                                </span>
                                <span class="competitor">
                                    <img src="../../../assets/images/icons/cowboy-icon.png">
                                    <div>{{turn.competidor}}</div>
                                </span>
                            </div>
                            <div style="display:flex;flex-direction:column; justify-content:center;align-items:center;">
                                <span class="placing" style="width: unset;font-size: 4vw;line-height: 1em;text-align: left;">{{turn.pos}}</span>
                                <span class="total" style="width: unset;font-size: 3.2vw;line-height: 3.2vw;">{{turn.total}}<i class="mdi mdi-timer-outline ml-2"></i> </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="patrocinadores.length" style="height:11vw;width:100%;padding-top:5px;padding-bottom:5px;background-color:rgb(255,255,255);" class="d-flex justify-content-center">
                <div *ngFor="let patrocinador of patrocinadores" style="display:flex;align-items:center;justify-content:center;height:100%;" [ngStyle]="{'width':getPatrocinadoresWidth()+'%'}">
                    <img [src]="patrocinador" style="max-width:100%; max-height:100%;" crossorigin="anonymous" alt="">
                </div>
            </div>
        </div>

    </div>
</div>
